import { CloseIcon } from "assets";
import { Input } from "components";
import { Modal } from "components/Modal";
import { ModalBody } from "components/Modal/components";
import { CustomSelect } from "components/Select";
import * as React from "react";
import { optionType } from "types";
import styles from "./styles.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { initialOptionType } from "Helper/options";

export interface PaymentData {
  paymentPlan: string;
  numberOfAplications?: number;
}

interface UpdatePlanProps {
  show: boolean;
  closeModal: () => void;
  submit: (payment: PaymentData) => void;
  clear: boolean;
}

const planOptions: optionType[] = [
  {
    label: "Gold ($65)",
    value: "gold",
  },
  {
    label: "Ruby ($95)",
    value: "ruby",
  },
  {
    label: "Platinum (enterprise)",
    value: "enterprise",
  },
];

interface PlanData {
  plan: optionType;
  applications: number;
}

const initialValues: PlanData = {
  plan: initialOptionType,
  applications: 0,
};

const optionTypeSchema = yup
  .object({
    label: yup.string().required("Required"),
    value: yup.string().required("Required"),
  })
  .required("Required");

const schema = yup
  .object({
    plan: optionTypeSchema,
    applications: yup.number(),
  })
  .required();

const UpdateProspectPlan: React.FC<UpdatePlanProps> = ({ show, closeModal, submit, clear }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch: watch,
    setValue,
    reset,
    setError,
  } = useForm<PlanData>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  React.useEffect(() => {
    reset();
  }, [clear]);

  const onSubmit: SubmitHandler<PlanData> = (data) => {
    if (data.plan.value === "gold" || data.plan.value === "ruby") {
      submit({ paymentPlan: data.plan.value });
    } else {
      if (data.applications) submit({ paymentPlan: data.plan.value, numberOfAplications: data.applications });
      else setError("applications", { message: "Number of applications is required for enterprise" });
    }
  };

  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <ModalBody>
          <CloseIcon onClick={closeModal} role={"button"} className={styles.closeBtn} />
          <h1 className={styles.ttl}>Update Prospect&apos;s Plan & Payment</h1>

          <CustomSelect
            onChange={(x) => setValue("plan", x)}
            validatorMessage={!(watch("plan.label") && watch("plan.value")) ? errors.plan?.message ?? "" : ""}
            inputClass={styles.select}
            name={"plan"}
            placeholder={"Select..."}
            label={"Plan*"}
            options={planOptions}
            value={watch("plan")}
            parentClassName={styles.inputWrap}
          />
          {watch("plan.value") === "enterprise" ? (
            <Input
              label="Number of Applications*"
              placeholder=""
              type="number"
              parentClassName={styles.inputWrap}
              required
              validatorMessage={errors.applications?.message}
              name="applications"
              register={register}
              min={1}
            />
          ) : (
            ""
          )}

          <button onClick={handleSubmit(onSubmit)} className={styles.submitBtn}>
            Submit
          </button>
        </ModalBody>
      </Modal>
    </>
  );
};

export { UpdateProspectPlan };

import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LogoutIcon, OverviewIcon, VobbLogoFull, MessageIcon, UsersIcon } from "assets";
import styles from "./styles.module.css";
import { Routes } from "routes/utils";
import config from "config.json";

type navList =
  | "University Verification"
  | "Verified Agents"
  | "Unverified Agents"
  | "Support"
  | "Applications"
  | "Special Offers"
  | "Blogs"
  | "Institutions"
  | "Signup Requests"
  | "Approved Signups"
  | "Prospects"
  | "Overview"
  | "Action Required"
  | "Commissions";

type navLinks = {
  pathname: string;
  active: navList;
};

interface sideBarItemProps {
  icon: React.ReactElement;
  text: string;
  link: string;
  active: navList;
  closeSideBar: () => void;
}

const SideBarItems: React.FC<sideBarItemProps> = ({ icon, text, link, active, closeSideBar }) => {
  const location = useLocation();
  return (
    <Link
      style={{ textDecoration: "none", color: "inherit" }}
      to={link}
      onClick={() => {
        if (link === location.pathname) {
          closeSideBar();
        }
      }}
    >
      <div className={active === text ? `${styles.sidebarItem} ${styles.sidebarItemActive}` : styles.sidebarItem}>
        {icon} <p>{text}</p>
      </div>
    </Link>
  );
};

export interface SideBarProps {
  active: navList;
}

const SideBar: React.FC<SideBarProps> = ({ active }) => {
  const { pathname } = useLocation();
  const [showNav, setShowNav] = React.useState<boolean>(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    setShowNav(false);
  }, [pathname]);

  const fullList = [
    // {
    //   icon: <OverviewIcon />,
    //   text: "Overview",
    //   link: Routes.overview,
    // },

    {
      icon: <OverviewIcon />,
      text: "Institutions",
      link: Routes.institutions,
    },
    {
      icon: <UsersIcon />,
      text: "Action Required",
      link: Routes.actionRequired,
    },
    {
      icon: <OverviewIcon />,
      text: "Signup Requests",
      link: Routes.signupRequests,
    },
    {
      icon: <OverviewIcon />,
      text: "Approved Signups",
      link: Routes.approvedSignups,
    },
    {
      icon: <UsersIcon />,
      text: "Verified Agents",
      link: Routes.verifiedAgents,
    },
    {
      icon: <UsersIcon />,
      text: "Unverified Agents",
      link: Routes.unverifiedAgents,
    },
    {
      icon: <UsersIcon />,
      text: "Prospects",
      link: Routes.prospects,
    },
    {
      icon: <MessageIcon />,
      text: "Applications",
      link: Routes.applications,
    },
    {
      icon: <MessageIcon />,
      text: "Commissions",
      link: Routes.commissions,
    },
    {
      icon: <MessageIcon />,
      text: "Special Offers",
      link: Routes.specialOffers,
    },
    {
      icon: <MessageIcon />,
      text: "Blogs",
      link: Routes.blogs,
    },
    {
      icon: <MessageIcon />,
      text: "Support",
      link: Routes.support,
    },
  ];

  const whitelabelList = [
    {
      icon: <OverviewIcon />,
      text: "Institutions",
      link: Routes.institutions,
    },
    {
      icon: <UsersIcon />,
      text: "Action Required",
      link: Routes.actionRequired,
    },
    {
      icon: <OverviewIcon />,
      text: "Signup Requests",
      link: Routes.signupRequests,
    },
    {
      icon: <OverviewIcon />,
      text: "Approved Signups",
      link: Routes.approvedSignups,
    },
    {
      icon: <UsersIcon />,
      text: "Verified Agents",
      link: Routes.verifiedAgents,
    },
    {
      icon: <UsersIcon />,
      text: "Unverified Agents",
      link: Routes.unverifiedAgents,
    },
    {
      icon: <UsersIcon />,
      text: "Prospects",
      link: Routes.prospects,
    },
    {
      icon: <MessageIcon />,
      text: "Applications",
      link: Routes.applications,
    },
    {
      icon: <MessageIcon />,
      text: "Commissions",
      link: Routes.commissions,
    },
  ];

  const sidebarList = config.whitelabel ? whitelabelList : fullList;

  const logout = () => {
    localStorage.clear();
    return navigate("/");
  };
  return (
    <>
      <div className={`${styles.mobileNav} ${styles.mobileNavReducePadding}`}>
        {config.whitelabel ? (
          <img src={config.logo} className={styles.logo} />
        ) : (
          <VobbLogoFull className={styles.logo} />
        )}
        <button onClick={() => setShowNav(!showNav)} className={styles.hamburger}>
          <div className={styles.line}></div>
          <div className={styles.line}></div>
          <div className={styles.line}></div>
        </button>
      </div>
      <div className={showNav ? `${styles.show} ${styles.sidebarWrapper}` : `${styles.hide} ${styles.sidebarWrapper}`}>
        {config.whitelabel ? (
          <img src={config.logo} className={styles.logo} />
        ) : (
          <VobbLogoFull className={styles.desktoplogo} />
        )}
        <div className={styles.sidebarItemWrapper}>
          {sidebarList.map((item, index) => (
            <SideBarItems key={index} {...item} active={active} closeSideBar={() => setShowNav(false)} />
          ))}
        </div>
        <button onClick={logout} className={styles.logoutBtn}>
          <LogoutIcon />
          Logout
        </button>
      </div>
    </>
  );
};

export { SideBar };

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from "react";

function useDropdownList({ initialDropdownState = false }) {
  // Initial dropdown state
  const [activeDropDownState, setActiveDropdownState] = useState(initialDropdownState);
  const dropdownLinkRef = useRef<any>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);

  // Effect to close dropdown when a click outside happens
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        dropdownLinkRef.current &&
        !dropdownLinkRef.current.contains(e.target) &&
        !dropdownLinkRef.current.contains(e.target)
      ) {
        setActiveDropdownState(false);
      }
    };

    document.addEventListener("mousedown", (e) => handleClickOutside(e));

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef, dropdownLinkRef]);

  return { activeDropDownState, dropdownRef, dropdownLinkRef, setActiveDropdownState };
}

export { useDropdownList };

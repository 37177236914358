// Environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL;

/*
=================================
AUTH URLS
=================================
*/

/**
 * File upload url
 *
 * @returns url string
 *
 */

export const fileUploadURL = () => `${API_URL}/documnet-upload`;

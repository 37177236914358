import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RouteBuilder, ScrollToTop } from "./utils";

/**
 * MAIN ROUTER COMPONENT
 *
 * ===============================================
 *
 * This component houses all admin dashboard routes and their respective layouts.
 * To add a route navigate to the route builder and add to the existing list.
 *
 *
 */

const MainRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {RouteBuilder.map((item, idx) => {
          const { Element, path, caseSensitive, Layout, props } = item;

          // Checks if a layout exits or not
          const PageComponant = Layout && props ? (
            <Layout {...props} >
              <Element />
            </Layout>
          ) : (
            <Element />
          );

          return <Route key={idx} path={path} element={PageComponant} caseSensitive={caseSensitive} />;
        })}
      </Routes>
    </BrowserRouter>
  );
};

export { MainRouter };

import { appendParams } from "Helper/appendParams";

// Environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL;

/*
=================================
AUTH URLS
=================================
*/

/**
 * Create institution url
 *
 * @returns url string
 *
 */

export const addInstitutionURL = () => `${API_URL}/admin/create-single-institution`;

/**
 * Fetch list of institutions url
 *
 * @param pageNo
 * @param noOfRequests
 * @param search
 * @returns url string
 *
 */

export const fetchInstitutionsURL = ({ pageNo = "", noOfRequests = "", search = "", isLanguageSchool = undefined }) =>
  `${API_URL}/admin/list-institution?${appendParams({
    pageNo,
    noOfRequests,
    search,
    isLanguageSchool,
  })}`;

/**
 * Fetch verified agents url
 * Fetch agents without pagination, to be used for dropdowns
 *
 * @returns url string
 *
 */
export const fetchAllVerifiedAgentsURL = () => `${API_URL}/fetch-verified-agents`;

/**
 * Fetch a single institution
 *
 * @param id
 * @returns url string
 *
 */
export const fetchInstitutionURL = (id) => `${API_URL}/admin/single-institution/${id}`;

/**
 * Fetch agents in an institution
 *
 * @param id
 * @returns url string
 *
 */
export const fetchInstitutionAgentsURL = ({ pageNo = "", noOfRequests = "", search = "", id = "" }) =>
  `${API_URL}/admin/view-instutions-agent/${id}?pageNo=${pageNo}&noOfRequests=${noOfRequests}${
    search ? `&search=${search}` : ""
  }`;

/**
 * Fetch courses in an institution
 *
 * @param id
 * @returns url string
 *
 */
export const fetchInstitutionCoursesURL = ({
  pageNo = "",
  noOfRequests = "",
  search = "",
  id = "",
  degree = "",
  attendance = "",
}) =>
  `${API_URL}/list-university-course/${id}?pageNo=${pageNo}&noOfRequests=${noOfRequests}${
    search ? `&search=${search}` : ""
  }${degree ? `&degree=${degree}` : ""}${attendance ? `&attendance=${attendance}` : ""}`;

/**
 * Fetch a single course
 * @param id
 * @returns url string
 */
export const fetchCourseURL = (id) => `${API_URL}/view-single-course/${id}`;

/**
 * Enable/disable institution
 * @param id
 * @returns url string
 */
export const updateStatusInstitutionURL = (id) => `${API_URL}/admin/disable-enable-institution/${id}`;

/**
 * Enable/disable course
 * @param id
 * @returns url string
 */
export const updateStatusCourseURL = (id) => `${API_URL}/admin/disable-enable-course-handler/${id}`;

/**
 * Create a course
 * @param id
 * @returns url string
 */
export const createCourseURL = (id) => `${API_URL}/admin/create-university-course/${id}`;

/**
 * Enable/disable agent under institution
 * @param id
 * @returns url string
 */
export const updateStatusAgentURL = (id) => `${API_URL}/admin/disable-enable-agent/${id}`;

/**
 * Fetch agents that are not under an institution
 * @param id
 * @returns url string
 */
export const fetchNonAgentsURL = (id) => `${API_URL}/lists-of-agents-not_in-university/${id}`;

/**
 * Add agents to an institution
 * @param id
 * @returns url string
 */
export const addAgentsToUniURL = (id) => `${API_URL}/admin/add-agent-to-univeristy/${id}`;

/**
 * Edit a course
 * @param id
 * @returns url string
 */
export const editCourseURL = (id) => `${API_URL}/admin/edit-course/${id}`;

/**
 * Edit a university's profile
 * @param id
 * @returns url string
 */
export const editInstitutionURL = (id) => `${API_URL}/admin/edit-university-profile/${id}`;

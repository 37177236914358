import { Chart as ChartJS, ChartData, registerables, ChartOptions } from "chart.js";
import { Line } from "react-chartjs-2";
import * as React from "react";

ChartJS.unregister();
ChartJS.register(...registerables);
ChartJS.defaults.font.family = "Barlow";
ChartJS.defaults.font.size = 13;

interface OverTimeData {
  data: {
    allAgents: number[];
    verifiedAgents: number[];
    signupRequests: number[];
    approvedSignups: number[];
    students: number[];
    applications: number[];
    admissions: number[];
    cancellation: number[];
    acceptance: number[];
    enrollment: number[];
  };
  labels: string[];
}
const OverTimeChart: React.FC<OverTimeData> = ({ data, labels }) => {
  const chartData: ChartData<"line"> = {
    labels,
    datasets: [
      {
        label: "All Agents",
        data: data.allAgents,
        backgroundColor: ["rgb(255 159 64)"],
        borderColor: ["rgb(255 159 64)"],
        borderWidth: 1,
      },
      {
        label: "Verified Agents",
        data: data.verifiedAgents,
        backgroundColor: ["rgb(0 193 0 )"],
        borderColor: ["rgb(0 193 0)"],
        borderWidth: 1,
      },
      {
        label: "Signup Requests",
        data: data.signupRequests,
        backgroundColor: ["rgb(189 183 107)"],
        borderColor: ["rgb(189 183 107)"],
        borderWidth: 1,
      },
      {
        label: "Approved Signups",
        data: data.approvedSignups,
        backgroundColor: ["rgb(154 154 50 )"],
        borderColor: ["rgb(154 154 50)"],
        borderWidth: 1,
      },
      {
        label: "Students",
        data: data.students,
        backgroundColor: ["rgb(10 10 10)"],
        borderColor: ["rgb(10 10 10)"],
        borderWidth: 1,
      },
      {
        label: "Applications",
        data: data.applications,
        backgroundColor: ["rgb(193 193 193 )"],
        borderColor: ["rgb(193 193 193)"],
        borderWidth: 1,
      },
      {
        label: "Admissions",
        data: data.admissions,
        backgroundColor: ["rgb(0 114 0 )"],
        borderColor: ["rgb(0 114 0)"],
        borderWidth: 1,
      },
      {
        label: "Cancellation",
        data: data.cancellation,
        backgroundColor: ["rgb(255 193 0 )"],
        borderColor: ["rgb(255 193 0)"],
        borderWidth: 1,
      },
      {
        label: "Acceptance",
        data: data.acceptance,
        backgroundColor: ["rgb(0 193 255)"],
        borderColor: ["rgb(0 193 255)"],
        borderWidth: 1,
      },
      {
        label: "Enrollments",
        data: data.enrollment,
        backgroundColor: ["rgb(0 193 200"],
        borderColor: ["rgb(0 193 200)"],
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<"line"> = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {},
      },
      x: {
        grid: {},
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          padding: 24
        },
        align: "start",
      },
    },
  };

  const config = {
    type: "line",
    data: chartData,
    options: options,
  };

  return <Line {...config} />;
};

export { OverTimeChart };

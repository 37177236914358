import {
  DropDownItem,
  Pagination,
  SignupData,
  SignupRequestItem,
  SignupRequestUI,
  Toast,
  ViewSignupRequestModal,
} from "components";
import Preloader from "components/Preloader";
import { getErrorMessage } from "Helper/errorMessage";
import { useApiRequest } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { getOneSignupService, listSignupsService, verifySignupService } from "services";
import { optionType } from "types";
import config from "config.json";

const SignupRequests = () => {
  // Current page number for pagination component and data fetching
  const [showRequest, setShowRequest] = React.useState(false);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });
  const [search, setSearch] = React.useState("");
  const [sourceFilter, setSourceFilter] = React.useState<optionType>({ label: "All", value: "" });

  // API Request Variables
  const {
    run: runRequestList,
    data: requestListResponse,
    requestStatus: requestListStatus,
    error: requestListError,
  } = useApiRequest({});
  const {
    run: runRequest,
    data: requestResponse,
    requestStatus: requestStatus,
    error: requestError,
  } = useApiRequest({});
  const { run: runVerify, data: verifyResponse, requestStatus: verifyStatus, error: verifyError } = useApiRequest({});

  useEffect(() => {
    runRequestList(
      listSignupsService({
        pageNo: `${currentPageNo}`,
        noOfRequests: "10",
        status: "pending",
        search,
        source: config.whitelabel ? config.source : sourceFilter.value,
      }),
    );
  }, [currentPageNo, search, sourceFilter]);

  const signupList = useMemo<SignupRequestItem[]>(() => {
    if (requestListResponse) {
      if (requestListResponse?.status === 200) {
        const data = requestListResponse.data.data;
        return data.fetchedData.map((item) => ({
          agencyName: item.agencyName,
          representativeName: item.name,
          emailAddress: item.email,
          phone: item.phone,
          agencyId: item._id,
          dateCreated: new Date(item.createdAt).toLocaleDateString(),
        }));
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(requestListResponse, "Unable to fetch signups"),
          type: false,
        });
      }
    }
    if (requestListError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(requestListError, "Unable to fetch signups"),
        type: false,
      });
    }

    return [];
  }, [requestListResponse, requestListError]);

  const signupInfo = useMemo<SignupData>(() => {
    if (requestResponse) {
      if (requestResponse?.status === 200) {
        const agent = requestResponse.data.data;
        setShowRequest(true);
        return {
          agencyName: agent.agencyName,
          agentName: agent.name,
          email: agent.email,
          address: agent.address,
          phone: agent.phone,
          website: agent.website,
          teamSize: agent.teamSize,
          studentSize: agent.numberOfProspects,
        };
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(requestResponse, "Unable to fetch signup"),
          type: false,
        });
      }
    }
    if (requestError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(requestError, "Unable to fetch signup"),
        type: false,
      });
    }

    return {
      agencyName: "",
      agentName: "",
      email: "",
      address: "",
      phone: "",
      website: "",
      teamSize: "",
      studentSize: "",
    };
  }, [requestResponse, requestError]);

  const verifySignup = () => {
    runVerify(verifySignupService({ data: { status: "approved" }, id: requestResponse?.data?.data._id }));
  };

  useMemo(() => {
    if (verifyResponse) {
      if (verifyResponse?.status === 200) {
        setShowRequest(false);
        setToast({
          show: true,
          heading: "Great!",
          text: "You successfully approved a signup",
          type: true,
        });
        runRequestList(listSignupsService({ pageNo: `${currentPageNo}`, noOfRequests: "10", status: "pending" }));
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(verifyResponse, "Unable to verify signup"),
          type: false,
        });
      }
    }
    if (verifyError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(verifyError, "Unable to verify signup"),
        type: false,
      });
    }
  }, [verifyResponse, verifyError]);

  const handleSearch = (search) => {
    setCurrentPageNo(1);
    setSearch(search);
  };

  const showPreloader = requestListStatus.isPending || requestStatus.isPending || verifyStatus.isPending;

  return (
    <>
      {showPreloader && <Preloader />}
      <Toast closeModal={() => setToast({ ...toast, show: false })} {...toast} />
      <ViewSignupRequestModal
        show={showRequest}
        closeModal={() => setShowRequest(false)}
        verify={verifySignup}
        agent={signupInfo}
      />
      <SignupRequestUI
        tableBodyItems={signupList}
        count={requestListResponse?.data?.data?.count ?? 0}
        viewSignup={(id) => runRequest(getOneSignupService({ id }))}
        handleSearch={handleSearch}
        sourceFilter={{ value: sourceFilter, control: (x) => setSourceFilter(x) }}
        currentPage={requestListResponse?.data?.data?.pageNo ?? 0}
      />
      <Pagination
        pages={requestListResponse?.data?.data?.availablePages ?? 0}
        handlePage={(page) => setCurrentPageNo(page)}
        currentPage={requestListResponse?.data?.data?.pageNo ?? 0}
      />
    </>
  );
};

export { SignupRequests };

import * as React from "react";
import { Modal } from "components";
import styles from "./styles.module.css";
import { ModalBody } from "components/Modal/components";

interface DeleteBlogPromptProps {
  show: boolean;
  closeModal: () => void;
  deleteBlog: () => void;
}

const DeleteBlogPrompt: React.FC<DeleteBlogPromptProps> = ({ show, closeModal, deleteBlog }) => {
  return (
    <Modal dialogClassName={styles.deleteModal} contentClassName={styles.content} show={show} onHide={closeModal}>
      <ModalBody bodyClassName={styles.body}>
        <p className={styles.promptTxt}>Are you sure you want to delete this blog post? This action cannot be undone</p>
        <button onClick={closeModal} className={`appBtnOutline ${styles.promptBtn}`}>
          Cancel
        </button>
        <button onClick={() => {closeModal(); deleteBlog()}} className={`appBtnFill ${styles.promptBtn}`}>
          Yes, delete
        </button>
      </ModalBody>
    </Modal>
  );
};

export { DeleteBlogPrompt };

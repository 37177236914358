import {
  AdminDashBoardTable,
  AllCustomTableClasses,
  PageHeader,
  VerificationTableBody,
  VerificationTableBodyItem,
} from "components";
import { TableHeaderItemProps } from "components/table/components";
import React from "react";
import { ContactProps } from "../ViewUniversities";
import styles from "./styles.module.css";

interface VerificationAgentsList {
  tableBodyItems: VerificationTableBodyItem[];
  handleViewUniversities: (id: string, contact: ContactProps) => void;
  count: number;
}

export const universityStatusCount = ({ pending, accepted, denied }) => {
  return (
    <div className={styles.statusWrap}>
      <div className={styles.status}>
        <div>
          {accepted}
          <div className={`${styles.accepted} ${styles.statusIcon}`}> &#10003;</div>
        </div>
        <div>
          {pending}
          <div className={`${styles.pending} ${styles.statusIcon}`}> !</div>
        </div>
        <div>
          {denied}
          <div className={`${styles.denied} ${styles.statusIcon}`}> &times;</div>
        </div>
      </div>
    </div>
  );
};

const UniversityVerificationUI: React.FC<VerificationAgentsList> = ({ tableBodyItems, handleViewUniversities, count }) => {
  const viewUni = (id, contact) => {
    handleViewUniversities(id, contact);
  };

  // This  is to show how to use the table component
  const tableHeaderTitles: TableHeaderItemProps[] = [
    { title: "Agency" },
    { title: "Representative" },
    { title: "Universities" },
    { title: "" },
  ];

  // Custom table Classes
  const CustomTableClasses: AllCustomTableClasses = {
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItem,
    tableWrapperClass: styles.tableWrapperClass
  };

  return (
    <section>
      <PageHeader
        title="University Verification"
        description="The table below presents a list of agencies who have added universities. Click the view button to see further details on the universities that were added."
        count={count}
      />
      {tableBodyItems.length > 0 && (
        <AdminDashBoardTable
          tableHeaderTitles={tableHeaderTitles}
          customTableClasses={CustomTableClasses}
          AdminTableBody={
            <VerificationTableBody
              handleView={viewUni}
              tableBodyClassName={styles.tableBodyClass}
              tableBodyItems={tableBodyItems}
            />
          }
        />
      )}
    </section>
  );
};

export { UniversityVerificationUI };

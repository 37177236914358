import axios, { AxiosRequestConfig } from "axios";

// Create and axios instance
axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// axios request interceptor
axios.interceptors.request.use(
  (config) => {
    // Get Access Tokens from either local storage
    const authToken = localStorage.getItem("vobbAdminToken");
    if (authToken) {
      config.headers = { ...config?.headers };
      config.headers["x-auth-token"] = authToken;
    }

    return config;
  },
  (error) => error,
);

// Log a user out with an expired token
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && window.location.pathname !== "/") {
      window.location.assign("/");
      // Redirect user to login page if not authorized
      // TODO: Add redirect logic
    }
    return error;
  },
);

// API Request Functions
interface ApiRequestProps {
  url: string;
  config?: AxiosRequestConfig;
  data?: unknown;
}

// Axios request functions
export async function getRequest(request: ApiRequestProps) {
  return await axios.get(request.url, request.config);
}

export async function postRequest(request: ApiRequestProps) {
  return await axios.post(request.url, request.data, request.config);
}

export async function putRequest(request: ApiRequestProps) {
  return await axios.put(request.url, request.data, request.config);
}

export async function patchRequest(request: ApiRequestProps) {
  return await axios.patch(request.url, request.data, request.config);
}

export async function deleteRequest(request: ApiRequestProps) {
  return await axios.delete(request.url, request.config);
}
export async function pathRequest(request: ApiRequestProps) {
  return await axios.patch(request.url, request.config);
}

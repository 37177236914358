export const Routes = {
  home: "/",
  uniVerification: "/university-verification",
  singleUniVerification: "/university-verification/:id",
  singleUniVerificationID: (id) => `/university-verification/${id}`,
  verifiedAgents: "/verified-agents",
  unverifiedAgents: "/unverified-agents",
  support: "/admin-support",
  applications: "/applications",
  specialOffers: "/special-offers",
  blogs: "/blogs",

  institutions: "/institutions",
  institution: "/institution/:id",
  institutionID: (id) => `/institution/${id}`,
  signupRequests: "/signups",
  approvedSignups: "/signups/approved",
  prospects: "/prospects",
  prospect: "/prospects/:id",
  prospectID: (id) => `/prospects/${id}`,
  overview: "/overview",
  actionRequired: "/urgent",
  createProspect: "/prospect/create/:id",
  createProspectID: (id) => `/prospect/create/${id}`,
  editProspect: "/prospect/edit/:id",
  editProspectID: (id) => `/prospect/edit/${id}`,
  agent: "/agent/:id",
  agentID: (id) => `/agent/${id}`,
  commissions: "/commissions",
};

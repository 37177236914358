import {
  ApplicationDetails,
  applicationInfo,
  CommentProps,
  PaymentData,
  ProspectApplicationTableItem,
  ProspectComments,
  Toast,
  UpdateProspectPlan,
  ViewProspectUI,
} from "components";
import Preloader from "components/Preloader";
import { getCountry, getNationality } from "Helper/country";
import { getErrorMessage } from "Helper/errorMessage";
import { useApiRequest } from "hooks";
import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  createCommentService,
  createProspectCommentService,
  deleteCommentService,
  deleteProspectCommentService,
  editCommentService,
  editProspectCommentService,
  EditProspectData,
  editProspectService,
  fetchProspectApplicationsService,
  fetchProspectCommentService,
  getApplicationCommentsServices,
  updateProspectDocsService,
  updateProspectPlanService,
  updateProspectStatusService,
  uploadService,
  viewProspectService,
} from "services";
import {
  GeneralInfo,
  ContactInfo,
  EducationHistory,
  Language,
  Documents,
  PlanInfo,
  ProspectInfo,
  educationHistoryOptions,
  languageQualificationOptions,
  initialProspect,
  emptyDoc,
  DocType,
} from "./type";
import { queryObject } from "Helper/queryObject";

const initialState: applicationInfo = {
  university: "",
  degree: "",
  name: "",
  course: "",
  passportPhoto: "",
  id: "",
};

const ViewProspect = () => {
  const params = useParams();
  const { search: urlSearch, pathname } = useLocation();
  const urlQuery = queryObject(urlSearch);
  const navigate = useNavigate();

  const [viewComments, setViewComments] = React.useState<boolean>(false);
  const [viewApplication, setViewApplication] = React.useState<{ show: boolean; application: applicationInfo }>({
    show: false,
    application: initialState,
  });
  const [showUpgrade, setShowUpgrade] = React.useState(false);
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });
  const [clearComment, setClearComment] = React.useState<boolean>(false);
  const [clearPlan, setClearPlan] = React.useState<boolean>(false);

  const {
    run: runViewProspect,
    data: viewProspectResponse,
    requestStatus: viewProspectStatus,
    error: viewProspectError,
  } = useApiRequest({});

  const {
    run: runProspectApps,
    data: prospectAppsResponse,
    requestStatus: prospectAppsStatus,
    error: prospectAppsError,
  } = useApiRequest({});

  const {
    run: runUpdateStatus,
    data: updateStatusResponse,
    requestStatus: updateStatusStatus,
    error: updateStatusError,
  } = useApiRequest({});
  const { run: runCommentsFetch, data: commentsData, requestStatus: commentStatus } = useApiRequest({});
  const {
    run: runCreateComment,
    data: createCommentData,
    requestStatus: createCommentStatus,
    error: createCommentError,
  } = useApiRequest({});
  const {
    run: runEditComment,
    data: editCommentData,
    requestStatus: editCommentStatus,
    error: editCommentError,
  } = useApiRequest({});
  const {
    run: runDeleteComment,
    data: deleteCommentData,
    requestStatus: deleteCommentStatus,
    error: deleteCommentError,
  } = useApiRequest({});
  const {
    run: runUploadFile,
    data: uploadFileResponse,
    requestStatus: uploadFileStatus,
    error: uploadFileError,
  } = useApiRequest({});
  const {
    run: runUpdateDoc,
    data: updateDocResponse,
    requestStatus: updateDocStatus,
    error: updateDocError,
  } = useApiRequest({});
  const {
    run: runUpdatePlan,
    data: updatePlanResponse,
    requestStatus: updatePlanStatus,
    error: updatePlanError,
  } = useApiRequest({});
  const {
    run: runActionNeeded,
    data: actionNeededResponse,
    requestStatus: actionNeededStatus,
    error: actionNeededError,
  } = useApiRequest({});

  const fetchProspect = () =>
    runViewProspect(
      viewProspectService({
        id: params.id,
      }),
    );

  const fetchApps = () => runProspectApps(fetchProspectApplicationsService({ id: params.id }));

  const viewProspectComments = () => {
    setViewComments(true);
    fetchComments(params.id, "prospect");
  };

  React.useEffect(() => {
    fetchProspect();
    fetchApps();
    urlQuery.comments && viewProspectComments();
  }, []);

  const checkValue = (val) => (val && val !== "" ? val : "---");
  const getDate = (date) => (date ? date.split("T")[0] : "---");

  const prospect = React.useMemo<ProspectInfo>(() => {
    if (viewProspectResponse && viewProspectResponse.status === 200) {
      const prospect = viewProspectResponse.data.data;

      const plan: PlanInfo = {
        plan: prospect.paymentPlan,
        paymentStatus: prospect.payment === "pending" ? false : true,
        remainingApps: prospect.numberOfAplications - prospect.numberSubmitted,
      };

      const general: GeneralInfo = {
        unreadComments: prospect.adminCommentInfo.commentCount,
        id: checkValue(prospect._id),
        status: checkValue(prospect.status).replaceAll("_", " "),
        publicId: checkValue(prospect._id),
        passportPhoto: prospect.suppDocs.passId.url,
        firstName: prospect.firstName,
        middleName: prospect.middleName,
        lastName: prospect.lastName,
        dateofBirth: getDate(prospect.dob),
        gender: prospect.gender,
        maritalStatus: prospect.maritalStatus,
        fathersName: checkValue(prospect.fName),
        mothersName: checkValue(prospect.mName),
        nationality: getNationality(prospect.nationality),
        passportNo: prospect.passport.number,
        issueDate: getDate(prospect.passport.issueDate),
        expiryDate: getDate(prospect.passport.expiryDate),
        countryIssued: checkValue(getCountry(prospect.passport.issueCountry)),
        birthPlace: checkValue(prospect.placeOfBirth),
      };

      const contact: ContactInfo = {
        address: checkValue(prospect.contactInfo.address),
        country: checkValue(getCountry(prospect.contactInfo.country)),
        city: checkValue(prospect.contactInfo.city),
        state: checkValue(prospect.contactInfo.state),
        phone: checkValue(prospect.contactInfo.phone),
        email: prospect.contactInfo.email ?? "",
      };

      const eduHistory: EducationHistory[] = prospect.education.map((edu) => ({
        level: educationHistoryOptions.find((item) => item.value === edu.level)?.label,
        institute: edu.insititute,
        program: edu.program,
        startDate: getDate(edu.startDate),
        endDate: getDate(edu.endDate),
        country: getCountry(edu.country),
        studyLanguage: edu.language,
        grade: edu.aveGrade,
        maximumGrade: edu.maxGrade,
      }));

      const languages: Language[] = prospect.language.map((lang) => ({
        qualification: languageQualificationOptions.find((item) => item.value === lang.qualification)?.label,
        band: lang.band,
        examDate: getDate(lang.examDate),
        readingScore: lang.readingScore,
        listeningScore: lang.listeningScore,
        writingScore: lang.writingScore,
        speakingScore: lang.speakingScore,
      }));

      const intlPassport: DocType = prospect.suppDocs.passCopy.url
        ? {
            url: prospect.suppDocs.passCopy.url,
            name: prospect.suppDocs.passCopy.filename !== "" ? prospect.suppDocs.passCopy.filename : "Intl. Passport",
          }
        : emptyDoc;

      const highSchoolTranscript: DocType = prospect.suppDocs.hst.url
        ? {
            url: prospect.suppDocs.hst.url,
            name: prospect.suppDocs.hst.filename !== "" ? prospect.suppDocs.hst.filename : "High School Transcript",
          }
        : emptyDoc;

      const languageCert: DocType = prospect.suppDocs.languageProficiency.url
        ? {
            url: prospect.suppDocs.languageProficiency.url,
            name:
              prospect.suppDocs.languageProficiency.filename !== ""
                ? prospect.suppDocs.languageProficiency.filename
                : "Language Certificate",
          }
        : emptyDoc;

      const workExperience = prospect.suppDocs.cv.url
        ? {
            url: prospect.suppDocs.cv.url,
            name: prospect.suppDocs.cv.filename !== "" ? prospect.suppDocs.cv.filename : "Work Experience",
          }
        : emptyDoc;

      const previousVisa = prospect.suppDocs.visa.url
        ? {
            url: prospect.suppDocs.visa.url,
            name: prospect.suppDocs.visa.filename !== "" ? prospect.suppDocs.visa.filename : "Previous Visa",
          }
        : emptyDoc;

      const highSchoolCert = prospect.suppDocs.hsc.url
        ? {
            url: prospect.suppDocs.hsc.url,
            name: prospect.suppDocs.hsc.filename !== "" ? prospect.suppDocs.hsc.filename : "High School Certificate",
          }
        : emptyDoc;

      const statementOfPurpose = prospect.suppDocs.sop.url
        ? {
            url: prospect.suppDocs.sop.url,
            name: prospect.suppDocs.sop.filename !== "" ? prospect.suppDocs.sop.filename : "Statement Of Purpose",
          }
        : emptyDoc;

      const referenceLetters: DocType[] = prospect.suppDocs.reference.map((item, index) => ({
        url: item.url,
        name: item.filename !== "" ? item.filename.replace(" VobbRef", "") : `Reference_${index + 1}`,
      }));

      const adminDocs: DocType[] = prospect.suppDocs.adminSuppDocs.map((item, index) => ({
        url: item.url,
        name: item.filename !== "" ? item.filename : `Admin_Doc_${index + 1}`,
      }));

      const otherDocs: DocType[] = prospect.suppDocs.others.map((item, index) => ({
        url: item.url,
        name: item.filename.replace(" VobbOther", ""),
      }));

      const undergradCert = prospect.suppDocs.undergraduateCertificate.url
        ? {
            url: prospect.suppDocs.undergraduateCertificate.url,
            name:
              prospect.suppDocs.undergraduateCertificate.filename !== ""
                ? prospect.suppDocs.undergraduateCertificate.filename
                : "Undergrad. Certificate",
          }
        : emptyDoc;

      const undergradTranscript = prospect.suppDocs.undergraduateTranscript.url
        ? {
            url: prospect.suppDocs.undergraduateTranscript.url,
            name:
              prospect.suppDocs.undergraduateTranscript.filename !== ""
                ? prospect.suppDocs.undergraduateTranscript.filename
                : "Undergrad. Transcript",
          }
        : emptyDoc;

      const generalDocs: DocType[] = [
        intlPassport,
        highSchoolTranscript,
        languageCert,
        workExperience,
        previousVisa,
        highSchoolCert,
        statementOfPurpose,
        undergradTranscript,
        undergradCert,
      ];

      const docs: Documents = {
        general: generalDocs.filter((item) => item.name !== "").sort((a, b) => (a.name < b.name ? -1 : 1)),
        references: referenceLetters.sort((a, b) => (a.name < b.name ? -1 : 1)),
        others: otherDocs.sort((a, b) => (a.name < b.name ? -1 : 1)),
        admin_documents: adminDocs.sort((a, b) => (a.name < b.name ? -1 : 1)),
      };

      return {
        personal: general,
        contact: contact,
        eduHistory: eduHistory,
        languages: languages,
        documents: docs,
        planInfo: plan,
        actionRequired: prospect.actionRequired,
      };
    }

    return initialProspect;
  }, [viewProspectResponse, viewProspectError]);

  const applications = React.useMemo<ProspectApplicationTableItem[]>(() => {
    if (prospectAppsResponse && prospectAppsResponse.status === 200) {
      return prospectAppsResponse.data.data.map((item) => ({
        publicID: "",
        university: item.university.university,
        uniLogo: item.university.logo.path,
        course: item.course.name,
        degree: item.course.degree,
        country: getCountry(item.university.country),
        status: item.sendingAgent.status,
        id: item._id,
        intakeYear: item.intakeYear,
        intakeMonth: item.intakeMonth,
        commentCount: item.sendingAgent.commentCount,
        prospectPhoto: item.prospect.suppDocs.passId.url,
        prospectName: `${item.prospect.firstName} ${item.prospect.lastName}`,
      }));
    } else if (prospectAppsError) {
      setToast({
        show: true,
        heading: "Sorry",
        text: getErrorMessage(prospectAppsResponse, "Unable to update the prospect's status"),
        type: false,
      });
    }

    return [];
  }, [prospectAppsResponse, prospectAppsError]);

  const updateProspect = (stat) => {
    runUpdateStatus(updateProspectStatusService({ id: params.id ?? "", data: { status: stat } }));
  };

  React.useMemo(() => {
    if (updateStatusResponse && updateStatusResponse.status === 200) {
      fetchProspect();
      setToast({
        show: true,
        heading: "Great!",
        text: updateStatusResponse?.data?.message ?? "Successfully updated the prospect's status",
        type: true,
      });
    } else if (updateStatusError) {
      setToast({
        show: true,
        heading: "Sorry",
        text: getErrorMessage(updateStatusResponse, "Unable to update the prospect's status"),
        type: false,
      });
    }
  }, [updateStatusResponse, updateStatusError]);

  // Formatted comments
  const comments = React.useMemo<CommentProps[]>(() => {
    if (commentsData?.status === 200) {
      const fetchedComments = commentsData?.data?.data;

      fetchProspect();
      fetchApps();

      return fetchedComments.map((item) => ({
        name: item?.name,
        role: item?.agent?.role,
        comment: item.comment,
        date: item.time,
        id: item._id,
        userID: item?.agent?._id,
      }));
    }

    return [];
  }, [commentsData]);

  const fetchComments = (id, type) => {
    if (type === "prospect") {
      runCommentsFetch(fetchProspectCommentService({ id }));
    } else if (type === "app") {
      runCommentsFetch(getApplicationCommentsServices({ id: id }));
    }
  };

  const createComment = (id, data, type) => {
    if (type === "prospect") {
      runCreateComment(createProspectCommentService({ id, data }));
    } else if (type === "app") {
      runCreateComment(createCommentService({ id, data }));
    }
  };

  const editComment = (id, commentID, data, type) => {
    if (type === "prospect") {
      runEditComment(editProspectCommentService({ id, commentID, data }));
    } else if (type === "app") {
      runEditComment(editCommentService({ id, commentID, data }));
    }
  };

  const deleteComment = (id, commentID, type) => {
    if (type === "prospect") {
      runDeleteComment(deleteProspectCommentService({ id, commentID }));
    } else if (type === "app") {
      runDeleteComment(deleteCommentService({ id, commentID }));
    }
  };

  // Create comment response
  React.useMemo(() => {
    if (createCommentData && createCommentData?.status === 200) {
      setClearComment(!clearComment);
      if (viewComments) {
        runCommentsFetch(fetchProspectCommentService({ id: params.id }));
      } else if (viewApplication.show) {
        runCommentsFetch(getApplicationCommentsServices({ id: createCommentData?.data?.data?.applicationID }));
      }
    } else if (createCommentError) {
      setToast({
        show: true,
        heading: "Sorry",
        text: getErrorMessage(createCommentData, "Failed to add comment. Please try again later."),

        type: false,
      });
      setTimeout(() => {
        setToast({
          ...toast,
          show: false,
        });
      }, 5000);
    }
  }, [createCommentData, createCommentError, createCommentStatus]);

  // Edit comment response
  React.useMemo(() => {
    if (editCommentData?.status === 200) {
      setClearComment(!clearComment);

      if (viewComments) {
        runCommentsFetch(fetchProspectCommentService({ id: params.id }));
      } else if (viewApplication.show) {
        runCommentsFetch(getApplicationCommentsServices({ id: editCommentData?.data?.data?.applicationID }));
      }
    } else if (editCommentStatus.isRejected || editCommentStatus.isResolved) {
      setToast({
        show: true,
        heading: "Sorry",
        text:
          editCommentError?.response?.data?.message ??
          editCommentData?.response?.data?.message ??
          "Failed to edit comment. Please try again later.",
        type: false,
      });
      setTimeout(() => {
        setToast({
          ...toast,
          show: false,
        });
      }, 5000);
    }
  }, [editCommentData, editCommentError, editCommentStatus]);

  // Delete comment response
  React.useMemo(() => {
    if (deleteCommentData?.status === 200) {
      if (viewComments) {
        runCommentsFetch(fetchProspectCommentService({ id: params.id }));
      } else if (viewApplication.show) {
        runCommentsFetch(getApplicationCommentsServices({ id: deleteCommentData?.data?.data?.applicationID }));
      }
    } else if (deleteCommentStatus.isRejected || deleteCommentStatus.isResolved) {
      setToast({
        show: true,
        heading: "Sorry",
        text:
          deleteCommentError?.response?.data?.message ??
          deleteCommentData?.response?.data?.message ??
          "Failed to delete comment. Please try again later.",
        type: false,
      });
      setTimeout(() => {
        setToast({
          ...toast,
          show: false,
        });
      }, 5000);
    }
  }, [deleteCommentData, deleteCommentError, deleteCommentStatus]);

  const uploadDoc = (data: File) => {
    const formData = new FormData();
    formData.append("files", data);
    runUploadFile(uploadService(formData));
  };

  React.useMemo(() => {
    if (uploadFileResponse && uploadFileResponse.status === 200) {
      const file = uploadFileResponse.data.data[0];
      const doc = { url: file.location, key: file.key, filename: file.originalname };
      runUpdateDoc(updateProspectDocsService({ id: params.id ?? "", data: doc }));
    } else if (uploadFileError) {
      setToast({
        show: true,
        heading: "Sorry",
        text: getErrorMessage(uploadFileResponse, "Unable to update the prospect's status"),
        type: false,
      });
    }
  }, [uploadFileResponse, uploadFileError]);

  React.useMemo(() => {
    if (updateDocResponse && updateDocResponse.status === 200) {
      setToast({
        show: true,
        heading: "Great!",
        text: updateDocResponse?.data?.message ?? "Successfully updated the prospect's documents",
        type: true,
      });
      fetchProspect();
    } else if (updateDocError) {
      setToast({
        show: true,
        heading: "Sorry",
        text: getErrorMessage(updateDocResponse, "Unable to update the prospect's documents"),
        type: false,
      });
    }
  }, [updateDocResponse, updateDocError]);

  const updatePlan = (data: PaymentData) => {
    runUpdatePlan(updateProspectPlanService({ id: params.id, data }));
  };

  React.useMemo(() => {
    if (updatePlanResponse && updatePlanResponse.status === 200) {
      setClearPlan(!clearPlan);
      setShowUpgrade(false);
      fetchProspect();
      setToast({
        show: true,
        heading: "Great!",
        text: updatePlanResponse?.data?.message ?? "Successfully updated the prospect's plan",
        type: true,
      });
      fetchProspect();
    } else if (updatePlanError) {
      setToast({
        show: true,
        heading: "Sorry",
        text: getErrorMessage(updatePlanResponse, "Unable to update the prospect's plan"),
        type: false,
      });
    }
  }, [updatePlanResponse, updatePlanError]);

  const updateActionStatus = (status: boolean) => {
    runActionNeeded(editProspectService({ data: { actionRequired: status }, id: params.id ?? "" }));
  };

  React.useMemo(() => {
    if (actionNeededResponse && actionNeededResponse.status === 200) {
      setToast({
        show: true,
        heading: "Great!",
        text: actionNeededResponse?.data?.message ?? "Successfully updated the prospect's information",
        type: true,
      });
      fetchProspect();
    } else if (actionNeededError) {
      setToast({
        show: true,
        heading: "Sorry",
        text: getErrorMessage(actionNeededResponse, "Unable to update the prospect's information"),
        type: false,
      });
    }
  }, [actionNeededResponse, actionNeededError]);

  const showLoader =
    viewProspectStatus.isPending ||
    updateStatusStatus.isPending ||
    prospectAppsStatus.isPending ||
    uploadFileStatus.isPending ||
    updateDocStatus.isPending ||
    updatePlanStatus.isPending ||
    actionNeededStatus.isPending;
  const showCommentLoader =
    createCommentStatus.isPending ||
    editCommentStatus.isPending ||
    deleteCommentStatus.isPending ||
    commentStatus.isPending;

  return (
    <>
      {showLoader && <Preloader />}
      <Toast closeModal={() => setToast({ ...toast, show: false })} {...toast} />
      <UpdateProspectPlan
        show={showUpgrade}
        closeModal={() => setShowUpgrade(false)}
        submit={updatePlan}
        clear={clearPlan}
      />
      <ApplicationDetails
        app={viewApplication.application}
        comments={comments}
        show={viewApplication.show}
        closeModal={() => setViewApplication({ show: false, application: initialState })}
        editComment={(id, commentID, data) => editComment(id, commentID, data, "app")}
        addNewComment={(id, data) => createComment(id, data, "app")}
        deleteComment={(id, commentID) => deleteComment(id, commentID, "app")}
        commentLoader={showCommentLoader}
        clearComment={clearComment}
      />
      <ProspectComments
        comments={comments}
        show={viewComments}
        closeModal={() => {
          setViewComments(false);
          navigate(pathname);
        }}
        editComment={(id, commentID, data) => editComment(id, commentID, data, "prospect")}
        addNewComment={(id, data) => createComment(id, data, "prospect")}
        deleteComment={(id, commentID) => deleteComment(id, commentID, "prospect")}
        commentLoader={showCommentLoader}
        clearComment={clearComment}
        prospectId={params.id ?? ""}
      />
      <ViewProspectUI
        prospect={prospect}
        applications={applications}
        changePlan={() => setShowUpgrade(true)}
        changeStatus={(x) => updateProspect(x.toLowerCase().replaceAll(" ", "_"))}
        viewComments={viewProspectComments}
        viewAppComments={(app) => {
          setViewApplication({
            show: true,
            application: app,
          });
          fetchComments(app.id, "app");
        }}
        loading={viewProspectStatus.isPending}
        uploadDoc={uploadDoc}
        changeActionStatus={updateActionStatus}
      />
    </>
  );
};

export { ViewProspect };
export * from "./type";

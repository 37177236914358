import { Chart as ChartJS, ChartData, registerables, ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";
import * as React from "react";

ChartJS.unregister();
ChartJS.register(...registerables);
ChartJS.defaults.font.family = "Barlow";
ChartJS.defaults.font.size = 13;

interface ApplicationData {
  data: number[];
}

const ApplicationStatusChart: React.FC<ApplicationData> = ({ data }) => {
  const chartData: ChartData<"bar"> = {
    labels: [
      "Sent",
      "Submitted",
      "Admission",
      "Accepted",
      "Enrolled",
      "Withdrawn",
      "Cancelled",
      "Rejected",
      "Visa Denied",
    ],
    datasets: [
      {
        data: data,
        backgroundColor: [
          "rgb(124 124 255 / 20%)",
          "rgb(189 183 107 / 20%)",
          "rgb(138 43 226 / 20%)",
          "rgb(154 205 50 / 20%)",
          "rgb(0 193 0 / 20%)",
          "rgb(255 124 124  / 20%)",
          "rgb(255 124 124  / 20%)",
          "rgb(255 124 124 / 20%)",
          "rgb(255 124 124 / 20%)",
        ],
        borderColor: [
          "rgb(124 124 255)",
          "rgb(189 183 107)",
          "rgb(138 43 226)",
          "rgb(154 205 50)",
          "rgb(0 193 0)",
          "rgb(255 124 124)",
          "rgb(255 124 124)",
          "rgb(255 124 124)",
          "rgb(255 124 124)",
        ],
        borderWidth: 1,
        borderRadius: 4,
        barThickness: 24,
        maxBarThickness: 32,
        categoryPercentage: 0.5,
        barPercentage: 1.0,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {},
    },
  };

  const config = {
    type: "bar",
    data: chartData,
    options: options,
  };

  return <Bar {...config} />;
};

export { ApplicationStatusChart };

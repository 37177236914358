import {
  CaretLeftBlue,
  DeleteIcon,
  AddIcon,
  DocumentIcon,
  WarningIcon,
  UploadIcon,
  ChangeIcon,
  avatarImg,
} from "assets";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  countryOptions,
  educationHistoryOptions,
  genderOptions,
  languageQualificationOptions,
  maritalOptions,
  nationalityOptions,
} from "Helper/options";
import styles from "./styles.module.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, useFieldArray, SubmitErrorHandler } from "react-hook-form";
import { Input } from "components/Input";
import { CustomSelect } from "components/Select";
import {
  GeneralInfo,
  EducationInfo,
  initialEducationHistory,
  LanguageInfo,
  initialLanguageData,
  Documents,
  initialDocuments,
  doc,
  initDocsUpload,
  uploadDocsType,
  EducationHistory,
  Language,
  UploadedDocuments,
  emptyDoc,
  DocType,
} from "./types";

const PlusIcon = () => <AddIcon className={styles.plusIcon} />;
const DocumentSketchIcon = () => <DocumentIcon className={styles.docIcon} />;
const DocUploadIcon = () => <UploadIcon className={styles.docUploadIcon} />;

const optionTypeSchemaReq = yup.object({
  label: yup.string().required("Required"),
  value: yup.string().required("Required"),
});

const generalInfoSchema = yup
  .object({
    passportPhoto: yup.string(),
    firstName: yup.string().required("Required"),
    middleName: yup.string(),
    lastName: yup.string().required("Required"),
    gender: yup.string().required("Required"),
    passportNo: yup
      .string()
      .required("Required")
      .matches(/[0-9a-zA-Z]+$/, "Only alphanumeric characters are expected"),
    dateofBirth: yup.string().required("Required"),
    maritalStatus: yup.string().required("Required"),
    fathersName: yup.string(),
    mothersName: yup.string(),
    issueDate: yup.date().required("Required"),
    expiryDate: yup.string().required("Required"),
    address: yup.string().required("Required"),
    city: yup.string().required("Required"),
    state: yup.string().required("Required"),
    email: yup.string().email("Enter a valid email").required("Required"),
    phone: yup.string().required("Required"),
    nationality: optionTypeSchemaReq,
    country: optionTypeSchemaReq,
    countryOfResidence: optionTypeSchemaReq,
    birthPlace: yup.string().required("Required"),
  })
  .required();

const educationHistorySchema = yup
  .object({
    eduHistory: yup.array().of(
      yup.object({
        level: optionTypeSchemaReq,
        program: yup.string().required("Required"),
        institute: yup.string().required("Required"),
        startDate: yup.string().required("Required"),
        endDate: yup.string().required("Required"),
        country: optionTypeSchemaReq,
        studyLanguage: yup.string().required("Required"),
        grade: yup.string().required("Required"),
        maximumGrade: yup.string().required("Required"),
      }),
    ),
  })
  .required();

const languageSchema = yup
  .object({
    languages: yup.array().of(
      yup.object({
        qualification: optionTypeSchemaReq,
        band: yup.string().required("Required"),
        examDate: yup.string().required("Required"),
        readingScore: yup.string().required("Required"),
        listeningScore: yup.string().required("Required"),
        writingScore: yup.string().required("Required"),
        speakingScore: yup.string().required("Required"),
      }),
    ),
  })
  .required();

const documentSchema = yup
  .object({
    intlPassport: yup.mixed().nullable(),
    highSchoolTranscript: yup.mixed().nullable(),
    languageCert: yup.mixed().nullable(),
    workExperience: yup.mixed().nullable(),
    previousVisa: yup.mixed().nullable(),
    highSchoolCert: yup.mixed().nullable(),
    statementOfPurpose: yup.mixed().nullable(),
    undergradTranscript: yup.mixed().nullable(),
    undergradCert: yup.mixed().nullable(),
    referenceLetters: yup.array().of(yup.mixed().nullable().required("Required")),
    others: yup.array().of(yup.mixed().nullable().required("Required")),
  })
  .required();

export interface ProspectInfo {
  generalInfo: GeneralInfo;
  languageInfo: Language[];
  eduHistory: EducationHistory[];
  uploadedDocs: UploadedDocuments;
  initAvatar: DocType;
}

interface EditProspectProps {
  prospect: ProspectInfo;
  submit: ({
    docs,
    general,
    eduHistory,
    languages,
  }: {
    docs: FormData | boolean;
    general: GeneralInfo;
    eduHistory: EducationHistory[];
    languages: Language[];
  }) => void;
  tooLarge: () => void;
  deleteDoc: (docs: uploadDocsType) => void;
}

const EditProspectUI: React.FC<EditProspectProps> = ({ prospect, submit, tooLarge, deleteDoc }) => {
  const { generalInfo, languageInfo, eduHistory, uploadedDocs, initAvatar } = prospect;

  const navigate = useNavigate();
  const [stage, setStage] = React.useState(1);
  const [photo, setPhoto] = React.useState({
    nameUrl: "",
    file: new File([], ""),
  });
  const [hasUploaded, setHasUploaded] = React.useState<boolean>(false);

  const [metReq, setMetReq] = React.useState(true); // set to false

  React.useEffect(() => {
    Object.keys(generalInfo).map((key: any) => setValueGeneral(key, generalInfo[key]));
    setValueEducation("eduHistory", eduHistory);
    setValueLanguage("languages", languageInfo);
    setValueDocuments("highSchoolCert", null);
  }, [prospect]);

  const {
    register: registerGeneral,
    handleSubmit: handleSubmitGeneral,
    setValue: setValueGeneral,
    formState: { errors: errorsGeneral },
    watch: watchGeneral,
    getValues: getValuesGeneral,
    reset: resetGeneral,
  } = useForm<GeneralInfo>({
    resolver: yupResolver(generalInfoSchema),
    defaultValues: { ...generalInfo },
  });

  const {
    register: registerEducation,
    handleSubmit: handleSubmitEducation,
    setValue: setValueEducation,
    formState: { errors: errorsEducation },
    watch: watchEducation,
    control: controlEducation,
    getValues: getValuesEducation,

    clearErrors,
    reset: resetEducation,
  } = useForm<EducationInfo>({
    resolver: yupResolver(educationHistorySchema),
    defaultValues: { eduHistory: eduHistory },
  });

  const {
    fields: fieldsEdu,
    append: appendEdu,
    remove: removeEdu,
  } = useFieldArray({
    control: controlEducation,
    name: "eduHistory",
  });

  const {
    register: registerLanguage,
    handleSubmit: handleSubmitLanguage,
    setValue: setValueLanguage,
    formState: { errors: errorsLanguage },
    watch: watchLanguage,
    control: controlLanguage,
    getValues: getValuesLanguage,
    clearErrors: clearErrorsLanguage,
    reset: resetLanguage,
  } = useForm<LanguageInfo>({
    resolver: yupResolver(languageSchema),
    defaultValues: { languages: languageInfo },
  });

  const {
    fields: fieldsLang,
    append: appendLang,
    remove: removeLang,
  } = useFieldArray({
    control: controlLanguage,
    name: "languages",
  });

  const {
    register: registerDocuments,
    handleSubmit: handleSubmitDocuments,
    setValue: setValueDocuments,
    formState: { errors: errorsDocuments },
    watch: watchDocuments,
    getValues: getValuesDocuments,
    reset: resetDocuments,
  } = useForm<Documents>({
    resolver: yupResolver(documentSchema),
    defaultValues: initialDocuments,
  });

  // Minimum of 10 years
  const formatYear = new Date().getFullYear() - 10;
  const maxDate = `${formatYear}-${12}-${31}`;

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const fileName = `Passport photo.${getExtension(file.name)}`;
    const photo = new File([file], fileName);

    setPhoto({ file: photo, nameUrl: URL?.createObjectURL(file) });
    setValueGeneral("passportPhoto", file);
  };

  const onSubmitGeneral: SubmitHandler<GeneralInfo> = (data) => {
    setMetReq(true);
    setStage(2);
  };

  const addEducation: SubmitHandler<EducationInfo> = (data) => {
    appendEdu({ ...initialEducationHistory });
  };

  const isValidArrayOfObjects2 = (arr): boolean => {
    return (
      arr.every((item) => {
        return Object.keys(item).every((key) => item[key] !== "");
      }) ||
      arr.every((item) => {
        return Object.keys(item).every((key) => item[key] === "");
      })
    );
  };

  const onSubmitEducation: SubmitHandler<EducationInfo> = (data) => {
    setStage(3);
  };

  const onSubmitEducationError: SubmitErrorHandler<EducationInfo> = (data) => {
    const { eduHistory } = watchEducation();

    const edu = eduHistory.map((item) => ({
      level: item.level.value,
      insititute: item.institute,
      program: item.program,
      startDate: item.startDate,
      endDate: item.endDate,
      country: item.country.value,
      language: item.studyLanguage,
      aveGrade: item.grade,
      maxGrade: item.maximumGrade,
    }));

    if (isValidArrayOfObjects2(edu)) {
      clearErrors();
      setStage(3);
    }
  };

  const getMessage = ({
    value,
    errorList,
    index,
    key,
  }: {
    value: any;
    errorList: any | undefined;
    index: number;
    key: string;
  }) => {
    const item = errorList ? errorList[index] : [];

    return item ? (value ? "" : item[key]?.value?.message ?? "") : "";
  };

  const addLanguage: SubmitHandler<LanguageInfo> = (data) => {
    appendLang({ ...initialLanguageData });
  };

  const onSubmitLanguage: SubmitHandler<LanguageInfo> = (data) => {
    setStage(4);
  };

  const onSubmitLanguageError: SubmitErrorHandler<LanguageInfo> = (data) => {
    const { languages } = getValuesLanguage();

    const lang = languages.map((item) => ({
      qualification: item.qualification.value,
      band: item.band,
      examDate: item.examDate,
      readingScore: item.readingScore,
      writingScore: item.writingScore,
      speakingScore: item.speakingScore,
      listeningScore: item.listeningScore,
    }));

    if (isValidArrayOfObjects2(lang)) {
      clearErrorsLanguage();
      setStage(4);
    }
  };

  const getMegaByte = (num) => {
    return num ? (Number(num) / 1000000).toFixed(3) : 0;
  };

  const getFileName = (fileName) => {
    return fileName.split(".").at(0);
  };

  const getExtension = (fileName) => {
    return fileName.split(".").at(-1);
  };

  const checkFileSize = ({ file, onSuccess }: { file: any; onSuccess: () => void }) => {
    if (file.size <= 1048576 * 5) {
      onSuccess();
    } else {
      tooLarge();
    }
  };

  const handleDocUpload = (e: any, name, key) => {
    const file = e.target.files[0];
    const fileName = `${name}.${getExtension(file.name)}`;
    const doc = new File([file], fileName);

    checkFileSize({
      file: file,
      onSuccess: () => {
        setValueDocuments(key, doc);
        setHasUploaded(false);
      },
    });
  };

  const handleRefUpload = (e: any, index: number) => {
    const prevList = watchDocuments("referenceLetters");
    const file = e.target.files[0];
    const name = `${getFileName(file.name)} VobbRef.${getExtension(file.name)}`;
    const doc = new File([file], name);

    const newList = prevList.map((item, idx) => (idx === index ? doc : item));

    checkFileSize({
      file: file,
      onSuccess: () => {
        setValueDocuments("referenceLetters", newList);
        setHasUploaded(false);
      },
    });
  };

  const handleOtherUpload = (e: any, index: number) => {
    const prevList = watchDocuments("others");

    const file = e.target.files[0];
    const name = `${getFileName(file.name)} VobbOther.${getExtension(file.name)}`;
    const doc = new File([file], name);

    const newList = prevList.map((item, idx) => (idx === index ? doc : item));

    checkFileSize({
      file: file,
      onSuccess: () => {
        setValueDocuments("others", newList);
        setHasUploaded(false);
      },
    });
  };

  const onSubmitDocument: SubmitHandler<Documents> = (data) => {
    const general = getValuesGeneral();
    const { eduHistory } = getValuesEducation();
    const { languages } = getValuesLanguage();

    submit({ docs: getDocumentData(), general, eduHistory, languages });
  };

  const eduCertDocs: doc[] = [
    {
      label: "High School Transcript",
      fileName: "High School Transcript",
      key: "highSchoolTranscript",
    },
    {
      label: "High School Certificate",
      fileName: "High School Certificate",
      key: "highSchoolCert",
    },
    {
      label: "Undergraduate Transcript (If applying for PG)",
      fileName: "Undergraduate Transcript",
      key: "undergradTranscript",
    },
    {
      label: "Undergraduate Certificate (if applying for PG)",
      fileName: "Undergraduate Certificate",
      key: "undergradCert",
    },
    {
      label: "IELTS Copy/Other Language Proficiency",
      fileName: "Language Certificate",
      key: "languageCert",
    },
  ];

  const personalDocs: doc[] = [
    {
      label: "Valid Passport Copy",
      fileName: "Intl. Passport",
      key: "intlPassport",
    },

    {
      label: "Previous Visa Copies if existing",
      fileName: "Previous Visa",
      key: "previousVisa",
    },
    {
      label: "Statement of Purpose",
      fileName: "Statement of Purpose",
      key: "statementOfPurpose",
    },
  ];

  const workDocs: doc[] = [
    {
      label: "CV & Work Experience Certificate",
      fileName: "Work Experience",
      key: "workExperience",
    },
  ];

  const ref: doc = {
    label: "Recommendation/Reference Letter",
    fileName: "Reference Letter",
    key: "referenceLetters",
  };
  const other: doc = {
    label: "Document",
    fileName: "Others",
    key: "others",
  };

  const getDocumentData = (): FormData | boolean => {
    const data = new FormData();

    const docKeys = Object.keys(getValuesDocuments());
    const docKeysUpload = docKeys.filter((key) =>
      key === "referenceLetters" || key === "others"
        ? getValuesDocuments()[key].filter((item) => item.size > 0).length > 0
        : getValuesDocuments()[key] !== null && getValuesDocuments()[key].size > 0,
    );

    if ((docKeysUpload.length > 0 || photo.file.size > 0) && !hasUploaded) {
      const refData = getValuesDocuments().referenceLetters.map((item) => ({
        file: item,
        name: item.name,
      }));

      const othersData = getValuesDocuments().others.map((item) => ({
        file: item,
        name: item.name,
      }));

      const docData = docKeysUpload
        .map((key) =>
          key === "referenceLetters"
            ? refData
            : key === "others"
            ? othersData
            : {
                file: getValuesDocuments()[key],
                name: getValuesDocuments()[key].name,
              },
        )
        .flat();

      docData.forEach((file) => {
        data.append(`files`, file.file, file.name);
      });

      if (photo.file.size > 0 && photo.nameUrl) {
        data.append(`files`, photo.file, photo.file.name);
      }

      return data;
    }

    return false;
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  return (
    <>
      <main className={`${styles.container} appContainer`}>
        <button className={styles.backBtn} onClick={() => navigate(-1)}>
          <CaretLeftBlue className={styles.svg} title="caret left" />
          <span>Back</span>
        </button>
        <h1 className={styles.ttl}>Edit Student</h1>
        <nav className={styles.ctrlSec}>
          <div role="button" onClick={() => setStage(1)} className={stage === 1 ? styles.ctrlActive : ""}>
            General Information
          </div>
          <div
            role="button"
            onClick={() => (metReq ? setStage(2) : "")}
            className={`${stage === 2 ? styles.ctrlActive : ""} ${!metReq ? styles.disabledCtrl : ""}`}
          >
            Education History
          </div>
          <div
            role="button"
            onClick={() => (metReq ? setStage(3) : "")}
            className={`${stage === 3 ? styles.ctrlActive : ""} ${!metReq ? styles.disabledCtrl : ""}`}
          >
            Language
          </div>
          <div
            role="button"
            onClick={() => (metReq ? setStage(4) : "")}
            className={`${stage === 4 ? styles.ctrlActive : ""} ${!metReq ? styles.disabledCtrl : ""}`}
          >
            Documents
          </div>
        </nav>
        {stage === 1 ? (
          <form className={styles.formWrap}>
            <p className={styles.formTtl}>Personal Information</p>
            <div className={styles.avatarSec}>
              <img
                className={styles.avatar}
                src={
                  photo.nameUrl !== ""
                    ? photo.nameUrl
                    : generalInfo.passportPhoto !== ""
                    ? generalInfo.passportPhoto
                    : avatarImg
                }
                alt="avatar"
              />
              <label role="button" htmlFor="passportPhoto" className={styles.changeBtn}>
                <input
                  onChange={handleImageUpload}
                  name="passportPhoto"
                  id="passportPhoto"
                  type={"file"}
                  accept="image/png, image/jpg, image/jpeg"
                />
                Change passport photo
              </label>
              {!watchGeneral("passportPhoto") && errorsGeneral.passportPhoto?.message ? (
                <p className={styles.errorMsg}>
                  <WarningIcon /> {errorsGeneral.passportPhoto?.message}
                </p>
              ) : (
                ""
              )}
            </div>
            <Input
              label="First name*"
              placeholder=""
              type="text"
              className={styles.input}
              required
              validatorMessage={errorsGeneral.firstName?.message}
              name="firstName"
              register={registerGeneral}
              onKeyDown={handleEnter}
              parentClassName={styles.inputWrap}
              value={watchGeneral("firstName")}
            />
            <Input
              label="Middle name"
              placeholder=""
              type="text"
              className={styles.input}
              required
              validatorMessage={errorsGeneral.middleName?.message}
              name="middleName"
              register={registerGeneral}
              onKeyDown={handleEnter}
              parentClassName={styles.inputWrap}
              value={watchGeneral("middleName")}
            />
            <Input
              label="Last name*"
              placeholder=""
              type="text"
              className={styles.input}
              required
              validatorMessage={errorsGeneral.lastName?.message}
              name="lastName"
              register={registerGeneral}
              onKeyDown={handleEnter}
              parentClassName={styles.inputWrap}
              value={watchGeneral("lastName")}
            />
            <CustomSelect
              onChange={(x) => setValueGeneral("nationality", x)}
              validatorMessage={
                watchGeneral("nationality").value ? "" : errorsGeneral.nationality?.message?.toString() ?? ""
              }
              inputClass={styles.select}
              name={"nationality"}
              placeholder={"Select..."}
              label={"Nationality*"}
              options={nationalityOptions}
              value={watchGeneral("nationality")}
              parentClassName={styles.inputWrap}
            />
            <Input
              label="Date of birth*"
              placeholder=""
              type="date"
              className={styles.input}
              required
              validatorMessage={errorsGeneral.dateofBirth?.message}
              name="dateofBirth"
              register={registerGeneral}
              onKeyDown={handleEnter}
              parentClassName={styles.inputWrap}
              value={watchGeneral("dateofBirth")}
              maxDate={maxDate}
            />
            <CustomSelect
              onChange={(x) => setValueGeneral("gender", x.value)}
              validatorMessage={watchGeneral("gender") ? "" : errorsGeneral.gender?.message ?? ""}
              inputClass={styles.select}
              name={"gender"}
              placeholder={"Select..."}
              label={"Gender*"}
              options={genderOptions}
              value={{
                label: watchGeneral("gender"),
                value: watchGeneral("gender"),
              }}
              parentClassName={styles.inputWrap}
            />
            <CustomSelect
              onChange={(x) => setValueGeneral("maritalStatus", x.value)}
              validatorMessage={watchGeneral("maritalStatus") ? "" : errorsGeneral.maritalStatus?.message ?? ""}
              inputClass={styles.select}
              name={"maritalStatus"}
              placeholder={"Select..."}
              label={"Marital status*"}
              options={maritalOptions}
              value={{
                label: watchGeneral("maritalStatus"),
                value: watchGeneral("maritalStatus"),
              }}
              parentClassName={styles.inputWrap}
            />
            <Input
              label="Fathers name"
              placeholder=""
              type="text"
              className={styles.input}
              required
              validatorMessage={errorsGeneral.fathersName?.message}
              name="fathersName"
              register={registerGeneral}
              onKeyDown={handleEnter}
              parentClassName={styles.inputWrap}
              value={watchGeneral("fathersName")}
            />
            <Input
              label="Mothers name"
              placeholder=""
              type="text"
              className={styles.input}
              required
              validatorMessage={errorsGeneral.mothersName?.message}
              name="mothersName"
              register={registerGeneral}
              onKeyDown={handleEnter}
              parentClassName={styles.inputWrap}
              value={watchGeneral("mothersName")}
            />
            <p className={styles.formTtl}>International Passport Information</p>
            <Input
              label="Passport number*"
              placeholder=""
              type="text"
              className={styles.input}
              required
              validatorMessage={errorsGeneral.passportNo?.message}
              name="passportNo"
              register={registerGeneral}
              onKeyDown={handleEnter}
              parentClassName={styles.inputWrap}
              value={watchGeneral("passportNo")}
            />
            <Input
              label="Issue date"
              placeholder=""
              type="date"
              className={styles.input}
              required
              validatorMessage={errorsGeneral.issueDate?.message}
              name="issueDate"
              register={registerGeneral}
              onKeyDown={handleEnter}
              parentClassName={styles.inputWrap}
              value={watchGeneral("issueDate")}
            />
            <Input
              label="Expiry date"
              placeholder=""
              type="date"
              className={styles.input}
              required
              validatorMessage={errorsGeneral.expiryDate?.message}
              name="expiryDate"
              register={registerGeneral}
              onKeyDown={handleEnter}
              parentClassName={styles.inputWrap}
              value={watchGeneral("expiryDate")}
            />
            <CustomSelect
              onChange={(x) => setValueGeneral("country", x)}
              validatorMessage={watchGeneral("country") ? "" : errorsGeneral.country?.message?.toString() ?? ""}
              inputClass={styles.select}
              name={"country"}
              placeholder={"Select..."}
              label={"Country"}
              options={countryOptions}
              value={watchGeneral("country")}
              parentClassName={styles.inputWrap}
            />
            <Input
              label="Place of birth"
              placeholder=""
              type="text"
              className={styles.input}
              required
              validatorMessage={errorsGeneral.birthPlace?.message}
              name="birthPlace"
              register={registerGeneral}
              onKeyDown={handleEnter}
              parentClassName={styles.inputWrap}
              value={watchGeneral("birthPlace")}
            />

            <div className={styles.inputWrap}></div>

            <p className={styles.formTtl}>Contact Information</p>
            <Input
              label="Address"
              placeholder=""
              type="text"
              className={styles.input}
              required
              validatorMessage={errorsGeneral.address?.message}
              name="address"
              register={registerGeneral}
              onKeyDown={handleEnter}
              parentClassName={styles.inputWrap}
              value={watchGeneral("address")}
            />
            <Input
              label="City"
              placeholder=""
              type="text"
              className={styles.input}
              required
              validatorMessage={errorsGeneral.city?.message}
              name="city"
              register={registerGeneral}
              onKeyDown={handleEnter}
              parentClassName={styles.inputWrap}
              value={watchGeneral("city")}
            />
            <Input
              label="State"
              placeholder=""
              type="text"
              className={styles.input}
              required
              validatorMessage={errorsGeneral.state?.message}
              name="state"
              register={registerGeneral}
              onKeyDown={handleEnter}
              parentClassName={styles.inputWrap}
              value={watchGeneral("state")}
            />
            <CustomSelect
              onChange={(x) => setValueGeneral("countryOfResidence", x)}
              validatorMessage={
                watchGeneral("countryOfResidence") ? "" : errorsGeneral.countryOfResidence?.message?.toString() ?? ""
              }
              inputClass={styles.select}
              name={"countryOfResidence"}
              placeholder={"Select..."}
              label={"Country of residence"}
              options={countryOptions}
              value={watchGeneral("countryOfResidence")}
              parentClassName={styles.inputWrap}
            />
            <Input
              label="Email*"
              placeholder=""
              type="email"
              className={styles.input}
              required
              validatorMessage={errorsGeneral.email?.message}
              name="email"
              register={registerGeneral}
              onKeyDown={handleEnter}
              parentClassName={styles.inputWrap}
              value={watchGeneral("email")}
            />
            <Input
              label="Phone"
              placeholder=""
              type="phone"
              className={styles.input}
              required
              validatorMessage={errorsGeneral.phone?.message}
              name="phone"
              register={registerGeneral}
              onKeyDown={handleEnter}
              parentClassName={styles.inputWrap}
              value={watchGeneral("phone")}
            />

            <div className={styles.btnGroup}>
              <button onClick={handleSubmitGeneral(onSubmitGeneral)} className={styles.nextBtn}>
                Next
              </button>
              <button onClick={handleSubmitDocuments(onSubmitDocument)} className={styles.nextBtn}>
                Update
              </button>
            </div>
          </form>
        ) : stage === 2 ? (
          <form className={styles.formWrap}>
            {fieldsEdu.map((item, index) => {
              return (
                <div className={styles.subFormWrap} key={item.id}>
                  <p className={styles.formTtl}>Education ({index + 1})</p>

                  {fieldsEdu.length > 1 && (
                    <button className={styles.removeBtn} type="button" onClick={() => removeEdu(index)}>
                      <DeleteIcon />
                    </button>
                  )}

                  <CustomSelect
                    onChange={(x) => {
                      setValueEducation(`eduHistory.${index}.level`, x);

                      if (x.value === "ssce") {
                        setValueEducation(`eduHistory.${index}.program`, "nil");
                        setValueEducation(`eduHistory.${index}.grade`, "nil");
                        setValueEducation(`eduHistory.${index}.maximumGrade`, "nil");
                      }
                    }}
                    validatorMessage={getMessage({
                      value: watchEducation(`eduHistory.${index}.level`).value,
                      errorList: errorsEducation?.eduHistory,
                      index: index,
                      key: "level",
                    })}
                    inputClass={styles.select}
                    name={`eduHistory.${index}.level`}
                    placeholder={"Select..."}
                    label={"Education level"}
                    options={educationHistoryOptions}
                    value={watchEducation(`eduHistory.${index}.level`)}
                    parentClassName={styles.inputWrap}
                  />
                  <Input
                    label="Institute"
                    placeholder=""
                    type="text"
                    className={styles.input}
                    required
                    validatorMessage={
                      errorsEducation?.eduHistory ? errorsEducation?.eduHistory[index]?.institute?.message ?? "" : ""
                    }
                    name={`eduHistory.${index}.institute`}
                    register={registerEducation}
                    onKeyDown={handleEnter}
                    parentClassName={styles.inputWrap}
                    value={watchEducation(`eduHistory.${index}.institute`)}
                  />
                  {watchEducation(`eduHistory.${index}.level`).value !== "ssce" ? (
                    <Input
                      label="Program"
                      placeholder=""
                      type="text"
                      className={styles.input}
                      required
                      validatorMessage={
                        errorsEducation?.eduHistory ? errorsEducation?.eduHistory[index]?.program?.message ?? "" : ""
                      }
                      name={`eduHistory.${index}.program`}
                      register={registerEducation}
                      onKeyDown={handleEnter}
                      parentClassName={styles.inputWrap}
                      value={watchEducation(`eduHistory.${index}.program`)}
                    />
                  ) : (
                    ""
                  )}
                  <Input
                    label="Start date"
                    placeholder=""
                    type="date"
                    className={styles.input}
                    required
                    validatorMessage={
                      errorsEducation?.eduHistory ? errorsEducation?.eduHistory[index]?.startDate?.message ?? "" : ""
                    }
                    name={`eduHistory.${index}.startDate`}
                    register={registerEducation}
                    onKeyDown={handleEnter}
                    parentClassName={styles.inputWrap}
                    value={watchEducation(`eduHistory.${index}.startDate`)}
                  />
                  <Input
                    label="Expected end date"
                    placeholder=""
                    type="date"
                    className={styles.input}
                    required
                    validatorMessage={
                      errorsEducation?.eduHistory ? errorsEducation?.eduHistory[index]?.endDate?.message ?? "" : ""
                    }
                    name={`eduHistory.${index}.endDate`}
                    register={registerEducation}
                    onKeyDown={handleEnter}
                    parentClassName={styles.inputWrap}
                    value={watchEducation(`eduHistory.${index}.endDate`)}
                  />
                  <CustomSelect
                    onChange={(x) => setValueEducation(`eduHistory.${index}.country`, x)}
                    validatorMessage={getMessage({
                      value: watchEducation(`eduHistory.${index}.country`).value,
                      errorList: errorsEducation?.eduHistory,
                      index: index,
                      key: "country",
                    })}
                    inputClass={styles.select}
                    name={`eduHistory.${index}.country`}
                    placeholder={"Select..."}
                    label={"Country"}
                    options={countryOptions}
                    value={watchEducation(`eduHistory.${index}.country`)}
                    parentClassName={styles.inputWrap}
                  />
                  <Input
                    label="Study language"
                    placeholder=""
                    type="text"
                    className={styles.input}
                    required
                    validatorMessage={
                      errorsEducation?.eduHistory
                        ? errorsEducation?.eduHistory[index]?.studyLanguage?.message ?? ""
                        : ""
                    }
                    name={`eduHistory.${index}.studyLanguage`}
                    register={registerEducation}
                    onKeyDown={handleEnter}
                    parentClassName={styles.inputWrap}
                    value={watchEducation(`eduHistory.${index}.studyLanguage`)}
                  />
                  {watchEducation(`eduHistory.${index}.level`).value !== "ssce" ? (
                    <>
                      {" "}
                      <Input
                        label="GPA"
                        placeholder=""
                        type="number"
                        className={styles.input}
                        required
                        validatorMessage={
                          errorsEducation?.eduHistory ? errorsEducation?.eduHistory[index]?.grade?.message ?? "" : ""
                        }
                        name={`eduHistory.${index}.grade`}
                        register={registerEducation}
                        onKeyDown={handleEnter}
                        parentClassName={styles.inputWrap}
                        value={watchEducation(`eduHistory.${index}.grade`)}
                      />
                      <Input
                        label="Maximum GPA possible"
                        placeholder=""
                        type="number"
                        className={styles.input}
                        required
                        validatorMessage={
                          errorsEducation?.eduHistory
                            ? errorsEducation?.eduHistory[index]?.maximumGrade?.message ?? ""
                            : ""
                        }
                        name={`eduHistory.${index}.maximumGrade`}
                        register={registerEducation}
                        onKeyDown={handleEnter}
                        parentClassName={styles.inputWrap}
                        value={watchEducation(`eduHistory.${index}.maximumGrade`)}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
            <div style={{ width: "100%" }}>
              <button className={styles.addBtn} onClick={handleSubmitEducation(addEducation)}>
                <PlusIcon /> Add New
              </button>
            </div>

            <div className={styles.btnGroup}>
              <button
                onClick={handleSubmitEducation(onSubmitEducation, onSubmitEducationError)}
                className={styles.nextBtn}
              >
                Next
              </button>
              <button onClick={handleSubmitDocuments(onSubmitDocument)} className={styles.nextBtn}>
                Update
              </button>
            </div>
          </form>
        ) : stage === 3 ? (
          <form className={styles.formWrap}>
            {fieldsLang.map((item, index) => {
              return (
                <div className={styles.subFormWrap} key={item.id}>
                  <p className={styles.formTtl}>Language Qualification ({index + 1})</p>
                  {index !== 0 && (
                    <button className={styles.removeBtn} type="button" onClick={() => removeLang(index)}>
                      <DeleteIcon />
                    </button>
                  )}

                  <CustomSelect
                    onChange={(x) => setValueLanguage(`languages.${index}.qualification`, x)}
                    validatorMessage={getMessage({
                      value: watchLanguage(`languages.${index}.qualification`).value,
                      errorList: errorsLanguage?.languages,
                      index: index,
                      key: "qualification",
                    })}
                    inputClass={styles.select}
                    name={`languages.${index}.qualification`}
                    placeholder={"Select..."}
                    label={"Qualification"}
                    options={languageQualificationOptions}
                    value={watchLanguage(`languages.${index}.qualification`)}
                    parentClassName={styles.inputWrap}
                  />

                  <Input
                    label="Band (total score)"
                    placeholder=""
                    type="number"
                    className={styles.input}
                    required
                    validatorMessage={
                      errorsLanguage?.languages ? errorsLanguage?.languages[index]?.band?.message ?? "" : ""
                    }
                    name={`languages.${index}.band`}
                    register={registerLanguage}
                    onKeyDown={handleEnter}
                    parentClassName={styles.inputWrap}
                    value={watchLanguage(`languages.${index}.band`)}
                  />
                  <Input
                    label="Exam date"
                    placeholder=""
                    type="date"
                    className={styles.input}
                    required
                    validatorMessage={
                      errorsLanguage?.languages ? errorsLanguage?.languages[index]?.examDate?.message ?? "" : ""
                    }
                    name={`languages.${index}.examDate`}
                    register={registerLanguage}
                    onKeyDown={handleEnter}
                    parentClassName={styles.inputWrap}
                    value={watchLanguage(`languages.${index}.examDate`)}
                  />
                  <Input
                    label="Reading score"
                    placeholder=""
                    type="number"
                    className={styles.input}
                    required
                    validatorMessage={
                      errorsLanguage?.languages ? errorsLanguage?.languages[index]?.readingScore?.message ?? "" : ""
                    }
                    name={`languages.${index}.readingScore`}
                    register={registerLanguage}
                    onKeyDown={handleEnter}
                    parentClassName={styles.inputWrap}
                    value={watchLanguage(`languages.${index}.readingScore`)}
                  />
                  <Input
                    label="Listening score"
                    placeholder=""
                    type="number"
                    className={styles.input}
                    required
                    validatorMessage={
                      errorsLanguage?.languages ? errorsLanguage?.languages[index]?.listeningScore?.message ?? "" : ""
                    }
                    name={`languages.${index}.listeningScore`}
                    register={registerLanguage}
                    onKeyDown={handleEnter}
                    parentClassName={styles.inputWrap}
                    value={watchLanguage(`languages.${index}.listeningScore`)}
                  />
                  <Input
                    label="Writing score"
                    placeholder=""
                    type="number"
                    className={styles.input}
                    required
                    validatorMessage={
                      errorsLanguage?.languages ? errorsLanguage?.languages[index]?.writingScore?.message ?? "" : ""
                    }
                    name={`languages.${index}.writingScore`}
                    register={registerLanguage}
                    onKeyDown={handleEnter}
                    parentClassName={styles.inputWrap}
                    value={watchLanguage(`languages.${index}.writingScore`)}
                  />
                  <Input
                    label="Speaking score"
                    placeholder=""
                    type="number"
                    className={styles.input}
                    required
                    validatorMessage={
                      errorsLanguage?.languages ? errorsLanguage?.languages[index]?.speakingScore?.message ?? "" : ""
                    }
                    name={`languages.${index}.speakingScore`}
                    register={registerLanguage}
                    onKeyDown={handleEnter}
                    parentClassName={styles.inputWrap}
                    value={watchLanguage(`languages.${index}.speakingScore`)}
                  />
                </div>
              );
            })}
            <div style={{ width: "100%" }}>
              <button className={styles.addBtn} onClick={handleSubmitLanguage(addLanguage)}>
                <PlusIcon /> Add New
              </button>
            </div>
            <div className={styles.btnGroup}>
              <button
                onClick={handleSubmitLanguage(onSubmitLanguage, onSubmitLanguageError)}
                className={styles.nextBtn}
              >
                Next
              </button>
              <button onClick={handleSubmitDocuments(onSubmitDocument)} className={styles.nextBtn}>
                Update
              </button>
            </div>
          </form>
        ) : stage === 4 ? (
          <>
            <form className={styles.formUploadWrap}>
              <p className={styles.formTtl}>Personal</p>
              <div className={styles.docsWrap}>
                {personalDocs.map((item, index) => (
                  <Document
                    key={index}
                    value={watchDocuments(item.key)}
                    size={getMegaByte(watchDocuments(item.key)?.size)}
                    uploaded={uploadedDocs[item.key]}
                    remove={() => setValueDocuments(item.key, null)}
                    error={errorsDocuments[item.key]?.message}
                    handleUpload={(e) => handleDocUpload(e, item.fileName, item.key)}
                    doc={item}
                  />
                ))}
              </div>
              <p className={styles.formTtl}>Education & Certificates</p>
              <div className={styles.docsWrap}>
                {eduCertDocs.map((item, index) => (
                  <Document
                    key={index}
                    value={watchDocuments(item.key)}
                    size={getMegaByte(watchDocuments(item.key)?.size)}
                    uploaded={uploadedDocs[item.key]}
                    remove={() => setValueDocuments(item.key, null)}
                    error={errorsDocuments[item.key]?.message}
                    handleUpload={(e) => handleDocUpload(e, item.fileName, item.key)}
                    doc={item}
                  />
                ))}
              </div>
              <p className={styles.formTtl}>Work</p>
              <div className={styles.docsWrap}>
                {workDocs.map((item, index) => (
                  <Document
                    key={index}
                    value={watchDocuments(item.key)}
                    size={getMegaByte(watchDocuments(item.key)?.size)}
                    uploaded={uploadedDocs[item.key]}
                    remove={() => setValueDocuments(item.key, null)}
                    error={errorsDocuments[item.key]?.message}
                    handleUpload={(e) => handleDocUpload(e, item.fileName, item.key)}
                    doc={item}
                  />
                ))}
              </div>

              <>
                <div className={styles.refTtlWrap}>
                  <p className={styles.formTtl}>Work & Academic References</p>
                  <button
                    className={styles.addBtn}
                    onClick={(e) => {
                      e.preventDefault();
                      const prev = watchDocuments("referenceLetters");
                      const newDoc: File = new File([], "");

                      if (prev.every((item) => item.size > 0)) setValueDocuments("referenceLetters", [...prev, newDoc]);
                    }}
                  >
                    <PlusIcon /> New reference
                  </button>
                </div>
                <div className={styles.docsWrap}>
                  {uploadedDocs.referenceLetters.map((item, index) => {
                    return (
                      <Document
                        key={item.key}
                        value={null}
                        size={""}
                        uploaded={{
                          ...uploadedDocs.referenceLetters[index],
                          filename: uploadedDocs.referenceLetters[index].filename.replace(" VobbRef", ""),
                        }}
                        deleteUpload={() => {
                          const prevDocs = uploadedDocs.referenceLetters;
                          prevDocs.splice(index, 1);

                          const docs: uploadDocsType = {
                            passId: initAvatar,
                            passCopy: uploadedDocs.intlPassport,
                            hst: uploadedDocs.highSchoolTranscript,
                            languageProficiency: uploadedDocs.languageCert,
                            cv: uploadedDocs.workExperience,
                            visa: uploadedDocs.previousVisa,
                            hsc: uploadedDocs.highSchoolCert,
                            sop: uploadedDocs.statementOfPurpose,
                            reference: [...prevDocs],
                            others: uploadedDocs.others,
                            undergraduateTranscript: uploadedDocs.undergradTranscript,
                            undergraduateCertificate: uploadedDocs.undergradCert,
                          };

                          deleteDoc(docs);
                        }}
                        remove={() => {}}
                        error={errorsDocuments[ref.key]?.message}
                        handleUpload={(e) => {}}
                        doc={{ ...ref, label: `${ref.label} (${index + 1})` }}
                      />
                    );
                  })}

                  {watchDocuments("referenceLetters").map((item, index) => {
                    return (
                      <Document
                        key={item.name}
                        value={watchDocuments("referenceLetters")[index]}
                        size={getMegaByte(watchDocuments("referenceLetters")[index]?.size)}
                        uploaded={emptyDoc}
                        removeEmpty={() => {
                          const prevList = watchDocuments("referenceLetters");
                          prevList.splice(-1);
                          setValueDocuments("referenceLetters", [...prevList]);
                        }}
                        remove={() => {
                          const prevList = watchDocuments("referenceLetters");

                          if (prevList.length > 1) {
                            prevList.splice(index, 1);
                            setValueDocuments("referenceLetters", [...prevList]);
                          } else {
                            const newList = prevList.map((item, idx) => (idx === index ? new File([], "") : item));

                            setValueDocuments("referenceLetters", newList);
                          }
                        }}
                        error={errorsDocuments[ref.key]?.message}
                        handleUpload={(e) => {
                          console.log(e, index, "before call");
                          handleRefUpload(e, index);
                        }}
                        doc={{
                          ...ref,
                          label: `${ref.label} (${uploadedDocs.referenceLetters.length + index + 1})`,
                        }}
                      />
                    );
                  })}
                </div>
              </>

              <>
                <div className={styles.refTtlWrap}>
                  <p className={styles.formTtl}>Other documents</p>
                  <button
                    className={styles.addBtn}
                    onClick={(e) => {
                      e.preventDefault();
                      const prev = watchDocuments("others");
                      const newDoc: File = new File([], "");

                      if (prev.every((item) => item.size > 0)) setValueDocuments("others", [...prev, newDoc]);
                    }}
                  >
                    <PlusIcon /> Others
                  </button>
                </div>
                <div className={styles.docsWrap}>
                  {uploadedDocs.others.map((item, index) => {
                    return (
                      <Document
                        key={item.key}
                        value={null}
                        size={""}
                        uploaded={{
                          ...uploadedDocs.others[index],
                          filename: uploadedDocs.others[index].filename.replace(" VobbOther", ""),
                        }}
                        deleteUpload={() => {
                          const prevDocs = uploadedDocs.others;
                          prevDocs.splice(index, 1);

                          const docs: uploadDocsType = {
                            passId: initAvatar,
                            passCopy: uploadedDocs.intlPassport,
                            hst: uploadedDocs.highSchoolTranscript,
                            languageProficiency: uploadedDocs.languageCert,
                            cv: uploadedDocs.workExperience,
                            visa: uploadedDocs.previousVisa,
                            hsc: uploadedDocs.highSchoolCert,
                            sop: uploadedDocs.statementOfPurpose,
                            reference: uploadedDocs.referenceLetters,
                            others: [...prevDocs],
                            undergraduateTranscript: uploadedDocs.undergradTranscript,
                            undergraduateCertificate: uploadedDocs.undergradCert,
                          };

                          deleteDoc(docs);
                        }}
                        remove={() => {}}
                        error={errorsDocuments[other.key]?.message}
                        handleUpload={(e) => {}}
                        doc={{
                          ...other,
                          label: `${other.label} (${index + 1})`,
                        }}
                      />
                    );
                  })}

                  {watchDocuments("others").map((item, index) => {
                    return (
                      <Document
                        key={item.name}
                        value={watchDocuments("others")[index]}
                        size={getMegaByte(watchDocuments("others")[index]?.size)}
                        uploaded={emptyDoc}
                        removeEmpty={() => {
                          const prevList = watchDocuments("others");
                          prevList.splice(-1);
                          setValueDocuments("others", [...prevList]);
                        }}
                        remove={() => {
                          const prevList = watchDocuments("others");

                          if (prevList.length > 1) {
                            prevList.splice(index, 1);
                            setValueDocuments("others", [...prevList]);
                          } else {
                            const newList = prevList.map((item, idx) => (idx === index ? new File([], "") : item));

                            setValueDocuments("others", newList);
                          }
                        }}
                        error={errorsDocuments[other.key]?.message}
                        handleUpload={(e) => {
                          handleOtherUpload(e, index);
                        }}
                        doc={{
                          ...other,
                          label: `${other.label} (${uploadedDocs.others.length + index + 1})`,
                        }}
                      />
                    );
                  })}
                </div>
              </>
            </form>
            <button onClick={handleSubmitDocuments(onSubmitDocument)} className={styles.nextBtn}>
              Update
            </button>
          </>
        ) : (
          ""
        )}
      </main>
    </>
  );
};

interface DocProps {
  value: File | null;
  size: number | string;
  uploaded: DocType;
  remove: () => void;
  removeEmpty?: () => void;
  deleteUpload?: () => void;
  doc: doc;
  error: string;
  handleUpload: (e: any) => void;
}

const Document: React.FC<DocProps> = ({
  doc,
  remove,
  value,
  size,
  uploaded,
  error,
  handleUpload,
  removeEmpty,
  deleteUpload,
}) => {
  const item = doc;

  const existingDoc = uploaded?.url !== "";
  const newDoc = value && value?.size > 0;
  const empty = !newDoc && !existingDoc;

  const fileName = newDoc ? value?.name : uploaded?.filename;
  return (
    <>
      <div
        className={`${styles.uploadWrap} ${newDoc ? styles.uploadSuccess : ""} ${
          uploaded?.url !== "" && !newDoc ? styles.uploaded : ""
        }`}
      >
        <p className={styles.uploadLabel}>{item.label}</p>
        <div className={styles.uploadLabelWrap}>
          {newDoc ? (
            <div className={styles.icon}>
              <DocumentSketchIcon />
            </div>
          ) : existingDoc ? (
            <a href={uploaded?.url} target="_blank" rel="noreferrer" className={styles.icon}>
              <DocumentSketchIcon />
            </a>
          ) : (
            ""
          )}
          {!empty ? (
            <div className={styles.formFileUploadLabel}>
              <p>{fileName}</p>
              {newDoc ? <p>{size} MB</p> : ""}
              {newDoc ? <div className={styles.uploadProgress}></div> : ""}
            </div>
          ) : (
            ""
          )}

          {deleteUpload ? (
            <DeleteIcon role={"button"} onClick={deleteUpload} />
          ) : !newDoc ? (
            <label
              role="button"
              className={existingDoc ? styles.emptyLabel : styles.formFileUploadLabel}
              htmlFor={item.key}
            >
              {!existingDoc ? (
                <>
                  <div className={styles.icon}>
                    <DocUploadIcon />
                  </div>
                  <span className={styles.uploadTxt}>Click to upload</span>
                  <p className={styles.formats}>JPG, PNG, PDF, DOC, DOCX ( MAX 5MB )</p>
                </>
              ) : (
                <ChangeIcon title="change document" className={styles.uploadDoc} />
              )}
              <input
                className={styles.formFileUploadInput}
                id={item.key}
                type="file"
                name={`file`}
                onChange={handleUpload}
                accept=".pdf, .png, .jpg, .jpeg, .doc, .docx"
              />
            </label>
          ) : (
            ""
          )}
          {newDoc || (empty && removeEmpty !== undefined) ? (
            <DeleteIcon
              className={empty ? styles.delEmptyDoc : ""}
              role="button"
              tabIndex={0}
              onClick={() => (empty && removeEmpty !== undefined ? removeEmpty() : remove())}
            />
          ) : (
            ""
          )}
        </div>

        {!value && error ? (
          <p className={styles.errorMsg}>
            <WarningIcon /> {error}
          </p>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export { EditProspectUI };

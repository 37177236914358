import { countries } from "country-flags-svg";

export const getCountry = (code: string) => {
  return countries.find((item) => item.iso3 === code?.toUpperCase())?.name ?? code;
};

export const getNationality = (code: string) => {
  return countries.find((item) => item.iso3 === code?.toUpperCase())?.demonym ?? code;
};

export const getFlag = (code: string) => {
  return countries.find((item) => item.iso3 === code?.toUpperCase())?.flag ?? code;
};

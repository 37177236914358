import { VerticalEllipseIcon } from "assets/vectors";
import { DropDownItem } from "components";
import { DropdownList } from "components/Selectbox/dropdownList";
import { useDropdownList } from "hooks/useDropdownList";
import React from "react";
import { TableRowItem } from "../../components";
import { AdminTableBody } from "../../components/tbody";
import { TableDataItem } from "../../components/td";
import styles from "./styles.module.css";

// University Verification Table Body Item
export type VerifiedAgentRoles = "super_agent" | "user" | "admin";

// University Verification Table Body Item
export interface VerifiedAgencyItem {
  agentName: string;
  representativeName: string;
  emailAddress: string;
  agencyAddress: string;
  agencyId: string;
  dateCreated: string;
  role: VerifiedAgentRoles;
}

// University Verification Table Body Props
interface TableBodyProps {
  tableBodyItems: VerifiedAgencyItem[];
  dropdownActions: VerifiedAgentsDropdownActions;
  currentPage: number;
  tableBodyClassName: string;
}

const VerifiedAgentsTableBody: React.FC<TableBodyProps> = ({
  tableBodyItems,
  dropdownActions,
  currentPage,
  tableBodyClassName,
}) => {
  return (
    <AdminTableBody customClassName={styles.tableContainer}>
      {tableBodyItems.map((item, idx) => (
        <TableRowItem key={`${item.agentName} ${idx}`} customClassName={styles.tableRow}>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {(currentPage - 1) * 10 + idx + 1}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.agentName}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {item.representativeName}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName} ${styles.email}`}>{item.emailAddress}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.agencyAddress}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.dateCreated}</TableDataItem>
          <ActionsTableRow actions={{ ...dropdownActions }} item={item} />
        </TableRowItem>
      ))}
    </AdminTableBody>
  );
};

// Action Tab Props
export interface VerifiedAgentsDropdownActions {
  handleAgentDelete: (agent: VerifiedAgencyItem) => void;
  handleAgentRoleUpdate: (agent: VerifiedAgencyItem) => void;
}

// Action tab props
interface ActionTabsProps {
  item: VerifiedAgencyItem;
  actions: VerifiedAgentsDropdownActions;
}

const ActionsTableRow: React.FC<ActionTabsProps> = ({ item, actions }) => {
  // Default Dropdown list
  const dropDownList: DropDownItem[] = [
    { title: "Delete Agency", styles: { color: "#ff5630", fontWeight: 600 }, action: actions.handleAgentDelete },
    { title: "Update Role", styles: { color: "#5b7fff", fontWeight: 600 }, action: actions.handleAgentRoleUpdate },
  ];

  // Hook to use dropdownList
  const { activeDropDownState, dropdownRef, dropdownLinkRef, setActiveDropdownState } = useDropdownList({
    initialDropdownState: false,
  });

  return (
    <TableDataItem className={styles.tableRowItem}>
      <button
        className={styles.deleteCTA}
        ref={dropdownLinkRef}
        id={item.agencyId}
        onClick={() => setActiveDropdownState((state) => !state)}
        key={item.agencyId}
      >
        <VerticalEllipseIcon className={styles.svg} />
      </button>

      {activeDropDownState && (
        <DropdownList
          onDropItemSelect={(dropItem) => dropItem.action && dropItem.action(item)}
          activeDropDownState={activeDropDownState}
          dropdownItems={dropDownList}
          dropdownRef={dropdownRef}
          dropPosition={"top"}
          key={item.agencyId}
        />
      )}
    </TableDataItem>
  );
};

export { VerifiedAgentsTableBody };

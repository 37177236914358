import * as React from "react";
import styles from "./styles.module.css";

interface ModalProps {
  show: boolean;
  onHide?: () => void;
  dialogClassName?: string;
  contentClassName?: string;
  bodyClassName?: string;
}

const useOutsideAlerter = (ref, closeFunction) => {
  React.useEffect(() => {
    /**
     * Hide if clicked on outside of element
     */
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        closeFunction && closeFunction();
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

const Modal: React.FC<ModalProps> = ({ children, show, onHide, dialogClassName, contentClassName }) => {
  if (!show) {
    return null;
  }

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, onHide);

  return (
    <div className={`${styles.modalDialog} ${dialogClassName}`}>
      <div ref={wrapperRef} className={`${styles.modalContent} ${contentClassName} `}>
        {children}
      </div>
    </div>
  );
};

export { Modal };

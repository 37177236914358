import { Input } from "components/Input";
import * as React from "react";
import styles from "./styles.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { AddIcon, EditIcon, WarningIcon } from "assets";
import { countries } from "country-flags-svg";
import { optionType } from "types";
import { CustomSelect, Toggle } from "components";
import { institutionTypeOptions } from "Helper/options";

interface UniData {
  logo: string;
  name: string;
  country: optionType;
  agent: optionType[];
  nationalities: optionType[];
  website: string;
  city?: string;
  commissionPercentage: number;
  isLanguageSchool: boolean;
  institutionType: optionType;
  isEuropean: boolean;
}

const initialValues: UniData = {
  logo: "",
  name: "",
  country: {
    label: "",
    value: "",
  },
  agent: [],
  nationalities: [],
  website: "https://",
  city: "",
  commissionPercentage: 0,
  isLanguageSchool: false,
  institutionType: {
    label: "",
    value: "",
  },
  isEuropean: false,
};

const schema = yup
  .object({
    logo: yup.string().required("Required"),
    name: yup.string().required("Required"),
    country: yup
      .object({ label: yup.string().required("Required"), value: yup.string().required("Required") })
      .required("Required"),
    agent: yup.array(),
    nationalities: yup.array(),
    website: yup.string().url("Enter valid url").required("Required"),
    city: yup.string().notRequired(),
    commissionPercentage: yup.number().notRequired(),
    isLanguageSchool: yup.boolean().required("Required"),
    institutionType: yup
      .object({ label: yup.string().required("Required"), value: yup.string().required("Required") })
      .required("Required"),
    isEuropean: yup.boolean().required("Required"),
  })
  .required();

interface AddInstitutionProps {
  show: boolean;
  close: () => void;
  submit: (data) => void;
  agentList: optionType[];
}

const AddInstitutionUI: React.FC<AddInstitutionProps> = ({ show, close, submit, agentList }) => {
  const [photo, setPhoto] = React.useState({ nameUrl: "", file: "" });
  const [agents, setAgents] = React.useState<optionType[]>([]);
  const [nationalities, setNationalities] = React.useState<optionType[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch: watch,
    setValue,
    reset,
  } = useForm<UniData>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const countryOptions: optionType[] = [];
  const nationalityOptions: optionType[] = [];

  countries.map((item) => {
    countryOptions.push({ value: item.iso3, label: item.name });
    nationalityOptions.push({ value: item.iso3, label: item.demonym });
  });
  nationalityOptions.unshift({ value: "Global", label: "Global" });

  React.useEffect(() => {
    if (!show) closeForm();
  }, [show]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setPhoto({ file, nameUrl: URL?.createObjectURL(file) });
    setValue("logo", file);
  };

  const removeAgent = (id) => {
    const index = agents.findIndex((item) => item.value === id);
    if (index >= 0) {
      agents.splice(index, 1);
      setAgents([...agents]);
      setValue("agent", [...agents]);
    }
  };

  const removeNationality = (iso3) => {
    const index = nationalities.findIndex((item) => item.value === iso3);
    if (index >= 0) {
      nationalities.splice(index, 1);
      setNationalities([...nationalities]);
      setValue("nationalities", [...nationalities]);
    }
  };

  const onSubmit: SubmitHandler<UniData> = (data) => {
    // console.log(data)
    const submitData = {
      university: data.name,
      logo: photo.file,
      country: data.country.value,
      website: data.website,
      agent: agents.map((item) => item.value),
      acceptingNationality: nationalities.map((item) => item.value),
      isLanguageSchool: data.isLanguageSchool,
      isEuropeanSchool: data.isEuropean,
      institutionType: data.institutionType.value,
    };

    if (data.city) submitData["city"] = data.city;
    if (data.commissionPercentage) submitData["commissionPercentage"] = data.commissionPercentage;

    submit(submitData);
  };

  const closeForm = () => {
    reset();
    close();
    setAgents([]);
    setPhoto({ nameUrl: "", file: "" });
  };
  return (
    <aside className={`${styles.wrapper} ${show ? styles.show : styles.hide}`}>
      <AddIcon
        className={`${styles.closeBtn} ${show ? styles.showIcon : styles.hideIcon}`}
        role="button"
        onClick={closeForm}
      />
      <h1 className={styles.ttl}>Add a new Institution</h1>
      <p className={styles.txt}>Complete the form below to add a new institution</p>

      <form className={styles.form}>
        <div className={styles.logoWrap}>
          <img src={photo.nameUrl} alt="logo" />
          <span>Institution logo</span>
          {!watch("logo") && errors.logo?.message ? (
            <span className={styles.message}>
              <WarningIcon /> {errors.logo?.message}
            </span>
          ) : (
            ""
          )}
          <label htmlFor="logo">
            <EditIcon />
            <input
              onChange={handleImageUpload}
              style={{ display: "none" }}
              id="logo"
              type={"file"}
              accept=".png, .jpg, .jpeg"
            />
          </label>
        </div>

        <Input
          label="Institution Name*"
          placeholder="Eg. University of Manitoba"
          type="text"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.name?.message}
          name="name"
          register={register}
        />
        <CustomSelect
          onChange={(x) => setValue("country", x)}
          validatorMessage={
            !(watch("country.label") && watch("country.value"))
              ? errors.country?.message ?? errors.country?.label?.message ?? errors.country?.value?.message ?? ""
              : ""
          }
          inputClass={styles.select}
          name={"country"}
          placeholder={"Select..."}
          label={"Country"}
          options={countryOptions}
          value={watch("country")}
          parentClassName={styles.inputWrap}
        />
        <Input
          label="City"
          placeholder="Eg.Winnipeg"
          type="text"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.city?.message}
          name="city"
          register={register}
        />
        <Input
          label="Website"
          placeholder="Eg. https://www.universityofmanitoba.com"
          type="text"
          parentClassName={styles.inputWrap}
          validatorMessage={errors.website?.message}
          name="website"
          register={register}
        />
        <Input
          label="Commission %"
          placeholder="Eg. 15"
          type="number"
          parentClassName={styles.inputWrap}
          validatorMessage={errors.commissionPercentage?.message}
          name="commissionPercentage"
          register={register}
        />
        <CustomSelect
          onChange={(x) => {
            const index = agents.findIndex((item) => item.value === x.value);
            if (index < 0) {
              setValue("agent", [x, ...agents]);
              setAgents((prev) => [x, ...prev]);
            }
          }}
          validatorMessage={watch("agent").length === 0 ? errors.agent?.message ?? "" : ""}
          inputClass={styles.select}
          name={"agent"}
          placeholder={"Select..."}
          label={"Agent"}
          options={agentList}
          value={{ label: "", value: "" }}
          parentClassName={styles.inputWrap}
        />
        {agents.length > 0 && (
          <div className={styles.agentList}>
            {agents.map((item, index) => (
              <span key={index} className={styles.agent}>
                {item.label} <AddIcon onClick={() => removeAgent(item.value)} role={"button"} />
              </span>
            ))}
          </div>
        )}
        <CustomSelect
          onChange={(x) => {
            const index = nationalities.findIndex((item) => item.value === x.value);
            if (index < 0) {
              setValue("nationalities", [x, ...nationalities]);
              setNationalities((prev) => [x, ...prev]);
            }
          }}
          validatorMessage={watch("nationalities").length === 0 ? errors.nationalities?.message ?? "" : ""}
          inputClass={styles.select}
          name={"nationalities"}
          placeholder={"Select..."}
          label={"Nationality"}
          options={nationalityOptions}
          value={{ label: "", value: "" }}
          parentClassName={styles.inputWrap}
        />
        {nationalities.length > 0 && (
          <div className={styles.agentList}>
            {nationalities.map((item, index) => (
              <span key={index} className={styles.agent}>
                {item.label} <AddIcon onClick={() => removeNationality(item.value)} role={"button"} />
              </span>
            ))}
          </div>
        )}
        <CustomSelect
          onChange={(x) => setValue("institutionType", x)}
          validatorMessage={
            !(watch("institutionType.label") && watch("institutionType.value"))
              ? errors.institutionType?.message ??
                errors.institutionType?.label?.message ??
                errors.institutionType?.value?.message ??
                ""
              : ""
          }
          inputClass={styles.select}
          name={"institutionType"}
          placeholder={"Select..."}
          label={"Institution Type*"}
          options={institutionTypeOptions}
          value={watch("institutionType")}
          parentClassName={styles.inputWrap}
        />
        <label className={styles.check}>
          <input
            checked={watch("isLanguageSchool")}
            onClick={() => setValue("isLanguageSchool", !watch("isLanguageSchool"))}
            type={"checkbox"}
          />
          <span>Language School</span>
        </label>
        <label className={styles.check}>
          <input
            checked={watch("isEuropean")}
            onClick={() => setValue("isEuropean", !watch("isEuropean"))}
            type={"checkbox"}
          />
          <span>European</span>
        </label>

        <button className={styles.submitBtn} onClick={handleSubmit(onSubmit)}>
          Submit
        </button>
      </form>
    </aside>
  );
};

export { AddInstitutionUI };

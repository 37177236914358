import { optionType } from "types";

export interface GeneralInfo {
  status: string;
  id: string;
  publicId: string;
  passportPhoto: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateofBirth: string;
  gender: string;
  maritalStatus: string;
  fathersName: string;
  mothersName: string;
  nationality: string;
  passportNo: string;
  issueDate: string;
  expiryDate: string;
  countryIssued: string;
  birthPlace: string;
  unreadComments: number;
}

export interface ContactInfo {
  country: string;
  address: string;
  city: string;
  state: string;
  email: string;
  phone: string;
}

export interface EducationHistory {
  level: string;
  institute: string;
  program: string;
  startDate: string;
  endDate: string;
  country: string;
  studyLanguage: string;
  grade: string;
  maximumGrade: string;
}

export interface Language {
  qualification: string;
  band: string;
  examDate: string;
  readingScore: string;
  listeningScore: string;
  writingScore: string;
  speakingScore: string;
}

export interface DocType {
  name: string;
  url: string;
}

export interface Documents {
  general: DocType[];
  references: DocType[];
  admin_documents: DocType[];
  others: DocType[];
}

export interface PlanInfo {
  plan: string;
  paymentStatus: boolean;
  remainingApps: number;
}

// Initial states
export const initialGeneralInfo: GeneralInfo = {
  status: "",
  id: "",
  publicId: "",
  passportPhoto: "",
  firstName: "",
  middleName: "",
  lastName: "",
  dateofBirth: "",
  gender: "",
  maritalStatus: "",
  fathersName: "",
  mothersName: "",
  nationality: "",
  passportNo: "",
  issueDate: "",
  expiryDate: "",
  countryIssued: "",
  birthPlace: "",
  unreadComments: 0,
};

export interface ProspectInfo {
  personal: GeneralInfo;
  contact: ContactInfo;
  eduHistory: EducationHistory[];
  languages: Language[];
  documents: Documents;
  planInfo: PlanInfo;
  actionRequired: boolean
}

export const emptyDoc: DocType = {
  name: "",
  url: "",
};

export const initialDocuments: Documents = {
  general: [],
  references: [],
  admin_documents: [],
  others: [],
};

export const initialContactInfo: ContactInfo = {
  address: "",
  country: "",
  city: "",
  state: "",
  phone: "",
  email: "",
};

export const initialPlanInfo: PlanInfo = {
  plan: "",
  paymentStatus: false,
  remainingApps: 0,
};

export const initialProspect: ProspectInfo = {
  personal: initialGeneralInfo,
  contact: initialContactInfo,
  eduHistory: [],
  languages: [],
  documents: initialDocuments,
  planInfo: initialPlanInfo,
  actionRequired: false
};

export const educationHistoryOptions: optionType[] = [
  {
    label: "Grade 12 certificate",
    value: "ssce",
  },
  {
    label: "Bachelors degree",
    value: "bachelors",
  },
  {
    label: "Post graduate diploma",
    value: "post-grad",
  },
  {
    label: "Masters",
    value: "masters",
  },
  {
    label: "PhD",
    value: "phd",
  },
];

export const languageQualificationOptions: optionType[] = [
  {
    label: "IELTS",
    value: "ielts",
  },
  {
    label: "GRE",
    value: "gre",
  },
  {
    label: "TOEFL",
    value: "toefl",
  },
  {
    label: "OIETC",
    value: "oietc",
  },
  {
    label: "MOI",
    value: "moi",
  },
  {
    label: "Duolingo",
    value: "duolingo",
  },
];

import React from "react";
import styles from "../styles.module.css";

// Table body props
interface AdminTableBodyProps {
  customClassName?: string;
}

/**
 * This is a representation of HTML's tbody component
 * ---------------------------------------------------
 * @param customClassName - @interface AdminTableBodyProps
 *
 */

const AdminTableBody: React.FC<AdminTableBodyProps> = ({ children, customClassName = "" }) => {
  return <tbody className={`${styles.adminTableBody} ${customClassName}`}>{children}</tbody>;
};

export { AdminTableBody };

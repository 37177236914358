import { Chart as ChartJS, ChartData, registerables, ChartOptions } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import * as React from "react";

ChartJS.unregister();
ChartJS.register(...registerables);
ChartJS.defaults.font.family = "Barlow";
ChartJS.defaults.font.size = 13;

interface SupportData {
  data: number[];
}
const SupportChart: React.FC<SupportData> = ({ data }) => {
  const chartData: ChartData<"doughnut"> = {
    labels: ["Unattended", "Pending", "Resolved"],
    datasets: [
      {
        label: "Support",
        data: data,
        backgroundColor: ["rgb(124 124 255 / 20%)", "rgb(255 159 64 / 20%)", "rgb(0 193 0 / 20%)"],
        weight: 0.5,
      },
    ],
  };

  const options: ChartOptions<"doughnut"> = {
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          color: "#121212",
        },
        position: "top",
        align: "start"
      },
    },
  };

  const config = {
    type: "doughnut",
    data: chartData,
    options: options,
  };

  return <Doughnut {...config} />;
};

export { SupportChart };

import { EyeIcon, EyeOpenIcon } from "assets";
import React from "react";
import { TableRowItem } from "../../components";
import { AdminTableBody } from "../../components/tbody";
import { TableDataItem } from "../../components/td";
import styles from "./styles.module.css";

// University Institutions Table Body Item
export interface CoursesTableBodyItem {
  degree: string;
  name: string;
  duration: string;
  id: string;
}

// University Institutions Table Body Props
interface TableBodyProps {
  tableBodyItems: CoursesTableBodyItem[];
  tableBodyClassName: string;
  handleView: (id: string) => void;
}

const CoursesTableBody: React.FC<TableBodyProps> = ({ tableBodyItems, tableBodyClassName, handleView }) => {
  return (
    <AdminTableBody customClassName={`${styles.tableContainer} `}>
      {tableBodyItems.map((item, idx) => (
        <TableRowItem key={`${item.name} ${idx}`} customClassName={styles.tableRow}>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName} ${styles.uniName}`}>
            {item.name}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName} ${styles.capitalize}`}>
            {item.degree}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName} ${styles.capitalize}`}>
            {item.duration}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            <button className={styles.viewBtn} onClick={() => handleView(item.id)}>
              <EyeOpenIcon className={styles.fillEye} /> <EyeIcon className={styles.outlineEye} /> View
            </button>
          </TableDataItem>
        </TableRowItem>
      ))}
    </AdminTableBody>
  );
};

export { CoursesTableBody };

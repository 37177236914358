import * as React from "react";
import Preloader from "components/Preloader";
import {
  CreateProspectUI,
  EducationHistory,
  GeneralInfo,
  Language,
  Toast,
  initDoc,
  initDocsUpload,
  uploadDocsType,
} from "components";
import { useApiRequest } from "hooks";
import { getErrorMessage } from "Helper/errorMessage";
import { CreateProspectRequestData, createProspectService, uploadService } from "services";
import { useParams } from "react-router-dom";

const initProspectData = {
  firstName: "",
  lastName: "",
  middleName: "",
  dob: "",
  gender: "",
  maritalStatus: "",
  fName: "",
  mName: "",
  nationality: "",
  passport: {
    number: "",
    issueDate: "",
    expiryDate: "",
    issueCountry: "",
  },
  placeOfBirth: "",
  countryOfBirth: "",
  language: [],
  contactInfo: {
    address: "",
    city: "",
    state: "",
    country: "",
    email: "",
    phone: "",
  },
  education: [],
  suppDocs: initDocsUpload,
};

const CreateProspect = () => {
  const { id: agentID } = useParams();

  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });
  const [prospectData, setProspectData] = React.useState<CreateProspectRequestData>(initProspectData);
  const [clear, setClear] = React.useState(false);

  const { run: runCreate, data: createResponse, requestStatus: createStatus, error: createError } = useApiRequest({});
  const { run: runUpload, data: uploadResponse, requestStatus: uploadStatus, error: uploadError } = useApiRequest({});

  const isValidArrayOfObjects = (arr) => {
    return arr.every((item) => {
      return Object.keys(item).every((key) => item[key] !== "");
    });
  };

  const isValidEduArray = (arr) => {
    return arr.every((item) => {
      return Object.keys(item).every((key) =>
        item.level === "ssce"
          ? key === "aveGrade" || key === "program" || key === "maxGrade"
            ? item[key] === ""
            : item[key] !== ""
          : item[key] !== "",
      );
    });
  };

  const createProspect = (data: CreateProspectRequestData) => {
    runCreate(createProspectService({ data, id: agentID ?? "" }));
  };

  const submit = ({
    docs,
    general,
    eduHistory,
    languages,
  }: {
    docs: FormData | boolean;
    general: GeneralInfo;
    eduHistory: EducationHistory[];
    languages: Language[];
  }) => {
    const lang = languages.map((item) => ({
      qualification: item.qualification.value,
      band: item.band,
      examDate: item.examDate,
      readingScore: item.readingScore,
      writingScore: item.writingScore,
      speakingScore: item.speakingScore,
      listeningScore: item.listeningScore,
    }));

    const edu = eduHistory.map((item) => ({
      level: item.level.value,
      insititute: item.institute,
      program: item.program === "nil" ? "" : item.program,
      startDate: item.startDate,
      endDate: item.endDate,
      country: item.country.value,
      language: item.studyLanguage,
      aveGrade: item.grade === "nil" ? "" : item.grade,
      maxGrade: item.maximumGrade === "nil" ? "" : item.maximumGrade,
    }));

    const data: CreateProspectRequestData = {
      firstName: general.firstName,
      lastName: general.lastName,
      middleName: general.middleName,
      dob: general.dateofBirth,
      gender: general.gender,
      maritalStatus: general.maritalStatus,
      fName: general.fathersName,
      mName: general.mothersName,
      nationality: general.nationality.value,
      passport: {
        number: general.passportNo,
        issueDate: general.issueDate,
        expiryDate: general.expiryDate,
        issueCountry: general.country.value,
      },
      placeOfBirth: general.birthPlace,
      countryOfBirth: general.country.value,
      language: isValidArrayOfObjects(lang) ? lang : [],
      contactInfo: {
        address: general.address,
        city: general.city,
        state: general.state,
        country: general.countryOfResidence.value,
        email: general.email,
        phone: general.phone,
      },
      education: isValidEduArray(edu) ? edu : [],
      suppDocs: initDocsUpload,
    };

    console.log(data, docs);

    if (!docs) {
      createProspect(data);
    } else {
      setProspectData(data);
      runUpload(uploadService(docs));
    }
  };

  React.useMemo(() => {
    if (uploadResponse) {
      if (uploadResponse?.status === 200) {
        const data: any[] = uploadResponse.data.data;

        const intlPassport = data.find((item) => /Intl. Passport/.test(item.originalname)) ?? initDoc;
        const previousVisa = data.find((item) => /Previous Visa/.test(item.originalname)) ?? initDoc;
        const sop = data.find((item) => /Statement of Purpose/.test(item.originalname)) ?? initDoc;
        const hsCert = data.find((item) => /High School Certificate/.test(item.originalname)) ?? initDoc;
        const hsTranscript = data.find((item) => /High School Transcript/.test(item.originalname)) ?? initDoc;
        const undegradCert = data.find((item) => /Undergraduate Certificate/.test(item.originalname)) ?? initDoc;
        const undegradTranscript = data.find((item) => /Undergraduate Transcript/.test(item.originalname)) ?? initDoc;
        const languageCert = data.find((item) => /Language Certificate/.test(item.originalname)) ?? initDoc;
        const workExperience = data.find((item) => /Work Experience/.test(item.originalname)) ?? initDoc;
        const reference = data.filter((item) => /VobbRef/.test(item.originalname));
        const others = data.filter((item) => /VobbOther/.test(item.originalname));
        const passportPhoto = data.find((item) => /Passport photo/.test(item.originalname)) ?? initDoc;

        const docs: uploadDocsType = {
          passId: {
            url: passportPhoto.location,
            key: passportPhoto.key,
            filename: passportPhoto.originalname,
          },
          passCopy: {
            url: intlPassport.location,
            key: intlPassport.key,
            filename: intlPassport.originalname,
          },
          hst: {
            url: hsTranscript.location,
            key: hsTranscript.key,
            filename: hsTranscript.originalname,
          },
          languageProficiency: {
            url: languageCert.location,
            key: languageCert.key,
            filename: languageCert.originalname,
          },
          cv: {
            url: workExperience.location,
            key: workExperience.key,
            filename: workExperience.originalname,
          },
          visa: {
            url: previousVisa.location,
            key: previousVisa.key,
            filename: previousVisa.originalname,
          },
          hsc: {
            url: hsCert.location,
            key: hsCert.key,
            filename: hsCert.originalname,
          },
          sop: {
            url: sop.location,
            key: sop.key,
            filename: sop.originalname,
          },
          reference: reference.map((item) => ({
            url: item.location,
            key: item.key,
            filename: item.originalname,
          })),
          others: others.map((item) => ({
            url: item.location,
            key: item.key,
            filename: item.originalname,
          })),
          undergraduateTranscript: {
            url: undegradTranscript.location,
            key: undegradTranscript.key,
            filename: undegradTranscript.originalname,
          },
          undergraduateCertificate: {
            url: undegradCert.location,
            key: undegradCert.key,
            filename: undegradCert.originalname,
          },
        };

        createProspect({ ...prospectData, suppDocs: docs });
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(uploadResponse, "Unable to upload documents"),
          type: false,
        });
      }
    }
    if (uploadError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(uploadError, "Unable to upload documents"),
        type: false,
      });
    }
  }, [uploadResponse, uploadError]);

  React.useMemo(() => {
    if (createResponse) {
      if (createResponse?.status === 200) {
        setToast({
          show: true,
          heading: "Great!",
          text: "Created prospect successfully!",
          type: true,
        });
        setClear(!clear)
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(createResponse, "Unable to create prospect"),
          type: false,
        });
      }
    }
    if (createError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(createError, "Unable to create prospect"),
        type: false,
      });
    }
  }, [createResponse, createError]);

  const tooLarge = () =>
    setToast({
      show: true,
      heading: "File size error",
      text: "Failed to attach file greater than 5MB. Please reduce size and try again.",
      type: false,
    });

  const showLoader = uploadStatus.isPending || createStatus.isPending;

  return (
    <>
      <Toast {...toast} closeModal={() => setToast({ ...toast, show: false })} />
      {showLoader ? <Preloader /> : ""}
      <CreateProspectUI tooLarge={tooLarge} submit={submit} clear={clear} />
    </>
  );
};

export { CreateProspect };

// Environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL;

/*
=================================
VERIFIED PAGE URLs
=================================
*/

/**
 * get all special offer universities
 *
 * @returns url string
 *
 */

export const getSpecialOffers = () => `${API_URL}/agent/retrieve/special-offer`;

/**
 * post special offer
 *
 * @returns url string
 *
 */

export const createSpecialOffer = () => `${API_URL}/agent/create/special-offer/university`;

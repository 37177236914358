import styles from "./styles.module.css";
import * as React from "react";

interface ToggleProps {
  onClick: () => void;
  checked: boolean;
  className?: string
}

const Toggle = ({ checked, onClick, className }: ToggleProps) => {
  return (
    <label className={`${styles.switch} ${className}`}>
      <input type="checkbox" checked={checked} onClick={onClick} />
      <span className={`${styles.slider} ${styles.round}`}></span>
    </label>
  );
};

export { Toggle };

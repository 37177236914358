import { Input } from "components/Input";
import * as React from "react";
import styles from "./styles.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { AddIcon, EditIcon, WarningIcon } from "assets";
import { countries } from "country-flags-svg";
import { optionType } from "types";
import { CustomSelect, objectComparision } from "components";
import { countryOptions, institutionTypeOptions } from "Helper/options";

interface AgentData {
  agencyName: string;
  repName: string;
  avatar: string;
  email: string;
  phone: string;
  website: string;
  address: string;
  teamSize: optionType;
  studentsPerMonth: optionType;
  bankHolderName: string;
  bankName: string;
  iban: string;
  bankHolderAddress: string;
  bankCountry: optionType;
}

const schema = yup
  .object({
    agencyName: yup.string(),
    repName: yup.string(),
    avatar: yup.string(),
    phone: yup.string(),
    address: yup.string(),
    bankName: yup.string(),
    bankHolderName: yup.string(),
    iban: yup.string(),
    bankHolderAddress: yup.string(),
    website: yup.string().url("Enter valid url"),
    email: yup.string().email("Enter valid email"),
    teamSize: yup.object({ label: yup.string(), value: yup.string() }),
    studentsPerMonth: yup.object({ label: yup.string(), value: yup.string() }),
    bankCountry: yup.object({ label: yup.string(), value: yup.string() }),
  })
  .required();

interface AddInstitutionProps {
  show: boolean;
  close: () => void;
  submit: (data) => void;
  initialValues: AgentData;
}

const EditAgentUI: React.FC<AddInstitutionProps> = ({ show, close, submit, initialValues }) => {
  const [photo, setPhoto] = React.useState({ nameUrl: "", file: "" });
  const [errorMessage, setErrorMessage] = React.useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch: watch,
    setValue,
    reset,
  } = useForm<AgentData>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  React.useEffect(() => {
    reset(initialValues);
  }, [initialValues]);

  React.useEffect(() => {
    if (!show) closeForm();
  }, [show]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setPhoto({ file, nameUrl: URL?.createObjectURL(file) });
    setValue("avatar", file);
  };

  const onSubmit: SubmitHandler<AgentData> = (data) => {
    const editData: any = {};

    if (!objectComparision(data.studentsPerMonth, initialValues.studentsPerMonth))
      editData.numberOfProspects = data.studentsPerMonth.value;
    if (!objectComparision(data.teamSize, initialValues.teamSize)) editData.teamSize = data.teamSize.value;
    if (data.website !== initialValues.website) editData.website = data.website;
    if (data.address !== initialValues.address) editData.address = data.address;
    if (data.agencyName !== initialValues.agencyName) editData.agencyName = data.agencyName;
    if (data.email !== initialValues.email) editData.email = data.email;
    if (data.phone !== initialValues.phone) editData.phone = data.phone;
    if (data.repName !== initialValues.repName) editData.name = data.repName;
    if (data.bankHolderName !== initialValues.bankHolderName) editData.bankHolderName = data.bankHolderName;
    if (data.bankName !== initialValues.bankName) editData.bankName = data.bankName;
    if (data.iban !== initialValues.iban) editData.iban = data.iban;
    if (data.bankHolderAddress !== initialValues.bankHolderAddress) editData.holderAddress = data.bankHolderAddress;
    if (!objectComparision(data.bankCountry, initialValues.bankCountry)) editData.bankCountry = data.bankCountry.value;

    if (photo.file) editData.avatar = photo.file;

    if (Object.keys(editData).length === 0) {
      setErrorMessage("You haven't updated any data");
      return;
    }
    setErrorMessage("");
    submit(editData);

    console.log(editData);
  };

  const teamSizeOptions: optionType[] = [
    {
      value: "1-10",
      label: "1-10",
    },
    {
      value: "10-30",
      label: "10-30",
    },
    {
      value: "30-50",
      label: "30-50",
    },
    {
      value: "More",
      label: "More",
    },
  ];

  const studentSizeOptions: optionType[] = [
    {
      value: "1-10",
      label: "1-10",
    },
    {
      value: "10-30",
      label: "10-30",
    },
    {
      value: "30-50",
      label: "30-50",
    },
    {
      value: "More",
      label: "More",
    },
  ];

  const closeForm = () => {
    reset();
    close();
    setPhoto({ nameUrl: "", file: "" });
  };
  return (
    <aside className={`${styles.wrapper} ${show ? styles.show : styles.hide}`}>
      <AddIcon
        className={`${styles.closeBtn} ${show ? styles.showIcon : styles.hideIcon}`}
        role="button"
        onClick={closeForm}
      />
      <h1 className={styles.ttl}>Edit Agency</h1>
      <p className={styles.txt}>Complete the form below to edit an agency&apos;s profile</p>

      <form className={styles.form}>
        <div className={styles.logoWrap}>
          <img src={photo.nameUrl === "" ? watch("avatar") : photo.nameUrl} alt="logo" />
          <span>Agency logo</span>
          {!watch("avatar") && errors.avatar?.message ? (
            <span className={styles.message}>
              <WarningIcon /> {errors.avatar?.message}
            </span>
          ) : (
            ""
          )}
          <label htmlFor="avatar">
            <EditIcon />
            <input
              onChange={handleImageUpload}
              style={{ display: "none" }}
              id="avatar"
              type={"file"}
              accept=".png, .jpg, .jpeg"
            />
          </label>
        </div>

        <Input
          label="Agency Name"
          placeholder=""
          type="text"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.agencyName?.message}
          name="agencyName"
          register={register}
        />
        <Input
          label="Representative Name"
          placeholder=""
          type="text"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.repName?.message}
          name="repName"
          register={register}
        />
        <Input
          label="Email"
          placeholder=""
          type="email"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.email?.message}
          name="email"
          register={register}
        />
        <Input
          label="Address"
          placeholder=""
          type="text"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.address?.message}
          name="address"
          register={register}
        />
        <Input
          label="Website"
          placeholder=""
          type="text"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.website?.message}
          name="website"
          register={register}
        />
        <Input
          label="Phone Number"
          placeholder=""
          type="text"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.phone?.message}
          name="phone"
          register={register}
        />
        <CustomSelect
          onChange={(x) => setValue("teamSize", x)}
          validatorMessage={
            !(watch("teamSize.label") && watch("teamSize.value"))
              ? errors.teamSize?.message ?? errors.teamSize?.label?.message ?? errors.teamSize?.value?.message ?? ""
              : ""
          }
          inputClass={styles.select}
          name={"teamSize"}
          placeholder={"Select..."}
          label={"Team Size"}
          options={teamSizeOptions}
          value={watch("teamSize")}
          parentClassName={styles.inputWrap}
        />
        <CustomSelect
          onChange={(x) => setValue("studentsPerMonth", x)}
          validatorMessage={
            !(watch("studentsPerMonth.label") && watch("studentsPerMonth.value"))
              ? errors.studentsPerMonth?.message ??
                errors.studentsPerMonth?.label?.message ??
                errors.studentsPerMonth?.value?.message ??
                ""
              : ""
          }
          inputClass={styles.select}
          name={"studentsPerMonth"}
          placeholder={"Select..."}
          label={"No. of students per month"}
          options={studentSizeOptions}
          value={watch("studentsPerMonth")}
          parentClassName={styles.inputWrap}
        />

        <p className={styles.bankTtl}>Bank Details</p>
        <Input
          label="Bank Name"
          placeholder=""
          type="text"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.bankName?.message}
          name="bankName"
          register={register}
        />
        <Input
          label="Bank Holder Name"
          placeholder=""
          type="text"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.bankHolderName?.message}
          name="bankHolderName"
          register={register}
        />
        <Input
          label="IBAN"
          placeholder=""
          type="text"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.iban?.message}
          name="iban"
          register={register}
        />
        <Input
          label="Bank Holder Address"
          placeholder=""
          type="text"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.bankHolderAddress?.message}
          name="bankHolderAddress"
          register={register}
        />
        <CustomSelect
          onChange={(x) => setValue("bankCountry", x)}
          validatorMessage={
            !(watch("bankCountry.label") && watch("bankCountry.value"))
              ? errors.bankCountry?.message ??
                errors.bankCountry?.label?.message ??
                errors.bankCountry?.value?.message ??
                ""
              : ""
          }
          inputClass={styles.select}
          name={"bankCountry"}
          placeholder={"Select..."}
          label={"Bank Country"}
          options={countryOptions}
          value={watch("bankCountry")}
          parentClassName={styles.inputWrap}
        />

        <button className={styles.submitBtn} onClick={handleSubmit(onSubmit)}>
          Submit
        </button>
        {errorMessage ? <p className={styles.errorMessage}>{errorMessage}</p> : ""}
      </form>
    </aside>
  );
};

export { EditAgentUI };

import { Pagination, universityStatusCount, UniversityVerificationUI, VerificationTableBodyItem } from "components";
import Preloader from "components/Preloader";
import { useApiRequest } from "hooks";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getAgentsForUniverstyVerificationService } from "services";
import { Routes } from "routes/utils";

const UniversityVerification: React.FC = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [totalPages, setTotalPages] = React.useState<number>(1);
  const [count, setCount] = React.useState<number>(0);

  const { run: runVerifiedAgencyFetch, data: agentsData, requestStatus } = useApiRequest({});

  // Fetch all verified agents
  useEffect(() => {
    runVerifiedAgencyFetch(getAgentsForUniverstyVerificationService({ pageNo: currentPage }));
  }, [runVerifiedAgencyFetch, currentPage]);

  // Request Error Data from API fetching hook
  // const agentsFetchrequestError = useMemo(
  //   () => (agentsData?.response ? agentsData?.response?.data : null),
  //   [agentsData?.response],
  // );

  // Formatted verified agents
  const preformattedAgentsData = useMemo<VerificationTableBodyItem[]>(() => {
    if (agentsData?.status === 200) {
      setCount(agentsData?.data?.data?.count)
      setTotalPages(agentsData?.data?.data?.availablePages);
      const fetchedAgencies = agentsData?.data?.data?.fetchedRequests;

      return fetchedAgencies.map((agency) => ({
        agencyName: agency?.agentRequesting?.agencyName,
        representativeName: agency?.agentRequesting?.name,
        id: agency?.agentRequesting?._id,
        universityCount: universityStatusCount({
          pending: agency?.universities?.filter((item) => {
            return item.isVerified === "pending";
          }).length,
          accepted: agency?.universities?.filter((item) => {
            return item.isVerified === "accepted";
          }).length,
          denied: agency?.universities?.filter((item) => {
            return item.isVerified === "denied" || item.isverified === "rejected";
          }).length,
        }),
        email: agency?.agentRequesting?.email,
        address: agency?.agentRequesting?.address,
        phone: agency?.agentRequesting?.phone ?? "",
      }));
    }

    return [];
  }, [agentsData]);

  const showPreloader = requestStatus.isPending;

  const fetchUniversityList = (id, contact) => {
    navigate(`${Routes.uniVerification}/${id}`, {
      state: contact,
    });
  };
  return (
    <>
      {showPreloader && <Preloader />}
      <UniversityVerificationUI
        tableBodyItems={preformattedAgentsData}
        handleViewUniversities={(id, contact) => fetchUniversityList(id, contact)}
        count={count}
      />

      <Pagination pages={totalPages} handlePage={(x) => setCurrentPage(x)} />
    </>
  );
};

export { UniversityVerification };

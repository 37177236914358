import { appendParams } from "Helper/appendParams";

// Environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL;

/*
=================================
STUDENT APPLICATION URLS
=================================
*/

/**
 * Application url
 *
 * @returns url string
 *
 */

export const applicationURL = ({ pageNo, status, university, country, agent, search, source }) =>
  `${API_URL}/admin/fetche-all-applications?${appendParams({
    pageNo,
    noOfRequests: 10,
    university,
    agent,
    prospectStatus: "",
    country,
    search,
    status,
    superAgent: "",
    source,
  })}`;

export const getSingleApplicationURL = (id) => `${API_URL}/application/${id}`;

export const addDocumentURL = (id) => `${API_URL}/agent/application/edit/${id}`;

export const updateApplicationStatusURL = (id) => `${API_URL}/applications/${id}/update`;

export const fetchUnisUnpaginatedURL = () => `${API_URL}/fetch-universities-unpaginated`;

export const fetchProspectsUnpaginatedURL = (agentId) => `${API_URL}/all-prospects-undr-agent/${agentId}`;

export const fetchCoursesUnpaginatedURL = () => `${API_URL}/courses-list`;

export const fetchCountryUnpaginatedURL = () => `${API_URL}/agent/country/list`;

export const createApplicationURL = ({ prospectId, agentId }) =>
  `${API_URL}/admin/create-prospect-application/${prospectId}/${agentId}`;

export const editApplicationURL = (id) => `${API_URL}/admin/edit-prospect-application/${id}`;

import { Pagination, UniversityTableBodyItem, ViewUniversitiesUI } from "components";
import Preloader from "components/Preloader";
import { useApiRequest } from "hooks";
import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getUniversityForVerificationService, patchUniversityStatus } from "services";
import { Routes } from "routes/utils";

const ViewUniversities: React.FC = () => {
  const navigate = useNavigate();
  const { state: contact } = useLocation();

  // get agent id from path
  const { id: agentID } = useParams();

  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [totalPages, setTotalPages] = React.useState<number>(1);
  const [universityCount, setUniversityCount] = React.useState<number>(0);

  const { run: runUniversityListFetch, data: uinversityData, requestStatus: requestStatus } = useApiRequest({});
  const { run: runUpdateUniversity, data: updateData, requestStatus: updateRequestStatus } = useApiRequest({});

  // Fetch universities per agent
  const fetchUniversityList = (agentID) => {
    runUniversityListFetch(getUniversityForVerificationService({ pageNo: currentPage, agentID }));
  };

  // Update university verification status
  const updateUniStatus = (data) => {
    runUpdateUniversity(patchUniversityStatus(data));
  };

  // Rerun fetch for universitylist during pagination and after update
  useEffect(() => {
    fetchUniversityList(agentID);
  }, [currentPage, agentID, updateData]);

  // Request Error Data from API fetching hook
  // const universitiesFetchrequestError = useMemo(
  //   () => (uinversityData?.response ? uinversityData?.response?.data : null),
  //   [uinversityData?.response],
  // );

  // Request Error Data from API fetching hook
  // const statusUpdateError = useMemo(
  //   () => (updateData?.response ? updateData?.response?.data : null),
  //   [updateData?.response],
  // );

  // Append prefix or suffix to commission
  const displayCommission = (type, amount) => {
    let commission = "";
    if (type === "fixed") {
      commission = `$ ${amount}`;
    } else {
      commission = `${amount}%`;
    }
    return commission;
  };

  // Formatted university data
  const preformattedUniversityData = useMemo<UniversityTableBodyItem[]>(() => {
    if (uinversityData?.status === 200) {
      setTotalPages(uinversityData?.data?.data?.availablePages);
      setUniversityCount(uinversityData?.data?.data?.count);
      const fetchedUniversities = uinversityData?.data?.data?.fetchedRequests;
      // console.log(fetchedUniversities);

      return fetchedUniversities.map((university) => ({
        universityName: university.university.university,
        universityID: university.university._id,
        requestID: uinversityData?.data?.data?.requestID,
        country: university.university.country,
        commissionReceived: displayCommission(
          university.universityCommissionType,
          university.universityPayedCommissions,
        ),
        commissionPaid: displayCommission(university.commissionType, university.commissions),
        status: university.isverified,
      }));
    }

    return [];
  }, [uinversityData]);

  const showPreloader = requestStatus.isPending || updateRequestStatus.isPending;

  const backToVerification = () => {
    navigate(Routes.uniVerification);
  };
  return (
    <>
      {showPreloader && <Preloader />}
      <ViewUniversitiesUI
        tableBodyItems={preformattedUniversityData}
        universityCount={universityCount}
        back={backToVerification}
        contactDetails={contact}
        updateUniversity={(university_id: string, request_id: string, status: string) =>
          updateUniStatus({ university_id, request_id, status })
        }
      />
      <Pagination pages={totalPages} handlePage={(x) => setCurrentPage(x)} />
    </>
  );
};

export { ViewUniversities };

import React from "react";
import { TableRowItem } from "../../components";
import { AdminTableBody } from "../../components/tbody";
import { TableDataItem } from "../../components/td";
import styles from "./styles.module.css";

// Application Table Body Item
export interface AgentApplicationsTableBodyItem {
  unreadCommentCount: {
    student: number;
    subAgent: number;
    superAgent: number;
  };
  source: string;
  university: {
    name: string;
    id: string;
    logo: string;
    country: { name: string; code: string };
  };
  course: {
    name: string;
    id: string;
    degree: string;
  };
  prospect: {
    name: string;
    id: string;
    photo: string;
    nationality: string;
  };
  status: string;
  id: string;
  intakeYear: string;
  intakeMonth: string;
  dateCreated: string;
}
interface unreadComments {
  student: number;
  subAgent: number;
  superAgent: number;
}

// University Verification Table Body Props
interface TableBodyProps {
  tableBodyItems: AgentApplicationsTableBodyItem[];
  tableBodyClassName: string;
  currentPage: number;
}

const AgentApplicationsTableBody: React.FC<TableBodyProps> = ({
  tableBodyItems,
  tableBodyClassName,
  currentPage,
}) => {

  return (
    <AdminTableBody customClassName={`${styles.tableContainer} `}>
      {tableBodyItems.map((item, idx) => {
        const commentCount =
          item.unreadCommentCount.student + item.unreadCommentCount.subAgent + item.unreadCommentCount.superAgent;

        return (
          <TableRowItem key={`${item.id} ${idx}`} customClassName={styles.tableRow}>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
              {(currentPage - 1) * 10 + idx + 1}
            </TableDataItem>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
              <div className={styles.student}>
                <span>{item.prospect.name}</span>
              </div>
            </TableDataItem>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
              <div className={styles.universityWrap}>
                <img src={item.university.logo} />
                <div className={styles.student}>
                  <span>{item.university.name}</span>
                  <span>{item.university.country.name}</span>
                </div>
              </div>
            </TableDataItem>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
              <div className={styles.student}>
                <span>{item.course.name}</span>
                <span>{item.course.degree}</span>
                <span>
                  {item.intakeMonth}, {item.intakeYear} intake
                </span>
              </div>
            </TableDataItem>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName} ${styles.statWrap}`}>
              {item.status}
            </TableDataItem>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.dateCreated}</TableDataItem>
          </TableRowItem>
        );
      })}
    </AdminTableBody>
  );
};

export { AgentApplicationsTableBody };

import { CaretLeftDark, CaretLeftLight, CaretRightDark, CaretRightLight } from "assets/vectors";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
// import { useAppSelector } from "../../redux/hooks";
import styles from "./pagination.module.css";

interface PageProps {
  pages: number;
  handlePage: (page: number) => void;
  currentPage?: number;
  bottomText?: string;
  total?: number;
  pageSize?: number;
  reset?: boolean;
}

const Pagination: React.FC<PageProps> = (props) => {
  // const search = useAppSelector((state) => state.search);
  const location = useLocation();
  const [middlePages, setMiddle] = useState<number[]>([]);
  const [activePage, setActive] = useState(1);

  function calcMiddle(newPage: number) {
    let middle: number[] = [];
    if (props.pages - 1 <= 5) {
      for (let i = 2; i < props.pages; i++) {
        middle.push(i);
      }
    } else if (newPage === 1) {
      middle = [2, -1];
    } else if (newPage === props.pages) {
      middle = [-1, newPage - 1];
    } else if (newPage === 2) {
      middle = [2, 3, -1];
    } else if (newPage === 3) {
      middle = [2, 3, 4, -1];
    } else if (newPage + 1 === props.pages) {
      middle = [-1, newPage - 1, newPage];
    } else {
      middle = [-1, newPage - 1, newPage, newPage + 1];
      if (newPage + 1 < props.pages - 1) middle.push(-1);
    }

    setActive(newPage);
    setMiddle(middle);
  }

  function updateSearch(newPage: number) {
    calcMiddle(newPage);
    props.handlePage(newPage);
  }

  function handleControl(curr: number, forward: boolean) {
    if (forward && curr < props.pages) updateSearch(curr + 1);
    else if (!forward && curr > 1) updateSearch(curr - 1);
  }

  useEffect(() => {
    calcMiddle(1);
  }, [location.pathname, props.pages]);

  useEffect(() => {
    props.currentPage && setActive(props.currentPage);
  }, [props.currentPage]);

  useEffect(() => {
    setActive(props.currentPage ?? 1);
  }, [props.reset]);

  if (props.pages <= 1) {
    return null;
  }
  return (
    <div className={styles.v_saved_list_ctrl_sec}>
      <div className={styles.v_saved_list_ctrl}>
        <span
          className={`${styles.v_saved_list_ctrl_box} ${activePage === 1 && styles.v_saved_list_ctrl_end}`}
          onClick={() => handleControl(activePage, false)}
        >
          {activePage === 1 ? <CaretLeftLight /> : <CaretLeftDark />}
        </span>
        <span
          className={`${styles.v_saved_list_ctrl_box} ${activePage === 1 && styles.v_saved_list_ctrl_active}`}
          onClick={() => updateSearch(1)}
        >
          1
        </span>
        {middlePages.length > 0 &&
          middlePages.map((item, index) => (
            <span
              key={index}
              className={`${styles.v_saved_list_ctrl_box} ${activePage === item && styles.v_saved_list_ctrl_active}`}
              onClick={() => item !== -1 && updateSearch(item)}
            >
              {item === -1 ? "..." : item}
            </span>
          ))}
        <span
          className={`${styles.v_saved_list_ctrl_box} ${activePage === props.pages && styles.v_saved_list_ctrl_active}`}
          onClick={() => updateSearch(props.pages)}
        >
          {props.pages}
        </span>
        <span
          className={`${styles.v_saved_list_ctrl_box} ${activePage === props.pages && styles.v_saved_list_ctrl_end}`}
          onClick={() => handleControl(activePage, true)}
        >
          {activePage === props.pages ? <CaretRightLight title="caret" /> : <CaretRightDark title="caret" />}
        </span>
      </div>
      {/* {props.bottomText && (
        <p className={styles.v_saved_list_ctrl_txt}>
          {props.pageSize && props.total && (
            <span>
              {props.pageSize} of {props.total}
            </span>
          )}
          {props.bottomText}
        </p>
      )} */}
    </div>
  );
};

export { Pagination };

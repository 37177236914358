import React from "react";

interface TableHeaderItemProps {
  className?: string;
}

/**
 * This component is a representation of the HTML's th element.
 */

const TableHeaderItem: React.FC<TableHeaderItemProps> = ({ children, className = "" }) => {
  return <th className={className}>{children}</th>;
};

export { TableHeaderItem };

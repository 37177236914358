export const getInitials = (name) => {
  let initial = "";
  if (name) {
    const nameArray = name.split(" ");
    if (nameArray.length > 1) {
      initial = name.split(" ")[0].charAt(0) + name.split(" ")[1].charAt(0);
    } else {
      initial = name.split(" ")[0].charAt(0);
    }
  }
  return initial.toUpperCase();
};

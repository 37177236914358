import React from "react";

interface TableHeadProps {
  className?: string;
}

/**
 * This component is a representation of the HTML's thead element.
 * --------------------------------------------------------------
 * @param className - @interface TableHeadProps
 */

const TableHead: React.FC<TableHeadProps> = ({ children, className }) => {
  return <thead className={className}>{children}</thead>;
};

export { TableHead };

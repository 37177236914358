import { CloseIcon, EditIcon } from "assets";
import { Modal } from "components/Modal";
import { ModalBody } from "components/Modal/components";
import { Toggle } from "components/Toggle";
import * as React from "react";
import styles from "./styles.module.css";

export interface SignupData {
  agencyName: string;
  agentName: string;
  email: string;
  address: string;
  phone: string;
  website: string;
  teamSize: string;
  studentSize: string;
}

interface ViewSignupRequestProps {
  show: boolean;
  closeModal: () => void;
  verify: () => void;
  agent: SignupData;
}

const ViewSignupRequestModal: React.FC<ViewSignupRequestProps> = ({ show, closeModal, agent, verify }) => {
  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <ModalBody>
          <CloseIcon onClick={closeModal} role={"button"} className={styles.closeBtn} />
          <h1 className={styles.ttl}>{agent.agencyName}</h1>
          {agent.website ? (
            <a href={agent.website} target={"_blank"} rel="noreferrer" className={styles.txt2}>
              {agent.website}
            </a>
          ) : (
            ""
          )}
          <p className={styles.txt1}>
            <span>Representative:</span> {agent.agentName}
          </p>
          <p className={styles.txt1}>
            <span>Email:</span> {agent.email}
          </p>
          <p className={styles.txt1}>
            <span>Phone:</span> {agent.phone}
          </p>
          <p className={styles.txt1}>
            <span>Address:</span> {agent.address}
          </p>
          <p className={styles.txt1}>
            <span>Team size:</span>
            {agent.teamSize.toLowerCase() === "more" ? "Over 50" : agent.teamSize}
          </p>
          <p className={styles.txt1}>
            <span>Students per month:</span>
            {agent.studentSize.toLowerCase() === "more" ? "Over 50" : agent.studentSize}
          </p>
          <button onClick={verify} className={styles.verifyBtn}>
            Verify
          </button>
        </ModalBody>
      </Modal>
    </>
  );
};

export { ViewSignupRequestModal };

import { Modal, SelectBox, VerifiedAgencyItem, VerifiedAgentRoles } from "components";
import Preloader from "components/Preloader";
import { useApiRequest } from "hooks";
import React, { useEffect, useState } from "react";
import { updateVerifAgencyServ } from "../../../services/admin/index";
import { DropDownItem } from "../../Selectbox/index";
import styles from "./styles.module.css";

// Modal Props
interface IProps {
  openDeleteModal: boolean;
  agentToBeDeleted: VerifiedAgencyItem | null;
  handleAgentDeleteSubmit: () => void;
  handleDeleteCancel: () => void;
}

const VerifiedAgentsDeleteModal: React.FC<IProps> = ({
  openDeleteModal,
  agentToBeDeleted,
  handleAgentDeleteSubmit,
  handleDeleteCancel,
}) => {
  return (
    <Modal show={openDeleteModal} onHide={() => {}}>
      <section className={styles.modalContainer}>
        <h2>
          Are you sure you want to delete <span>{agentToBeDeleted?.agentName}</span>? This action cannot be undone.
        </h2>
        <aside className={styles.modalBtnContainer}>
          <button className={styles.deleteBtn} onClick={handleAgentDeleteSubmit}>
            Confirm
          </button>
          <button className={styles.cancelBtn} onClick={handleDeleteCancel}>
            Cancel
          </button>
        </aside>
      </section>
    </Modal>
  );
};
// Shared Modal Props
interface IUpdateProps {
  handleMessageUpdateCancel: (performRefetch: boolean) => void;
  agentDetails: VerifiedAgencyItem | null;
}

// Support Message Modal
const VerifiedAgentsUpdateModal: React.FC<IUpdateProps> = ({ handleMessageUpdateCancel, agentDetails }) => {
  const [defaultRole, setDefaultRole] = useState(() => agentDetails?.role);
  const [performDataRefetch, setPerformDataRefetch] = useState(false);

  // API Status Update Request Hook
  const { run: runAgencyUpdate, data: agencyUpdateData, requestStatus: agencyUpdateStatus } = useApiRequest({});

  // Status update function
  const handleStatusUpdate = (updatedStatus) => {
    // Status to be changed to
    const statusToBeChanged = updatedStatus?.key?.toLowerCase() as VerifiedAgentRoles;

    // Confirm update delete
    if (agentDetails) {
      runAgencyUpdate(
        updateVerifAgencyServ({
          agentId: agentDetails?.agencyId,
          role: statusToBeChanged,
        }),
      );

      setPerformDataRefetch(true);
    }
  };

  // Dropdown list
  const dropDownList: DropDownItem[] = [
    { title: "Super Agent", key: "super_agent", styles: { justifyContent: "flex-start" } },
    { title: "User", key: "user", styles: { justifyContent: "flex-start" } },
  ];

  // Update status if update is successful
  useEffect(() => {
    if (agencyUpdateData?.data) {
      setDefaultRole(agencyUpdateData?.data?.data?.role as VerifiedAgentRoles);
    }
  }, [agencyUpdateData?.data]);

  return (
    <Modal show={true} onHide={() => {}}>
      <section className={`${styles.modalContainer} ${styles.verifiedAgents}`}>
        {agencyUpdateStatus.isPending && <Preloader />}
        <aside className={styles.messageTitle}>
          <h3>Agent Name </h3>
          <p>{agentDetails?.agentName}</p>
        </aside>
        <aside className={styles.messageBody}>
          <h3>Email </h3>
          <p>{agentDetails?.emailAddress}</p>
        </aside>

        <aside>
          <h3>Current Role</h3>
          <p>{defaultRole?.toUpperCase()}</p>
        </aside>

        {agentDetails?.role !== "admin" && (
          <aside className={`${styles.messageStatusUpdate}`}>
            <h3>Update Agent Role</h3>
            <SelectBox
              selectGroupID="status display"
              dropPosition="bottom"
              containerStyles={{ maxWidth: "200px" }}
              defaultSelected={{ title: defaultRole || "User" }}
              dropdownItems={dropDownList}
              onDropItemSelect={handleStatusUpdate}
            />
          </aside>
        )}

        <aside className={styles.modalBtnContainer} style={{ marginTop: "10px" }}>
          <button
            className={styles.cancelBtn}
            onClick={() => handleMessageUpdateCancel(performDataRefetch)}
            style={{ marginLeft: "0px", marginTop: "20px" }}
          >
            Close Modal
          </button>
        </aside>
      </section>
    </Modal>
  );
};

export { VerifiedAgentsDeleteModal, VerifiedAgentsUpdateModal };

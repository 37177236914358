import { useCallback, useState } from "react";
import { HorizontalTabItemProps } from "../../types";

// Hook interface
interface IProps<T> {
  tabItems: HorizontalTabItemProps<T>[];
}

// Horizontal tab hook
function useHorizontalTab<T>({ tabItems }: IProps<T>) {
  const [currentTab, setCurrentTab] = useState(() => tabItems[0]);
  const [doesRouteExist, setDoesRouteExist] = useState(false);

  // Tab updater function
  const updateActiveTab = useCallback((tabItem: HorizontalTabItemProps<T>) => setCurrentTab(tabItem), []);

  // Check if a tab is active
  const isActive = useCallback(
    (tab: HorizontalTabItemProps<keyof T>) => currentTab?.title.toLowerCase() === tab.title.toLowerCase(),
    [currentTab?.title],
  );

  return { currentTab, doesRouteExist, isActive, updateActiveTab, setDoesRouteExist };
}

export { useHorizontalTab };

import Preloader from "components/Preloader";
import { SpecialOfferUI } from "components/SpecialOffers";
import { SpecialOfferTableBodyItem } from "components/table/tableBodyVariants/SpecialOfferTable";
import { getCountry } from "Helper/country";
import { useApiRequest } from "hooks";
import React, { useCallback, useEffect, useMemo } from "react";
import { createSpecialOfferService, getSpecialOffersService, uploadService } from "services";

const SpecialOffers: React.FC = () => {
  const [count] = React.useState<number>(0);
  const [serverUrl, setServerUrl] = React.useState<string>("");
  const [serverError, setServerError] = React.useState<string>("");

  //API requests
  const { run: runSpecialOffersfetch, data: universityData, requestStatus } = useApiRequest({});
  const { run: runUploadImage, data: imageUploadResponse, requestStatus: imageUploadStatus } = useApiRequest({});
  const {
    run: runCreateSpecialOffer,
    data: createSpecialOfferResponse,
    requestStatus: createSpecialOfferStatus,
  } = useApiRequest({});

  const handleImageAsyncUpload = useCallback(
    async (data) => {
      runUploadImage(uploadService(data));
      await imageUploadResponse;
    },
    [runUploadImage, imageUploadResponse],
  );

  const handleCreateSpecialOffer = useCallback(
    async (data) => {
      runCreateSpecialOffer(createSpecialOfferService(data));
    },
    [runCreateSpecialOffer],
  );

  const clearServerUrl = useCallback(() => {
    setServerUrl("");
  }, []);

  useEffect(() => {
    if (imageUploadResponse?.status === 200) {
      const path = imageUploadResponse?.data?.data?.[0]?.path;
      setServerUrl(path);
    }
  }, [imageUploadResponse]);

  useEffect(() => {
    if (createSpecialOfferResponse?.status === 200) {
      window.location.reload();
    } else if (createSpecialOfferResponse?.response.status >= 400) {
      setServerError(createSpecialOfferResponse?.response?.data?.message);
    }
  }, [createSpecialOfferResponse]);

  // Fetch all verified agents
  useEffect(() => {
    runSpecialOffersfetch(getSpecialOffersService());
  }, [runSpecialOffersfetch]);

  // Request Error Data from API fetching hook
  // const specialOfferFetchrequestError = useMemo(
  //   () => (universityData?.response ? universityData?.response?.data : null),
  //   [universityData?.response],
  // );

  // Formatted special offers
  const preformatteduniversityData = useMemo<SpecialOfferTableBodyItem[]>(() => {
    if (universityData?.status === 200) {
      const fetchedUniversities = universityData?.data.data.fetchedUniversity;

      return fetchedUniversities.map((offer) => ({
        universityName: offer.university.name,
        tuition: offer.university.tuition.isFixed
          ? `${offer.university.tuition?.tuitionCurrency}${offer.university.tuition.universityTuition}`
          : `${offer.university.tuition.universityTuition}%`,
        initialDeposit: offer.university.tuition.isFixed
          ? `${offer.university.tuition?.tuitionCurrency}${offer.university.tuition.initialTuitionDeposit}`
          : `${offer.university.tuition.initialTuitionDeposit}%`,
        universityCommission: offer.university.commissionStructure.isFixed
          ? `${offer?.university?.commissionStructure?.commissionCurrency}${
              offer.university.commissionStructure.universityCommission ?? 0
            }`
          : `${offer.university.commissionStructure.universityCommission ?? 0}%`,
        recruiterCommission: offer.university.commissionStructure.recruitersCommission.isFixed
          ? `${offer?.university?.commissionStructure?.commissionCurrency}${offer.university.commissionStructure.recruitersCommission.commission}`
          : `${offer.university.commissionStructure.recruitersCommission.commission}%`,
        country: getCountry(offer.university.country),
        logo: "",
      }));
    }

    return [];
  }, [universityData]);

  const showPreloader = requestStatus.isPending || imageUploadStatus.isPending || createSpecialOfferStatus.isPending;

  return (
    <>
      {showPreloader && <Preloader />}
      <SpecialOfferUI
        tableBodyItems={preformatteduniversityData}
        handleImageAsyncUpload={handleImageAsyncUpload}
        handleCreateSpecialOffer={handleCreateSpecialOffer}
        serverUrl={serverUrl}
        serverError={serverError}
        clearServerUrl={clearServerUrl}
        count={count}
      />
    </>
  );
};

export { SpecialOffers };

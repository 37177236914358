import { SupportMessageType } from "api";
import { AdminSupportPageUI, SupportMessageModal, SupportMsgDeleteModal, SupportTableItem } from "components";
import { Pagination } from "components";
import { TableHeaderItemProps } from "components/table/components";
import { useApiRequest, useHorizontalTab } from "hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { deleteASupportMsgeStatServ, getAllSupportMessageServ } from "services";
import { HorizontalTabItemProps } from "types";

// Tab keys
export enum HeroSubTabKeys {
  Unattended = "Unattended",
  Pending = "Pending",
  Resolved = "Resolved",
}

// Admin Support Page
const AdminSupportPage: React.FC = () => {
  /**
   *  ===================================
   *  MODAL CRUD OPERATIONS
   * ===================================
   */
  // Show Message modal Status Update
  const [currentMessageInView, setCurrentMessageInView] = useState<SupportTableItem | null>(null);

  //Message delete props
  const { run: runMsgDelete, data: deleteRespData, requestStatus: deleteReqStatus } = useApiRequest({});
  const [currentMessageToDelete, setCurrentMessageToDelete] = useState<SupportTableItem | null>(null);
  const confirmMessageDelete = (item: SupportTableItem | null) => {
    runMsgDelete(deleteASupportMsgeStatServ({ messageId: item?.messageId }));
    setCurrentMessageToDelete(null);
  };

  // Close Modal Handler
  const handleCloseModal = () => {
    setCurrentMessageInView(null);
    fetchCurrentTabData();
  };

  /**
   *  ====================================
   *  HORIZONTAL TAB/PAGINATION STATEMENTS
   * =====================================
   */

  // Current page number for pagination component and data fetching
  const [currentPageNo, setCurrentPageNo] = useState(1);

  // Horzontal Tab
  const Items: HorizontalTabItemProps<typeof HeroSubTabKeys>[] = [
    { title: "Unattended", tabKey: HeroSubTabKeys.Unattended },
    { title: "Pending", tabKey: HeroSubTabKeys.Pending },
    { title: "Resolved", tabKey: HeroSubTabKeys.Resolved },
  ];

  // Horizontal Tab Variables
  const { currentTab, isActive, updateActiveTab } = useHorizontalTab<typeof HeroSubTabKeys>({ tabItems: Items });

  /**
   *  ====================================
   *  SUPPORT MESSAGES REQUEST OPERATIONS
   * =====================================
   */

  // API Request Variables
  const { run: runGetSupportMsgs, data: supportMsgsData, requestStatus } = useApiRequest({});

  // Function to fetch current tab data
  const fetchCurrentTabData = useCallback(() => {
    const currentTabTitle = currentTab.title.toLowerCase() as SupportMessageType;
    runGetSupportMsgs(getAllSupportMessageServ({ pageNo: currentPageNo, messageType: currentTabTitle }));
  }, [currentPageNo, currentTab.title, runGetSupportMsgs]);

  // Fetch all support messages
  useEffect(() => {
    fetchCurrentTabData();
  }, [fetchCurrentTabData]);

  // Effect to refetch messages after a delete
  useEffect(() => {
    if (deleteRespData?.data) {
      fetchCurrentTabData();
    }
  }, [deleteRespData?.data, fetchCurrentTabData]);

  // Formatted support messages
  const allFetchedMsgsData = useMemo(() => supportMsgsData?.data?.data, [supportMsgsData?.data?.data]);
  const preformattedSupportMsgs = useMemo<SupportTableItem[]>(() => {
    if (supportMsgsData?.status === 200) {
      const fetchedMessages = allFetchedMsgsData?.fetchedMessages;

      return fetchedMessages.map((message) => {
        const messageStatus = message?.status as SupportMessageType;

        return {
          messageTitle: message?.title,
          emailAddress: message?.email,
          messageId: message?._id,
          status: messageStatus,
          message: message?.message,
          dateCreated: new Date(message?.createdAt),
        };
      });
    }

    return [];
  }, [allFetchedMsgsData, supportMsgsData]);

  // Tab content
  const TabContent: { [key in HeroSubTabKeys]: SupportTableItem[] } = useMemo(
    () => ({
      [HeroSubTabKeys.Unattended]: preformattedSupportMsgs.filter(
        ({ status }) => status.toLowerCase() === "unattended",
      ),
      [HeroSubTabKeys.Pending]: preformattedSupportMsgs.filter(({ status }) => status.toLowerCase() === "pending"),
      [HeroSubTabKeys.Resolved]: preformattedSupportMsgs.filter(({ status }) => status.toLowerCase() === "resolved"),
    }),
    [preformattedSupportMsgs],
  );

  // Table preloader options
  const showPreloader = requestStatus.isPending || deleteReqStatus.isPending;

  /**
   *  ====================================
   *  SUPPORT MESSAGES TABLE VARIABLES
   * =====================================
   */

  // Table header titles
  const tableHeaderTitles: TableHeaderItemProps[] = useMemo(
    () => [
      {
        title: "Email",
      },
      {
        title: "Message Title",
      },
      {
        title: "Action",
      },
    ],
    [],
  );

  // Current tab data
  const currentTabKey = currentTab.tabKey;
  const CurrentTabData = TabContent[currentTabKey];

  /**
   *  ====================================
   *  UI PROPS COLLECTION
   * =====================================
   */

  // All props to be sent to Support UI
  const AdminSupportUIProps = {
    allFetchedMsgsData,
    Items,
    updateActiveTab,
    isActive,
    setCurrentMessageInView,
    setMessageToDelete: setCurrentMessageToDelete,
    showPreloader,
    tableHeaderTitles,
    CurrentTabData,
    currentTab,
  };

  return (
    <React.Fragment>
      <AdminSupportPageUI {...AdminSupportUIProps} />
      <Pagination pages={allFetchedMsgsData?.availablePages || 0} handlePage={(page) => setCurrentPageNo(page)} />

      {currentMessageInView && (
        <SupportMessageModal supportMessage={currentMessageInView} handleMessageUpdateCancel={handleCloseModal} />
      )}

      {currentMessageToDelete && (
        <SupportMsgDeleteModal
          supportMessage={currentMessageToDelete}
          handleMessageUpdateCancel={() => setCurrentMessageToDelete(null)}
          confirmDeleteHandler={confirmMessageDelete}
        />
      )}
    </React.Fragment>
  );
};

export { AdminSupportPage };

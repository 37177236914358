// Environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL;

/*
=================================
EXAMPLE UNI VERIFICATION URL
=================================
*/

/**
 * URL DESCRIPTION
 *
 * @returns url string
 *
 */

export const agentVerificationRequestURL = (pageNo) => `${API_URL}/admin/requests?pageNo=${pageNo}&noOfRequests=10`;

export const universityVerificationURL = (pageNo, agentID) => `${API_URL}/agent/universities/details/${agentID}?pageNo=${pageNo}&noOfUniversities=10`;

export const updateVerificationStatusURL = () => `${API_URL}/admin/universities/update`;
import { getErrorMessage } from "Helper/errorMessage";
import { CreateApplicationUI, Toast } from "components";
import Preloader from "components/Preloader";
import { useApiRequest } from "hooks";
import * as React from "react";
import {
  CreateApplicationData,
  createApplicationService,
  fetchAgentsUnpaginatedService,
  fetchCountryUnpaginatedService,
  fetchCoursesUnpaginatedService,
  fetchProspectsUnpaginatedService,
  fetchUnisUnpaginatedService,
} from "services";
import { optionType } from "types";

export interface AllUniData extends optionType {
  country: string;
}

export interface AllCourseData extends optionType {
  university: string;
  degree: string;
}

interface CreateApplicationProp {
  show: boolean;
  close: () => void;
  fetchApplications: () => void;
}

const CreateApplication: React.FC<CreateApplicationProp> = ({ show, close, fetchApplications }) => {
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });

  const {
    run: runAllAgents,
    data: allAgentsResponse,
    requestStatus: allAgentsStatus,
    error: allAgentsError,
  } = useApiRequest({});

  const {
    run: runAllUnis,
    data: allUnisResponse,
    requestStatus: allUnisStatus,
    error: allUnisError,
  } = useApiRequest({});

  const {
    run: runAllCourses,
    data: allCoursesResponse,
    requestStatus: allCoursesStatus,
    error: allCoursesError,
  } = useApiRequest({});

  const {
    run: runProspects,
    data: prospectsResponse,
    requestStatus: prospectsStatus,
    error: prospectsError,
  } = useApiRequest({});

  const {
    run: runCountries,
    data: countriesResponse,
    requestStatus: countriesStatus,
    error: countriesError,
  } = useApiRequest({});

  const {
    run: runCreateApp,
    data: createAppResponse,
    requestStatus: createAppStatus,
    error: createAppError,
  } = useApiRequest({});

  const sortAlphabetically = (list) => list.sort((a, b) => (a.label < b.label ? -1 : 1));

  const agentOptions = React.useMemo<optionType[]>(() => {
    if (allAgentsResponse && allAgentsResponse?.status === 200) {
      return allAgentsResponse?.data?.data?.map((item) => ({
        label: item.agencyName,
        value: item._id,
      }));
    }
    return [];
  }, [allAgentsResponse, allAgentsError]);

  const allUnis = React.useMemo<AllUniData[]>(() => {
    if (allUnisResponse && allUnisResponse?.status === 200) {

      const activeUnis = allUnisResponse?.data?.data?.filter((item) => item.isdisabled === false);

      return activeUnis?.map((item) => ({
        label: item.university,
        value: item._id,
        country: item?.country ?? "",
      }));
    }

    return [];
  }, [allUnisResponse, allUnisError]);

  const allCourses = React.useMemo<AllCourseData[]>(() => {
    if (allCoursesResponse && allCoursesResponse?.status === 200) {
      return allCoursesResponse?.data?.data?.map((item) => ({
        label: item.name,
        value: item._id,
        degree: item?.degree,
        university: item?.university,
      }));
    }

    return [];
  }, [allCoursesResponse, allCoursesError]);

  const prospectOptions = React.useMemo<optionType[]>(() => {
    if (prospectsResponse && prospectsResponse?.status === 200) {
      return prospectsResponse?.data?.data?.map((item) => ({
        value: item?._id ?? "",
        label: `${item?.firstName} ${item.middleName} ${item.lastName}`,
      }));
    }

    return [];
  }, [prospectsResponse, prospectsError]);

  const countryOptions = React.useMemo<optionType[]>(() => {
    if (countriesResponse && countriesResponse?.status === 200) {
      return countriesResponse?.data?.data?.map((item) => ({
        value: item?.countryCode ?? "",
        label: item?.countryName ?? "",
      }));
    }

    return [];
  }, [countriesResponse, countriesError]);

  React.useMemo(() => {
    if (createAppResponse) {
      if (createAppResponse.status === 200) {
        close();
        fetchApplications();
        console.log(createAppResponse);
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(createAppResponse, "Unable to create application"),
          type: false,
        });
      }
    }
    if (createAppError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(createAppError, "Unable to create application"),
        type: false,
      });
    }
  }, [createAppResponse, createAppError]);

  const fetchAllAgents = () => {
    runAllAgents(fetchAgentsUnpaginatedService());
  };

  const fetchAllUnis = () => {
    runAllUnis(fetchUnisUnpaginatedService());
  };

  const fetchAllCourses = () => {
    runAllCourses(fetchCoursesUnpaginatedService());
  };

  const fetchCountries = () => {
    runCountries(fetchCountryUnpaginatedService());
  };

  const fetchProspects = (id) => {
    runProspects(fetchProspectsUnpaginatedService({ id }));
  };

  const createApplication = (data: CreateApplicationData) => {
    runCreateApp(createApplicationService(data));
  };

  React.useEffect(() => {
    fetchAllAgents();
    fetchAllUnis();
    fetchAllCourses();
    fetchCountries();
  }, []);

  const showLoader = createAppStatus.isPending;

  return (
    <>
      {showLoader && <Preloader />}
      <Toast closeModal={() => setToast({ ...toast, show: false })} {...toast} />
      <CreateApplicationUI
        show={show}
        close={close}
        agentOptions={sortAlphabetically(agentOptions)}
        prospectOptions={prospectOptions}
        countryOptions={countryOptions}
        allUnis={allUnis}
        allCourses={allCourses}
        getProspectOptions={fetchProspects}
        submit={createApplication}
      />
    </>
  );
};

export { CreateApplication };

import * as React from "react";
import styles from "./styles.module.css";
import { AddIcon } from "assets";
import { optionType } from "types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { CustomSelect, objectComparision } from "components";
import { degreeOptions, getMonthOptions, initialOptionType, sourceOptions, yearOptions } from "Helper/options";
import { AllCourseData, AllUniData } from "pages/Applications/createApplication";
import { ApplicationDataOptional } from "services";

export interface ApplicationData {
  university: optionType;
  uniCountry: optionType;
  degree: optionType;
  preferredCourse: optionType;
  intakeYear: optionType;
  intakeMonth: optionType;
  agent: optionType;
  source: optionType;
  prospect: optionType;
}

const optionTypeSchemaReq = yup.object({
  label: yup.string().required("Required"),
  value: yup.string().required("Required"),
});

const applicationSchema = yup
  .object({
    prospect: optionTypeSchemaReq,
    university: optionTypeSchemaReq,
    uniCountry: optionTypeSchemaReq,
    degree: optionTypeSchemaReq,
    preferredCourse: optionTypeSchemaReq,
    intakeYear: optionTypeSchemaReq,
    intakeMonth: optionTypeSchemaReq,
    agent: optionTypeSchemaReq,
    source: optionTypeSchemaReq,
  })
  .required();

interface EditApplicationProp {
  show: boolean;
  close: () => void;
  agentOptions: optionType[];
  prospectOptions: optionType[];
  countryOptions: optionType[];
  allUnis: AllUniData[];
  getProspectOptions: (id) => void;
  allCourses: AllCourseData[];
  initialAppInfo: ApplicationData;
  submit: (data: ApplicationDataOptional) => void;
}

const EditApplicationUI: React.FC<EditApplicationProp> = ({
  show,
  close,
  agentOptions,
  prospectOptions,
  allUnis,
  countryOptions,
  getProspectOptions,
  allCourses,
  initialAppInfo,
  submit,
}) => {
  const [errorMessage, setErrorMessage] = React.useState("");

  const {
    handleSubmit: handleSubmit,
    setValue: setValue,
    formState: { errors: errors },
    watch: watch,
    reset: reset,
  } = useForm<ApplicationData>({
    resolver: yupResolver(applicationSchema),
    defaultValues: initialAppInfo,
  });

  React.useEffect(() => {
    reset(initialAppInfo);
  }, [initialAppInfo]);

  React.useEffect(() => {
    if (!show) closeForm();
  }, [show]);

  const getUniOptions = ({ country }: { country?: string }): optionType[] => {
    let unis: optionType[] = [];
    let newList = [...allUnis];

    if (country && country !== "") {
      newList = allUnis.filter((item) => item.country.toLowerCase() === country?.toLowerCase());
    }

    unis = newList.map((item) => ({
      value: item?.value ?? "",
      label: item?.label ?? "",
    }));

    return unis;
  };

  const getCourseOptions = ({ university, degree }: { university?: string; degree?: string }): optionType[] => {
    let courses: optionType[] = [];
    let newList = [...allCourses];

    if (degree && degree !== "" && university && university !== "") {
      newList = allCourses?.filter((item) => item.degree === degree && item.university === university);
    } else if (degree && degree !== "") {
      newList = allCourses?.filter((item) => item.degree === degree);
    } else if (university && university !== "") {
      newList = allCourses?.filter((item) => item.university === university);
    }

    courses = newList.map((item) => ({
      value: item?.value ?? "",
      label: item?.label ?? "",
    }));

    return courses;
  };

  const onSubmit: SubmitHandler<ApplicationData> = (data) => {
    console.log(data);
    const submitData: ApplicationDataOptional = {};

    if (!objectComparision(data.university, initialAppInfo.university)) submitData.university = data.university.value;
    if (!objectComparision(data.preferredCourse, initialAppInfo.preferredCourse))
      submitData.course = data.preferredCourse.value;
    if (!objectComparision(data.intakeMonth, initialAppInfo.intakeMonth))
      submitData.intakeMonth = data.intakeMonth.value;
    if (!objectComparision(data.intakeYear, initialAppInfo.intakeYear)) submitData.intakeYear = data.intakeYear.value;
    if (!objectComparision(data.source, initialAppInfo.source)) submitData.source = data.source.value;

    if (Object.keys(submitData).length === 0) {
      setErrorMessage("You haven't updated any data");
      return;
    }
    setErrorMessage("");

    submit(submitData);
  };

  const closeForm = () => {
    close();
    reset();
  };

  return (
    <aside className={`${styles.wrapper} ${show ? styles.show : styles.hide}`}>
      <AddIcon
        className={`${styles.closeBtn} ${show ? styles.showIcon : styles.hideIcon}`}
        role="button"
        onClick={closeForm}
      />
      <h1 className={styles.ttl}>Edit an Application</h1>
      <p className={styles.txt}>Complete the form below to edit an application</p>

      <form className={styles.form}>
        <CustomSelect
          onChange={(x) => {
            setValue("agent", x);
            getProspectOptions(x.value);
            setValue("prospect", initialOptionType);
          }}
          validatorMessage={
            !(watch("agent.label") && watch("agent.value"))
              ? errors.agent?.message ?? errors.agent?.label?.message ?? errors.agent?.value?.message ?? ""
              : ""
          }
          inputClass={styles.select}
          name={"agent"}
          placeholder={"Select..."}
          label={"Agent*"}
          options={agentOptions}
          value={watch("agent")}
          parentClassName={styles.inputWrap}
        />
        <CustomSelect
          onChange={(x) => setValue("prospect", x)}
          validatorMessage={
            !(watch("prospect.label") && watch("prospect.value"))
              ? errors.prospect?.message ?? errors.prospect?.label?.message ?? errors.prospect?.value?.message ?? ""
              : ""
          }
          inputClass={styles.select}
          name={"prospect"}
          placeholder={"Select..."}
          label={"Prospect*"}
          options={prospectOptions}
          value={watch("prospect")}
          parentClassName={styles.inputWrap}
        />
        <CustomSelect
          onChange={(x) => {
            setValue("uniCountry", x);
            setValue("university", initialOptionType);
            setValue("degree", initialOptionType);
            setValue("preferredCourse", initialOptionType);
          }}
          validatorMessage={
            !(watch("uniCountry.label") && watch("uniCountry.value"))
              ? errors.uniCountry?.message ??
                errors.uniCountry?.label?.message ??
                errors.uniCountry?.value?.message ??
                ""
              : ""
          }
          inputClass={styles.select}
          name={"uniCountry"}
          placeholder={"Select..."}
          label={"Country of study*"}
          options={countryOptions}
          value={watch("uniCountry")}
          parentClassName={styles.inputWrap}
        />
        <CustomSelect
          onChange={(x) => {
            setValue("university", x);

            setValue("degree", initialOptionType);
            setValue("preferredCourse", initialOptionType);
          }}
          validatorMessage={
            !(watch("university.label") && watch("university.value"))
              ? errors.university?.message ??
                errors.university?.label?.message ??
                errors.university?.value?.message ??
                ""
              : ""
          }
          inputClass={styles.select}
          name={"university"}
          placeholder={"Select..."}
          label={"University*"}
          options={getUniOptions({ country: watch("uniCountry").value })}
          value={watch("university")}
          parentClassName={styles.inputWrap}
        />

        <CustomSelect
          onChange={(x) => {
            setValue("degree", x);
            setValue("preferredCourse", initialOptionType);
          }}
          validatorMessage={
            !(watch("degree.label") && watch("degree.value"))
              ? errors.degree?.message ?? errors.degree?.label?.message ?? errors.degree?.value?.message ?? ""
              : ""
          }
          inputClass={styles.select}
          name={"degree"}
          placeholder={"Select..."}
          label={"Degree*"}
          options={degreeOptions}
          value={watch("degree")}
          parentClassName={styles.inputWrap}
        />

        <CustomSelect
          onChange={(x) => setValue("preferredCourse", x)}
          validatorMessage={
            !(watch("preferredCourse.label") && watch("preferredCourse.value"))
              ? errors.preferredCourse?.message ??
                errors.preferredCourse?.label?.message ??
                errors.preferredCourse?.value?.message ??
                ""
              : ""
          }
          inputClass={styles.select}
          name={"preferredCourse"}
          placeholder={"Select..."}
          label={"Preferred Course*"}
          options={getCourseOptions({
            university: watch("university.value"),
            degree: watch("degree.value").toLowerCase(),
          })}
          value={watch("preferredCourse")}
          parentClassName={styles.inputWrap}
        />

        <CustomSelect
          onChange={(x) => setValue("intakeYear", x)}
          validatorMessage={
            !(watch("intakeYear.label") && watch("intakeYear.value"))
              ? errors.intakeYear?.message ??
                errors.intakeYear?.label?.message ??
                errors.intakeYear?.value?.message ??
                ""
              : ""
          }
          inputClass={styles.select}
          name={"intakeYear"}
          placeholder={"Select..."}
          label={"Intake year*"}
          options={yearOptions}
          value={watch("intakeYear")}
          parentClassName={styles.inputWrap}
        />

        <CustomSelect
          onChange={(x) => setValue("intakeMonth", x)}
          validatorMessage={
            !(watch("intakeMonth.label") && watch("intakeMonth.value"))
              ? errors.intakeMonth?.message ??
                errors.intakeMonth?.label?.message ??
                errors.intakeMonth?.value?.message ??
                ""
              : ""
          }
          inputClass={styles.select}
          name={"intakeMonth"}
          placeholder={"Select..."}
          label={"Intake month*"}
          options={getMonthOptions(watch("intakeYear.value"))}
          value={watch("intakeMonth")}
          parentClassName={styles.inputWrap}
        />
        <CustomSelect
          onChange={(x) => setValue("source", x)}
          validatorMessage={
            !(watch("source.label") && watch("source.value"))
              ? errors.source?.message ?? errors.source?.label?.message ?? errors.source?.value?.message ?? ""
              : ""
          }
          inputClass={styles.select}
          name={"source"}
          placeholder={"Select..."}
          label={"Source*"}
          options={sourceOptions}
          value={watch("source")}
          parentClassName={styles.inputWrap}
        />
        <button className={styles.submitBtn} onClick={handleSubmit(onSubmit)}>
          Submit
        </button>
        {errorMessage ? <p className={styles.errorMessage}>{errorMessage}</p> : ""}
      </form>
    </aside>
  );
};

export { EditApplicationUI };

import React from "react";
import { TableRowItem } from "../../components";
import { AdminTableBody } from "../../components/tbody";
import { TableDataItem } from "../../components/td";
import styles from "./styles.module.css";

// University Verification Table Body Item
export interface SpecialOfferTableBodyItem {
  universityName: string;
  tuition: string;
  initialDeposit: string;
  universityCommission: string;
  recruiterCommission: string;
  country: string;
}

// University Verification Table Body Props
interface TableBodyProps {
  tableBodyItems: SpecialOfferTableBodyItem[];
  tableBodyClassName: string;
}

const SpecialOfferTableBody: React.FC<TableBodyProps> = ({ tableBodyItems, tableBodyClassName }) => {
  return (
    <AdminTableBody customClassName={`${styles.tableContainer} `}>
      {tableBodyItems.map((item, idx) => (
        <TableRowItem key={`${item.universityName} ${idx}`} customClassName={styles.tableRow}>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {item.universityName} <br /> {item.country}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.tuition}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {item.initialDeposit}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {item.universityCommission}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {item.recruiterCommission}
          </TableDataItem>
        </TableRowItem>
      ))}
    </AdminTableBody>
  );
};

export { SpecialOfferTableBody };

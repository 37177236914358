import {
  Toast,
  EditInstitutionUI,
  AgentUI,
  AgentData,
  ProspectsTableBodyItem,
  AgentProspectsTableBodyItem,
  ApplicationsTableBodyItem,
  EditAgentUI,
} from "components";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApiRequest } from "hooks";
import {
  EditAgentRequestData,
  editAgentProfileService,
  fetchAgentProfileService,
  fetchProspectsService,
  getApplicationsService,
  uploadService,
} from "services";
import Preloader from "components/Preloader";
import { getCountry, getNationality } from "Helper/country";
import { getErrorMessage } from "Helper/errorMessage";
import { Routes } from "routes/utils";
import { avatarImg } from "assets";

const initAgent: AgentData = {
  address: "",
  agencyName: "",
  avatar: "",
  repName: "",
  email: "",
  phone: "",
  website: "",
  teamSize: "",
  studentsPerMonth: "",
  stats: {
    prospects: 0,
    applications: 0,
    admissions: 0,
  },
  bankInfo: {
    holderName: "",
    bankName: "",
    iban: "",
    holderAddress: "",
    country: "",
  },
};

const Agent: React.FC = () => {
  const { id: agentID } = useParams();
  const navigate = useNavigate();

  const [search, setSearch] = React.useState({
    prospects: "",
    applications: "",
  });
  const [currentPage, setCurrentPage] = React.useState({
    prospects: 1,
    applications: 1,
  });
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });
  const [showEditAgent, setShowEditAgent] = React.useState(false);
  const [editAgentInfo, setEditAgentInfo] = React.useState<EditAgentRequestData>({});

  const { run: runAgent, data: agentData, requestStatus: agentStatus, error: agentError } = useApiRequest({});
  const {
    run: runListProspects,
    data: listProspectsResponse,
    requestStatus: listProspectsStatus,
    error: listProspectsError,
  } = useApiRequest({});
  const {
    run: runApplicationsFetch,
    data: applicationsData,
    requestStatus: applicationsStatus,
    error: applicationsError,
  } = useApiRequest({});
  const {
    run: runEditAgent,
    data: editAgentData,
    requestStatus: editAgentStatus,
    error: editAgentError,
  } = useApiRequest({});
  const {
    run: runUploadImage,
    data: imageUploadResponse,
    requestStatus: imageUploadStatus,
    error: imageUploadError,
  } = useApiRequest({});

  const fetchProspects = () =>
    runListProspects(
      fetchProspectsService({
        pageNo: `${currentPage.prospects}`,
        search: search.prospects,
        agent: agentID ?? "",
      }),
    );

  const fetchApplications = () => {
    runApplicationsFetch(
      getApplicationsService({
        pageNo: currentPage.applications,
        search: search.applications,
        agent: agentID ?? "",
      }),
    );
  };

  // fetch agent info
  React.useEffect(() => {
    runAgent(fetchAgentProfileService({ id: agentID }));
  }, [agentID]);

  React.useEffect(() => {
    fetchProspects();
  }, [currentPage.prospects, search.prospects]);

  React.useEffect(() => {
    fetchApplications();
  }, [currentPage.applications, search.applications]);

  const getValue = (data) => (data && data !== "" ? data : "-");

  const agent = React.useMemo<AgentData>(() => {
    if (agentData) {
      if (agentData.status === 200) {
        const data = agentData.data.data;
        return {
          address: getValue(data.address),
          agencyName: getValue(data.agencyName),
          avatar: data.avatar,
          repName: getValue(data.name),
          email: getValue(data.email),
          phone: getValue(data.phone),
          website: getValue(data.website),
          teamSize: getValue(data.teamSize),
          studentsPerMonth: getValue(data.numberOfProspects),
          stats: {
            prospects: data?.stats?.prospectCount ?? 0,
            applications: data?.stats?.applicationCount ?? 0,
            admissions: data?.stats?.admissionCount ?? 0,
          },
          bankInfo: {
            holderName: getValue(data.bankHolderName),
            bankName: getValue(data.bankName),
            iban: getValue(data.iban),
            holderAddress: getValue(data.holderAddress),
            country: getValue(getCountry(data.bankCountry)),
          },
        };
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(agentData, "Unable to fetch agent information"),
          type: false,
        });
      }
    }
    if (agentError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(agentError, "Unable to fetch agent information"),
        type: false,
      });
    }
    return initAgent;
  }, [agentData, agentError]);

  const getStatus = (stat) => stat.replaceAll("_", " ");
  const getPlan = (plan) => {
    let planString = "";
    if (plan === "free") planString = "Sapphire (free)";
    else if (plan === "gold") planString = "Gold ($65)";
    else if (plan === "ruby") planString = "Ruby ($95)";
    else if (plan === "enterprise") planString = "Platinum (enterprise)";
    else planString = plan;

    return planString;
  };

  const checkValue = (val) => val && val !== "";

  const prospectList = React.useMemo<AgentProspectsTableBodyItem[]>(() => {
    if (listProspectsResponse && listProspectsResponse?.status === 200) {
      const data = listProspectsResponse?.data?.data;

      return data?.fetchedData.map((item) => ({
        id: item._id,
        avatar: checkValue(item.suppDocs.passId.url) ? item.suppDocs.passId.url : avatarImg,
        name: `${item.firstName} ${item.lastName}`,
        plan: getPlan(item.paymentPlan),
        status: getStatus(item.status),
        nationality: getNationality(item.nationality),
        commentCount: item?.adminCommentInfo?.commentCount,
        dateCreated: new Date(item.createdAt).toLocaleDateString(),
      }));
    } else if (listProspectsError) {
      setToast({
        show: true,
        heading: "Sorry",
        text: getErrorMessage(listProspectsResponse, "Unable to fetch prospects"),
        type: false,
      });
    }

    return [];
  }, [listProspectsResponse, listProspectsError]);

  // Formatted list of applications agents
  const applicationsList = React.useMemo<ApplicationsTableBodyItem[]>(() => {
    if (applicationsData?.status === 200) {
      const fetchedApplications = applicationsData?.data?.data?.fetchedData;

      return fetchedApplications.map((application) => ({
        status: application?.receivingAgent?.status === "new" ? "sent" : application?.receivingAgent?.status,
        id: application?._id,
        unreadCommentCount: {
          student: application?.student?.commentCount,
          subAgent: application?.sendingAgent?.commentCount,
          superAgent: application?.receivingAgent?.commentCount,
        },
        intakeMonth: application?.intakeMonth,
        intakeYear: application?.intakeYear,
        dateCreated: new Date(application.createdAt).toLocaleDateString(),
        agent: { name: application?.agent_info.agencyName ?? "Deleted Agent", id: application?.agent_info._id },
        source: application?.source,
        university: {
          name: application.university_Info.university,
          id: application.university_Info._id,
          logo: application.university_Info.logo.path,
          country: { name: getCountry(application.university_Info.country), code: application.university_Info.country },
        },
        course: {
          name: application.course.name,
          id: application.course._id,
          degree: application.course.degree,
        },
        prospect: {
          name: `${application.prospect_Info.firstName} ${application.prospect_Info.lastName}`,
          id: application.prospect_Info._id,
          photo: application.prospect_Info.suppDocs.passId.url,
          nationality: getNationality(application?.prospect_Info.nationality),
        },
      }));
    }

    return [];
  }, [applicationsData, applicationsError]);

  const editAgent = (agent) => {
    const data: EditAgentRequestData = { ...agent };

    if (agent.avatar) {
      setEditAgentInfo(data);
      const formData = new FormData();
      formData.append("files", agent.avatar);
      runUploadImage(uploadService(formData));
    } else if (agentID) {
      runEditAgent(editAgentProfileService({ id: agentID, data }));
    }
  };

  React.useMemo(() => {
    if (imageUploadResponse) {
      if (imageUploadResponse?.status === 200) {
        const files = imageUploadResponse.data.data.map((item) => ({
          path: item.location,
          key: item.key,
        }));

        if (agentID)
          runEditAgent(editAgentProfileService({ id: agentID, data: { ...editAgentInfo, avatar: files[0].path } }));
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(imageUploadResponse, "Unable to upload image"),
          type: false,
        });
      }
    }
    if (imageUploadError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(imageUploadError, "Unable to upload image"),
        type: false,
      });
    }
  }, [imageUploadResponse, imageUploadError]);

  React.useMemo(() => {
    if (editAgentData) {
      if (editAgentData.status === 200) {
        setShowEditAgent(false);
        if (agentID) runAgent(fetchAgentProfileService({ id: agentID }));
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(editAgentData, "Unable to edit agency profile"),
          type: false,
        });
      }
    }

    if (editAgentError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(editAgentError, "Unable to edit agency profile"),
        type: false,
      });
    }
  }, [editAgentData, editAgentError]);

  const showPreloader =
    agentStatus.isPending || listProspectsStatus.isPending || applicationsStatus.isPending || editAgentStatus.isPending;

  return (
    <>
      {showPreloader && <Preloader />}
      <Toast closeModal={() => setToast({ ...toast, show: false })} {...toast} />
      <EditAgentUI
        show={showEditAgent}
        close={() => setShowEditAgent(false)}
        submit={editAgent}
        initialValues={{
          agencyName: agentData?.data?.data?.agencyName,
          repName: agentData?.data?.data?.name,
          avatar: agentData?.data?.data?.avatar,
          email: agentData?.data?.data?.email,
          phone: agentData?.data?.data?.phone,
          website: agentData?.data?.data?.website,
          address: agentData?.data?.data?.address,
          teamSize: { label: agentData?.data?.data?.teamSize, value: agentData?.data?.data?.teamSize },
          studentsPerMonth: {
            label: agentData?.data?.data?.numberOfProspects,
            value: agentData?.data?.data?.numberOfProspects,
          },
          bankHolderName: agentData?.data?.data?.bankHolderName,
          bankName: agentData?.data?.data?.bankName,
          iban: agentData?.data?.data?.iban,
          bankHolderAddress: agentData?.data?.data?.holderAddress,
          bankCountry: { label: agent.bankInfo.country, value: agentData?.data?.data?.bankCountry },
        }}
      />
      <AgentUI
        applicationList={applicationsList}
        prospectList={prospectList}
        handleSearchProspect={(x) => setSearch({ ...search, prospects: x })}
        handleSearchApplication={(x) => setSearch({ ...search, applications: x })}
        pageInfo={{
          applications: {
            count: applicationsData?.data?.data?.count ?? 0,
            totalPages: applicationsData?.data?.data?.availablePages ?? 0,
            currentPage: applicationsData?.data?.data?.pageNo ?? 1,
            updateCurrentPage: (x) => setCurrentPage({ ...currentPage, applications: x }),
          },
          prospects: {
            count: listProspectsResponse?.data?.data?.count ?? 0,
            totalPages: listProspectsResponse?.data?.data?.availablePages ?? 1,
            currentPage: listProspectsResponse?.data?.data?.pageNo ?? 1,
            updateCurrentPage: (x) => setCurrentPage({ ...currentPage, prospects: x }),
          },
        }}
        agent={agent}
        handleViewProspect={(id) => {
          window.open(Routes.prospectID(id), "_blank");
        }}
        editAgent={() => setShowEditAgent(true)}
        handleViewComments={(id) => {
          window.open(`${Routes.prospectID(id)}?comments=true`, "_blank");
        }}
        handleEditProspect={(id) => {
          navigate(Routes.editProspectID(id));
        }}
      />
    </>
  );
};

export { Agent };

import React from "react";

// Table Data Props
interface TableDataItemProps {
  className?: string;
}

/**
 * This component is a representation of the HTML's td element.
 */

const TableDataItem: React.FC<TableDataItemProps> = ({ children, className }) => {
  return <td className={className}>{children}</td>;
};

export { TableDataItem };

import React from "react";
import { AdminTableHeader } from "./components";
import { TableHeaderItemProps } from "./components/tableHeader";
import styles from "./styles.module.css";

// An interface for all custom table classes
interface AllCustomTableClasses {
  tableHeaderClassName?: string;
  tableHeaderItemClassName?: string;
  tableContainerClassName?: string;
  tableWrapperClass?: string;
}

// Admin Table Props
interface AdminTableProps {
  tableHeaderTitles: TableHeaderItemProps[];
  AdminTableBody: React.ReactElement;
  EmptyTableElement?: React.ReactElement;
  showEmptyTableElement?: boolean;
  customContainerStyles?: React.CSSProperties;
  customTableClasses?: AllCustomTableClasses;
  hideHeaders?: boolean;
}

/**
 * ADMIN DASHBOARD TABLE COMPONENT
 * --------------------------------------------
 * This is the admin table component.
 */

const AdminDashBoardTable: React.FC<AdminTableProps> = ({
  tableHeaderTitles,
  AdminTableBody,
  customContainerStyles,
  customTableClasses,
  showEmptyTableElement = false,
  EmptyTableElement,
  hideHeaders = false,
}) => {
  // All custom table classes
  const {
    tableHeaderClassName = "",
    tableHeaderItemClassName = "",
    tableContainerClassName = "",
    tableWrapperClass = "",
  } = customTableClasses || {};

  return (
    <section className={tableWrapperClass}>
      <table className={`${styles.mainTableContainer} ${tableContainerClassName}`} style={{ ...customContainerStyles }}>
        {!hideHeaders && (
          <AdminTableHeader
            className={tableHeaderClassName}
            tableHeadItemClassName={tableHeaderItemClassName}
            tableHeaderTitles={tableHeaderTitles}
          />
        )}
        {!showEmptyTableElement && AdminTableBody}
      </table>
      {showEmptyTableElement && EmptyTableElement}
    </section>
  );
};

export { AdminDashBoardTable };
export type { AllCustomTableClasses };

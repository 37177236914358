import {
  Pagination,
  InstitutionUI,
  CoursesTableBodyItem,
  InstitutionAgentsTableBodyItem,
  AddCourseUI,
  AddAgentUI,
  ViewCourseModal,
  InstitutionData,
  CourseData,
  Toast,
  EditInstitutionUI,
  EditCourseUI,
} from "components";
import React from "react";
import pic from "assets/vectors/profileDark.svg";
import { useParams } from "react-router-dom";
import { useApiRequest } from "hooks";
import {
  addAgentsToUniService,
  createCourseData,
  createCourseService,
  editCourseService,
  editInstitutionService,
  editUniData,
  fetchCourseService,
  fetchInstitutionAgentsService,
  fetchInstitutionCoursesService,
  fetchInstitutionService,
  fetchNonAgentsService,
  updateStatusAgentService,
  updateStatusCourseService,
  updateStatusInstitutionService,
  uploadService,
} from "services";
import Preloader from "components/Preloader";
import { getCountry, getFlag, getNationality } from "Helper/country";
import { getErrorMessage } from "Helper/errorMessage";
import { attendanceOptions, currencyOptions, degreeOptions, initialOptionType } from "Helper/options";
import { optionType } from "types";

const Institution: React.FC = () => {
  const { id: uniID } = useParams();
  const [showAddCourse, setShowAddCourse] = React.useState<boolean>(false);
  const [showCourse, setShowCourse] = React.useState<boolean>(false);
  const [showEditUni, setShowEditUni] = React.useState<boolean>(false);
  const [showEditCourse, setShowEditCourse] = React.useState<boolean>(false);
  const [showAddAgent, setShowAddAgent] = React.useState<boolean>(false);
  const [uniAppStatus, setUniAppStatus] = React.useState<boolean>(false);
  const [courseAppStatus, setCourseAppStatus] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const [degreeFilter, setDegreeFilter] = React.useState<string>("All");
  const [attendanceFilter, setAttendanceFilter] = React.useState<string>("All");
  const [currentPage, setCurrentPage] = React.useState({
    agents: 1,
    courses: 1,
  });
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });
  const [editUniInfo, setEditUniInfo] = React.useState<editUniData>({});

  const { run: runUni, data: uniData, requestStatus: uniStatus, error: uniError } = useApiRequest({});
  const {
    run: runUniAgents,
    data: uniAgentsData,
    requestStatus: uniAgentsStatus,
    error: uniAgentsError,
  } = useApiRequest({});

  const {
    run: runUniCourses,
    data: uniCoursesData,
    requestStatus: uniCoursesStatus,
    error: uniCoursesError,
  } = useApiRequest({});

  const { run: runCourse, data: courseData, requestStatus: courseStatus, error: courseError } = useApiRequest({});
  const {
    run: runCourseUpdateStatus,
    data: courseUpdateDataStatus,
    requestStatus: courseUpdateStatusStatus,
    error: courseUpdateStatusError,
  } = useApiRequest({});
  const {
    run: runUniUpdateStatus,
    data: uniUpdateStatusData,
    requestStatus: uniUpdateStatusStatus,
    error: uniUpdateStatusError,
  } = useApiRequest({});
  const {
    run: runCreateCourse,
    data: createCourseData,
    requestStatus: createCourseStatus,
    error: createCourseError,
  } = useApiRequest({});
  const {
    run: runAgentUpdateStatus,
    data: agentUpdateStatusData,
    requestStatus: agentUpdateStatusStatus,
    error: agentUpdateStatusError,
  } = useApiRequest({});
  const {
    run: runNonAgents,
    data: nonAgentsData,
    requestStatus: nonAgentsStatus,
    error: nonAgentsError,
  } = useApiRequest({});
  const {
    run: runAddAgents,
    data: addAgentsData,
    requestStatus: addAgentsStatus,
    error: addAgentsError,
  } = useApiRequest({});
  const {
    run: runEditCourse,
    data: editCourseData,
    requestStatus: editCourseStatus,
    error: editCourseError,
  } = useApiRequest({});
  const { run: runEditUni, data: editUniData, requestStatus: editUniStatus, error: editUniError } = useApiRequest({});
  const {
    run: runUploadImage,
    data: imageUploadResponse,
    requestStatus: imageUploadStatus,
    error: imageUploadError,
  } = useApiRequest({});

  // fetch uni info
  React.useEffect(() => {
    if (uniID) runUni(fetchInstitutionService(uniID));
  }, [uniID]);

  // fetch uni agents
  React.useEffect(() => {
    if (uniID) {
      runUniAgents(fetchInstitutionAgentsService({ id: uniID, pageNo: `${currentPage.agents}`, noOfRequests: `10` }));
    }
  }, [uniID, currentPage.agents]);

  // fetch uni courses
  React.useEffect(() => {
    if (uniID)
      runUniCourses(
        fetchInstitutionCoursesService({
          id: uniID,
          pageNo: `${currentPage.courses}`,
          noOfRequests: `10`,
          search,
          degree: degreeFilter !== "All" ? degreeFilter : "",
          attendance: attendanceFilter !== "All" ? attendanceFilter : "",
        }),
      );
  }, [uniID, currentPage.courses, search, degreeFilter, attendanceFilter]);

  const institution = React.useMemo<InstitutionData>(() => {
    if (uniData) {
      if (uniData.status === 200) {
        const data = uniData.data.data;
        setUniAppStatus(!data.isdisabled);
        return {
          name: data.university,
          logo: data.logo.path,
          website: data.website,
          city: data.city,
          isLanguageSchool: data.isLanguageSchool,
          isEuropean: data.isEuropeanSchool,
          institutionType: data.institutionType,
          id: data._id,
          commissionPercentage: data.commissionPercentage,
          country: getCountry(data.country),
          flag: getFlag(data.country),
          nationalities: data.acceptingNationality?.map((item) => getNationality(item)) ?? [],
        };
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(uniData, "Unable to fetch institution information"),
          type: false,
        });
      }
    }
    if (uniError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(uniError, "Unable to fetch institution information"),
        type: false,
      });
    }
    return {
      name: "",
      logo: "",
      website: "",
      country: "",
      city: "",
      id: "",
      commissionPercentage: 0,
      flag: "",
      nationalities: [],
      isLanguageSchool: false,
      isEuropean: false,
      institutionType: "public",
    };
  }, [uniData, uniError]);

  const agentList = React.useMemo<InstitutionAgentsTableBodyItem[]>(() => {
    if (uniAgentsData) {
      if (uniAgentsData.status === 200) {
        const data = uniAgentsData.data.data;
        setCurrentPage({
          ...currentPage,
          courses: data.pageNo,
        });

        return data.fetchedRequest.map((item) => ({
          name: item.agent.name,
          avatar: item.agent.avatar === "" ? pic : item.agent.avatar,
          agency: item.agent.agencyName,
          email: item.agent.email,
          address: item.agent.address,
          id: item.agent._id,
          status: !item.isDisabled,
        }));
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(uniAgentsData, "Unable to fetch agents under this institution"),
          type: false,
        });
      }
    }
    if (uniAgentsError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(uniAgentsError, "Unable to fetch agents under this institution"),
        type: false,
      });
    }
    return [];
  }, [uniAgentsData, uniAgentsError]);

  const courseList = React.useMemo<CoursesTableBodyItem[]>(() => {
    if (uniCoursesData) {
      if (uniCoursesData.status === 200) {
        const data = uniCoursesData.data.data;
        setCurrentPage({
          ...currentPage,
          courses: data?.pageNo,
        });

        return data.fetchedData.map((item) => ({
          name: item.name,
          degree: item.degree,
          duration: item.duration,
          id: item._id,
        }));
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(uniCoursesData, "Unable to fetch courses under this institution"),
          type: false,
        });
      }
    }

    if (uniCoursesError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(uniCoursesError, "Unable to fetch courses under this institution"),
        type: false,
      });
    }
    return [];
  }, [uniCoursesData, uniCoursesError]);

  const course = React.useMemo<CourseData>(() => {
    if (courseData) {
      if (courseData.status === 200) {
        const data = courseData.data.data;
        setShowCourse(true);
        setCourseAppStatus(!data.isDisabled);

        return {
          title: data.name,
          description: data.description,
          degree: data.degree,
          duration: data.duration,
          yearlyTuition: `${data.yearlyTuition.currency} ${data.yearlyTuition.amount}`,
          initialDeposit: `${data.initialDeposit.currency} ${data.initialDeposit.amount}`,
          applicationFee: data.applicationFee.amount
            ? `${data.applicationFee.currency} ${data.applicationFee.amount}`
            : "---",
          commission: `${data.commission.currency} ${data.commission.amount}`,
          attendance: data.attendance === "on-campus" ? "On Campus" : "Off Campus",
        };
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(courseData, "Unable to fetch course"),
          type: false,
        });
      }
    }

    if (courseError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(courseError, "Unable to fetch course"),
        type: false,
      });
    }
    return {
      title: "",
      description: "",
      degree: "",
      duration: "",
      yearlyTuition: "",
      initialDeposit: "",
      applicationFee: "",
      commission: "",
      attendance: "",
    };
  }, [courseData, courseError]);

  const viewCourse = (id) => {
    runCourse(fetchCourseService(id));
  };

  React.useMemo(() => {
    if (courseUpdateDataStatus) {
      if (courseUpdateDataStatus.status === 200) {
        viewCourse(courseData.data.data._id);
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(courseUpdateDataStatus, "Unable to update course status"),
          type: false,
        });
      }
    }

    if (courseUpdateStatusError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(courseUpdateStatusError, "Unable to update course status"),
        type: false,
      });
    }
  }, [courseUpdateDataStatus, courseUpdateStatusError]);

  React.useMemo(() => {
    if (uniUpdateStatusData) {
      if (uniUpdateStatusData.status === 200) {
        if (uniID) runUni(fetchInstitutionService(uniID));
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(uniUpdateStatusData, "Unable to update university status"),
          type: false,
        });
      }
    }

    if (uniUpdateStatusError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(uniUpdateStatusError, "Unable to update university status"),
        type: false,
      });
    }
  }, [uniUpdateStatusData, uniUpdateStatusError]);

  const updateCourse = (x) => {
    runCourseUpdateStatus(
      updateStatusCourseService({ id: courseData.data.data._id, data: { status: x ? "enable" : "disable" } }),
    );
  };

  const updateUniStatus = (x) => {
    runUniUpdateStatus(updateStatusInstitutionService({ id: uniData.data.data._id, data: { status: x } }));
  };

  const createCourse = (data: createCourseData) => {
    if (uniID) runCreateCourse(createCourseService({ id: uniID, data }));
  };

  React.useMemo(() => {
    if (createCourseData) {
      if (createCourseData.status === 200) {
        setShowAddCourse(false);
        if (uniID)
          runUniCourses(
            fetchInstitutionCoursesService({
              id: uniID,
              pageNo: "1",
              noOfRequests: `10`,
            }),
          );
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(createCourseData, "Unable to create course"),
          type: false,
        });
      }
    }

    if (createCourseError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(createCourseError, "Unable to create course"),
        type: false,
      });
    }
  }, [createCourseData, createCourseStatus, createCourseError]);

  const updateAgent = (id, status) => {
    runAgentUpdateStatus(
      updateStatusAgentService({ id: uniID ?? "", data: { status: status ? "enable" : "disable", agentId: id } }),
    );
  };

  React.useMemo(() => {
    if (agentUpdateStatusData) {
      if (agentUpdateStatusData.status === 200) {
        if (uniID)
          runUniAgents(
            fetchInstitutionAgentsService({ id: uniID, pageNo: `${currentPage.agents}`, noOfRequests: `10` }),
          );
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(agentUpdateStatusData, "Unable to update agent status"),
          type: false,
        });
      }
    }

    if (agentUpdateStatusError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(agentUpdateStatusError, "Unable to update agent status"),
        type: false,
      });
    }
  }, [agentUpdateStatusData, agentUpdateStatusStatus, agentUpdateStatusError]);

  const nonAgentsList = React.useMemo<optionType[]>(() => {
    if (nonAgentsData) {
      if (nonAgentsData.status === 200) {
        const data = nonAgentsData.data.data;

        return data.map((item) => ({
          label: item.agencyName,
          value: item._id,
        }));
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(nonAgentsData, "Unable to fetch agents under this institution"),
          type: false,
        });
      }
    }
    if (nonAgentsError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(nonAgentsError, "Unable to fetch agents under this institution"),
        type: false,
      });
    }
    return [];
  }, [nonAgentsData, nonAgentsError]);

  const addAgent = (x) => {
    if (uniID) runAddAgents(addAgentsToUniService({ id: uniID, data: x }));
  };

  React.useMemo(() => {
    if (addAgentsData) {
      if (addAgentsData.status === 200) {
        setShowAddAgent(false);
        setToast({
          show: true,
          heading: "Great!",
          text: "You've successfully added agent(s) to this university",
          type: true,
        });
        if (uniID)
          runUniAgents(
            fetchInstitutionAgentsService({
              id: uniID,
              pageNo: "1",
              noOfRequests: `10`,
            }),
          );
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(addAgentsData, "Unable to add agent(s)"),
          type: false,
        });
      }
    }

    if (addAgentsError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(addAgentsError, "Unable to add agent(s)"),
        type: false,
      });
    }
  }, [addAgentsData, addAgentsError]);

  const editCourse = (x) => {
    runEditCourse(editCourseService({ id: courseData.data.data._id, data: x }));
  };

  React.useMemo(() => {
    if (editCourseData) {
      if (editCourseData.status === 200) {
        setShowEditCourse(false);
        if (uniID)
          runUniCourses(
            fetchInstitutionCoursesService({
              id: uniID,
              pageNo: "1",
              noOfRequests: `10`,
            }),
          );
        viewCourse(courseData.data.data._id);
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(editCourseData, "Unable to edit course"),
          type: false,
        });
      }
    }

    if (editCourseError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(editCourseError, "Unable to edit course"),
        type: false,
      });
    }
  }, [editCourseData, editCourseError]);

  const editUni = (x) => {
    const data: editUniData = {};
    if (x.website) data.website = x.website;
    if (x.commissionPercentage >= 0) data.commissionPercentage = x.commissionPercentage;
    if (x.city) data.city = x.city;
    if (x.country) data.country = x.country;
    if (x.name) data.university = x.name;
    if (x.institutionType) data.institutionType = x.institutionType;
    if (x.isLanguageSchool !== undefined) data.isLanguageSchool = x.isLanguageSchool;
    if (x.isEuropean !== undefined) data.isEuropeanSchool = x.isEuropean;
    if (x.nationalities?.length > 0) data.acceptingNationality = x.nationalities;

    if (x.logo) {
      setEditUniInfo(data);
      const formData = new FormData();
      formData.append("files", x.logo);
      runUploadImage(uploadService(formData));
    } else if (uniID)
      runEditUni(
        editInstitutionService({
          id: uniID,
          data,
        }),
      );
  };

  React.useMemo(() => {
    if (imageUploadResponse) {
      if (imageUploadResponse?.status === 200) {
        const files = imageUploadResponse.data.data.map((item) => ({
          path: item.location,
          key: item.key,
        }));
        if (uniID) runEditUni(editInstitutionService({ id: uniID, data: { ...editUniInfo, logo: files[0] } }));
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(imageUploadResponse, "Unable to upload image"),
          type: false,
        });
      }
    }
    if (imageUploadError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(imageUploadError, "Unable to upload image"),
        type: false,
      });
    }
  }, [imageUploadResponse, imageUploadError]);

  React.useMemo(() => {
    if (editUniData) {
      if (editUniData.status === 200) {
        setShowEditUni(false);
        if (uniID) runUni(fetchInstitutionService(uniID));
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(editUniData, "Unable to edit university"),
          type: false,
        });
      }
    }

    if (editUniError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(editUniError, "Unable to edit university"),
        type: false,
      });
    }
  }, [editUniData, editUniError]);

  const openAddAgents = () => {
    runNonAgents(fetchNonAgentsService(uniID));
    setShowAddAgent(!showAddAgent);
  };

  const showPreloader =
    uniStatus.isPending ||
    uniAgentsStatus.isPending ||
    uniCoursesStatus.isPending ||
    courseStatus.isPending ||
    courseUpdateStatusStatus.isPending ||
    uniUpdateStatusStatus.isPending ||
    createCourseStatus.isPending ||
    agentUpdateStatusStatus.isPending ||
    nonAgentsStatus.isPending ||
    editCourseStatus.isPending ||
    addAgentsStatus.isPending ||
    editUniStatus.isPending ||
    imageUploadStatus.isPending;

  return (
    <>
      {showPreloader && <Preloader />}
      <Toast closeModal={() => setToast({ ...toast, show: false })} {...toast} />
      {showEditUni && (
        <EditInstitutionUI
          show={showEditUni}
          close={() => setShowEditUni(false)}
          submit={editUni}
          initialValues={{
            name: uniData?.data?.data?.university ?? "",
            nationalities: uniData?.data?.data?.acceptingNationality.map((item) => ({
              label: getNationality(item),
              value: item,
            })),
            website: uniData?.data?.data?.website,
            logo: uniData?.data?.data?.logo?.path,
            commissionPercentage: uniData?.data?.data?.commissionPercentage,
            city: uniData?.data?.data?.city,
            country: { label: getCountry(uniData?.data?.data?.country), value: uniData?.data?.data?.country },
            isLanguageSchool: uniData?.data?.data?.isLanguageSchool,
            institutionType: {
              label:  uniData?.data?.data?.institutionType,
              value:  uniData?.data?.data?.institutionType
            },
            isEuropean: uniData?.data?.data?.isEuropeanSchool,
          }}
        />
      )}
      <ViewCourseModal
        show={showCourse}
        closeModal={() => setShowCourse(false)}
        course={course}
        status={{ value: courseAppStatus, control: () => updateCourse(!courseAppStatus) }}
        edit={() => {
          setShowCourse(false);
          setShowEditCourse(true);
        }}
      />
      {showAddCourse && (
        <AddCourseUI show={showAddCourse} close={() => setShowAddCourse(false)} submit={createCourse} />
      )}
      {showEditCourse && (
        <EditCourseUI
          show={showEditCourse}
          close={() => setShowEditCourse(false)}
          submit={editCourse}
          initialValues={{
            name: courseData?.data?.data?.name,
            degree:
              degreeOptions.find((item) => item.value.toLowerCase() === courseData?.data?.data.degree.toLowerCase()) ??
              initialOptionType,
            duration: courseData?.data?.data.duration.split(" ")[0],
            yearlyTuition: {
              currency:
                currencyOptions.find((item) => item.value === courseData?.data?.data.yearlyTuition.currency) ??
                initialOptionType,
              amount: courseData?.data?.data.yearlyTuition.amount,
            },
            initialDeposit: {
              currency:
                currencyOptions.find((item) => item.value === courseData?.data?.data.initialDeposit.currency) ??
                initialOptionType,
              amount: courseData?.data?.data.initialDeposit.amount,
            },
            commission: {
              currency:
                currencyOptions.find((item) => item.value === courseData?.data?.data.commission.currency) ??
                initialOptionType,
              amount: courseData?.data?.data.commission.amount,
            },
            applicationFee: {
              currency:
                currencyOptions.find((item) => item.value === courseData?.data?.data.applicationFee.currency) ??
                initialOptionType,
              amount: courseData?.data?.data.applicationFee.amount,
            },
            attendance:
              attendanceOptions.find((item) => item.value === courseData?.data?.data.attendance) ?? initialOptionType,
            description: courseData?.data?.data?.description,
          }}
        />
      )}
      {showAddAgent && (
        <AddAgentUI
          show={showAddAgent}
          close={() => setShowAddAgent(false)}
          submit={addAgent}
          agentList={nonAgentsList}
        />
      )}
      <InstitutionUI
        courseList={courseList}
        agentsList={agentList}
        handleViewCourse={viewCourse}
        addCourse={() => setShowAddCourse(!showAddCourse)}
        addAgent={openAddAgents}
        handleSearch={(x) => setSearch(x)}
        institution={institution}
        status={{ value: uniAppStatus, control: () => updateUniStatus(uniAppStatus) }}
        degreeFilter={{ value: degreeFilter, control: (x) => setDegreeFilter(x.title) }}
        attendanceFilter={{
          value: attendanceFilter,
          control: (x) =>
            setAttendanceFilter(x.title === "Onsite" ? "on-campus" : x.title === "Online" ? "off-campus" : "All"),
        }}
        pageInfo={{
          agents: {
            count: uniAgentsData?.data?.data?.count ?? 0,
            totalPages: uniAgentsData?.data?.data?.availablePages ?? 0,
            currentPage: uniAgentsData?.data?.data?.pageNo ?? 1,
            updateCurrentPage: (x) => setCurrentPage({ ...currentPage, agents: x }),
          },
          courses: {
            count: uniCoursesData?.data?.data?.count ?? 0,
            totalPages: uniCoursesData?.data?.data?.availablePages ?? 0,
            currentPage: uniCoursesData?.data?.data?.pageNo ?? 1,
            updateCurrentPage: (x) => setCurrentPage({ ...currentPage, courses: x }),
          },
        }}
        editUni={() => setShowEditUni(true)}
        updateAgentStatus={updateAgent}
      />
    </>
  );
};

export { Institution };

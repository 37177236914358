import { appendParams } from "Helper/appendParams";

// Environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL;

/*
=================================
UNVERIFIED PAGE URLs
=================================
*/

/**
 * get all unverified agents
 *
 * @returns url string
 *
 */

export const getUnVerifiedAgentsURL = ({pageNo, source, search}) =>
  `${API_URL}/admin/agents/unverified?${appendParams({
    pageNo,
    noOfRequests: 10,
    source,
    search,
  })}`;

/**
 * verify agent
 *
 * @returns url string
 *
 */

export const verifyAgentURL = () => `${API_URL}/auth/resend-mail`;

/**
 * delete agent
 *
 * @returns url string
 *
 */

export const deleteAgentURL = ({ agentId }: { agentId: string }) => `${API_URL}/admin/delete/agent/${agentId}`;

import { SupportMessageType } from "api";
import React from "react";
import { TableRowItem } from "../../components";
import { AdminTableBody } from "../../components/tbody";
import { TableDataItem } from "../../components/td";
import styles from "./styles.module.css";

// University Verification Table Body Item
export interface SupportTableItem {
  emailAddress: string;
  messageTitle: string;
  messageId: string;
  status: SupportMessageType;
  message: string;
  dateCreated: Date;
}

// University Verification Table Body Props
interface TableBodyProps {
  tableBodyItems: SupportTableItem[];
  handleViewMessage: (message: SupportTableItem) => void;
  handleMessageDelete: (message: SupportTableItem) => void;
  currentTabTitle: SupportMessageType;
}

const SupportTableBody: React.FC<TableBodyProps> = ({
  tableBodyItems,
  handleViewMessage,
  currentTabTitle,
  handleMessageDelete,
}) => {
  // Check for unattended tab
  const isUnattendedTab = currentTabTitle.toLowerCase() === "unattended";

  return (
    <AdminTableBody customClassName={styles.tableContainer}>
      {tableBodyItems.map((item, idx) => (
        <TableRowItem key={`${item.messageTitle} ${idx}`} customClassName={styles.tableRow}>
          <TableDataItem className={styles.tableRowItem}>{item.emailAddress}</TableDataItem>
          <TableDataItem className={styles.tableRowItem}>
            {item.messageTitle.length > 40 ? `${item.messageTitle.slice(0, 40)}...` : item.messageTitle}
          </TableDataItem>
          <TableDataItem className={styles.tableRowItem}>
            <button className={styles.deleteCTA} style={{ marginLeft: "0px" }} onClick={() => handleViewMessage(item)}>
              View/Update
            </button>

            {isUnattendedTab ? (
              <button className={styles.deleteCTA} style={{ marginLeft: "16px" }}>
                <a href={`mailto:${item.emailAddress}`}>Reply Message</a>
              </button>
            ) : (
              <button
                className={styles.deleteCTA}
                style={{ marginLeft: isUnattendedTab ? "0px" : "16px" }}
                onClick={() => handleMessageDelete(item)}
              >
                Delete Message
              </button>
            )}
          </TableDataItem>
        </TableRowItem>
      ))}
    </AdminTableBody>
  );
};

export { SupportTableBody };

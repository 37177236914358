import * as React from "react";
import styles from "./styles.module.css";
import { PageHeader } from "components/PageHeader";
import {
  AccountApprovalAgentChart,
  ApplicationStatusChart,
  CommissionChart,
  EmailVerificationAgentChart,
  InstitutionChart,
  OverTimeChart,
  ProspectPlanChart,
  ProspectStatusChart,
  SupportChart,
} from "./charts";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { Input } from "components";

interface PeriodData {
  from: string;
  to: string;
}

const schema = yup
  .object({
    from: yup.string().required("Required"),
    to: yup.string().required("Required"),
  })
  .required();

const initialValues: PeriodData = {
  from: new Date().toDateString(),
  to: new Date().toDateString(),
};

const OverviewUI = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch: watch,
    setValue,
    reset,
  } = useForm<PeriodData>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  return (
    <main>
      <PageHeader title="Welcome Admin" description="Here's an overview of your organization's performance" />
      <section className={styles.statContainer}>
        <section className={styles.statWrap1}>
          <div className={styles.statBody}>
            <div className={styles.heading}>
              <p className={styles.ttl}>Agents</p>
              <p className={styles.count}>227</p>
            </div>
            <div className={styles.doubleCharts}>
              <div>
                <p>Email Verification</p>
                <EmailVerificationAgentChart data={[10, 15]} />
              </div>
              <div>
                <p>Account Approval</p>
                <AccountApprovalAgentChart data={[10, 15, 3]} />
              </div>
            </div>
          </div>
          <div className={styles.statBody}>
            <div className={styles.heading}>
              <p className={styles.ttl}>Prospects</p>
              <p className={styles.count}>600</p>
            </div>
            <div className={styles.doubleCharts}>
              <div>
                <p>Prospect Plan</p>
                <ProspectPlanChart data={[10, 15, 4, 17]} />
              </div>
              <div>
                <p>Prospect Status</p>
                <ProspectStatusChart data={[10, 15, 19, 21]} />
              </div>
            </div>
          </div>
          <div className={styles.statBody}>
            <div className={styles.heading}>
              <p className={styles.ttl}>Applications</p>
              <p className={styles.count}>600</p>
            </div>
            <ApplicationStatusChart data={[10, 15, 19, 21, 4, 10, 17, 16, 25]} />
          </div>
        </section>
        <section className={styles.statWrap2}>
          <div className={styles.statCount}>
            <div className={`${styles.courses} ${styles.actionNeeded} ${styles.statBody}`}>
              <p className={styles.ttl}>Action Needed</p>
              <p className={styles.count}>45</p>
            </div>
            <div className={`${styles.courses} ${styles.statBody}`}>
              <p className={styles.ttl}>Courses</p>
              <p className={styles.count}>45000</p>
            </div>
          </div>

          <div className={styles.statBody}>
            <div className={styles.heading}>
              <p className={styles.ttl}>Institutions</p>
              <p className={styles.count}>450</p>
            </div>
            <InstitutionChart data={[65, 35, 10, 15, 19, 21]} />
          </div>
          <div className={styles.statBody}>
            <div className={styles.heading}>
              <p className={styles.ttl}>Commissions</p>
              <p className={styles.count}>56</p>
            </div>
            <CommissionChart data={[65, 35]} />
          </div>
          <div className={styles.statBody}>
            <p className={styles.ttl}>Support Tickets</p>
            <SupportChart data={[10, 15, 3]} />
          </div>
        </section>
        <section className={`${styles.overTimeSec} ${styles.statBody}`}>
          <div className={styles.period}>
            <Input
              label="From"
              placeholder=""
              type="date"
              parentClassName={styles.inputWrap}
              required
              validatorMessage={errors.from?.message}
              name="from"
              register={register}
            />
            <Input
              label="To"
              placeholder=""
              type="date"
              parentClassName={styles.inputWrap}
              required
              validatorMessage={errors.to?.message}
              name="to"
              register={register}
            />
            <button>Apply</button>
          </div>
          <OverTimeChart
            data={{
              allAgents: [12, 38, 3, 12, 38, 3, 20],
              verifiedAgents: [2, 28, 13, 11, 8, 31, 27],
              signupRequests: [1, 2, 3, 4, 5, 6, 7],
              approvedSignups: [7, 8, 9, 11, 22, 12, 9],
              students: [7, 12, 11, 5, 25, 17, 7],
              applications: [4, 7, 9, 2, 5, 1, 10],
              admissions: [5, 9, 12, 15, 18, 11, 33],
              cancellation: [4, 7, 9, 1, 3, 10, 11],
              acceptance: [8, 4, 5, 12, 19, 28, 16],
              enrollment: [12, 3, 6, 8, 9, 12, 15],
            }}
            labels={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
          />
        </section>
      </section>
    </main>
  );
};

export { OverviewUI };

import React, { useState } from "react";
import { countries } from "country-flags-svg";
import { CameraIcon, TickIcon, WarningIcon } from "assets/vectors";
import { AdminDashBoardTable, AllCustomTableClasses, PageHeader, SelectBox } from "components";
import { TableHeaderItemProps } from "components/table/components";
import { SpecialOfferTableBody, SpecialOfferTableBodyItem } from "components/table/tableBodyVariants/SpecialOfferTable";
import styles from "./styles.module.css";

interface SpecialOffersList {
  tableBodyItems: SpecialOfferTableBodyItem[];
  count: number;
  handleImageAsyncUpload: (data) => void;
  handleCreateSpecialOffer: (data) => void;
  serverUrl: string;
  serverError: string;
  clearServerUrl: () => void;
}

const selectBoxStyles = { background: "#fff", padding: "18px 10px", color: "#000" };

const SpecialOfferUI: React.FC<SpecialOffersList> = ({
  tableBodyItems,
  count,
  handleImageAsyncUpload,
  handleCreateSpecialOffer,
  serverUrl,
  serverError,
  clearServerUrl,
}) => {
  const [createOffer, setCreateOffer] = useState(false);
  const [message, setMessage] = useState("");
  const [formFields, setFormFields] = useState({
    name: "",
    country: "",
    baseCurrency: "$",
    tuitionIsFixed: true,
    tuition: 0,
    initialDeposit: 0,
    uniCommissionIsfixed: true,
    uniCommission: 0,
    recruiterCommissionIsFixed: true,
    recruiterCommission: 0,
  });
  const [uniLogo, setUniLogo] = useState({ nameUrl: "", file: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMessage("");

    setFormFields({
      ...formFields,
      [name]: value,
    });
  };

  const handleDropDownChange = (e) => {
    switch (e.containerId) {
      case "countryDropdown":
        setFormFields({ ...formFields, country: e.key });
        break;
      case "baseCurrencyDropdown":
        setFormFields({ ...formFields, baseCurrency: e.key });
        break;
      case "tuitionFormatDropdown":
        setFormFields({ ...formFields, tuitionIsFixed: JSON.parse(e.key) });
        break;
      case "uniCommisionDropdown":
        setFormFields({ ...formFields, uniCommissionIsfixed: JSON.parse(e.key) });
        break;
      case "recruiterCommisionDropdown":
        setFormFields({ ...formFields, recruiterCommissionIsFixed: JSON.parse(e.key) });
        break;
      default:
        break;
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setUniLogo({ file, nameUrl: URL?.createObjectURL(file) });
    clearServerUrl();
  };

  const handleAsyncImageUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("files", uniLogo?.file);
    handleImageAsyncUpload(formData);
    setMessage("");
  };

  const createSpecialOffer = (e) => {
    e.preventDefault();

    const {
      name,
      country,
      tuition,
      initialDeposit,
      uniCommission,
      recruiterCommission,
      uniCommissionIsfixed,
      tuitionIsFixed,
      recruiterCommissionIsFixed,
      baseCurrency,
    } = formFields;

    if (!serverUrl) {
      return setMessage("Upload image first!");
    }

    if (name == "" || country == "") {
      return setMessage("Fill at least name and country fields");
    }

    handleCreateSpecialOffer([
      {
        university: {
          name,
          country,
          logo: serverUrl,
          tuition: {
            isFixed: tuitionIsFixed,
            universityTuition: tuition,
            tuitionCurrency: baseCurrency,
            initialTuitionDeposit: initialDeposit,
          },
          commissionStructure: {
            isFixed: uniCommissionIsfixed,
            universityCommission: uniCommission,
            commissionCurrency: baseCurrency,
            recruitersCommission: {
              isFixed: recruiterCommissionIsFixed,
              commission: recruiterCommission,
            },
          },
        },
      },
    ]);
  };

  // This  is to show how to use the table component
  const tableHeaderTitles: TableHeaderItemProps[] = [
    { title: "Instituition" },
    { title: "Tuition" },
    { title: "Initial Deposit" },
    { title: "University Commission" },
    { title: "Recruiter Commission" },
  ];

  // Custom table Classes
  const CustomTableClasses: AllCustomTableClasses = {
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItem,
    tableWrapperClass: styles.tableWrapperClass,
  };

  return (
    <section>
      <PageHeader
        title="Special Offers"
        description="The table below presents a list of special offer universities."
        count={count}
      />
      <button onClick={() => setCreateOffer(!createOffer)} className={styles.createOffer}>
        {createOffer ? "Close" : "Create"}
      </button>
      {createOffer ? (
        <>
          <form>
            <div className={styles["uniLogoContainer"]}>
              <div className={styles["uniLogoContainerImage"]}>
                {uniLogo?.nameUrl && !serverUrl ? (
                  <WarningIcon className={`${styles["verificationIcon"]} ${styles["warningIcon"]}`} />
                ) : uniLogo?.nameUrl && serverUrl ? (
                  <TickIcon className={`${styles["verificationIcon"]} ${styles["tickIcon"]}`} />
                ) : (
                  ""
                )}
                {uniLogo?.nameUrl !== "" ? (
                  <img src={uniLogo?.nameUrl} alt={uniLogo?.nameUrl} />
                ) : (
                  <CameraIcon className={styles["cameraIcon"]} />
                )}
                <input type="file" accept="image/png, image/jpg, image/jpeg" onChange={handleImageUpload} />
              </div>
              <button
                className={styles.formButton}
                disabled={!uniLogo?.nameUrl || serverUrl !== ""}
                onClick={handleAsyncImageUpload}
              >
                Upload Image
              </button>
            </div>
          </form>
          <form onSubmit={createSpecialOffer}>
            <div className={styles.formContainer}>
              <div>
                <label>Country</label>
                <SelectBox
                  selectGroupID="countryDropdown"
                  dropPosition="bottom"
                  displayStyles={selectBoxStyles}
                  dropdownItems={countries.map(({ name, iso3 }) => {
                    return {
                      containerId: "countryDropdown",
                      title: name,
                      key: iso3,
                      styles: { justifyContent: "flex-start" },
                    };
                  })}
                  onDropItemSelect={handleDropDownChange}
                />
              </div>
              <div>
                <label>Institution name</label>
                <input
                  placeholder="Institution name"
                  type="text"
                  name="name"
                  value={formFields.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Base Currency</label>
                <SelectBox
                  selectGroupID="baseCurrencyDropdown"
                  dropPosition="bottom"
                  displayStyles={selectBoxStyles}
                  defaultSelected={{ title: "Dollars" }}
                  dropdownItems={[
                    {
                      containerId: "baseCurrencyDropdown",
                      title: "Dollars",
                      key: "$",
                      styles: { justifyContent: "flex-start" },
                    },
                    {
                      containerId: "baseCurrencyDropdown",
                      title: "Euro",
                      key: "€",
                      styles: { justifyContent: "flex-start" },
                    },
                  ]}
                  onDropItemSelect={handleDropDownChange}
                />
              </div>
              <div>
                <label>Tuition format</label>
                <SelectBox
                  selectGroupID="tuitionFormatDropdown"
                  dropPosition="bottom"
                  displayStyles={selectBoxStyles}
                  defaultSelected={{ title: "Currency" }}
                  dropdownItems={[
                    {
                      containerId: "tuitionFormatDropdown",
                      title: "Currency",
                      key: "true",
                      styles: { justifyContent: "flex-start" },
                    },
                    {
                      containerId: "tuitionFormatDropdown",
                      title: "Percentage",
                      key: "false",
                      styles: { justifyContent: "flex-start" },
                    },
                  ]}
                  onDropItemSelect={handleDropDownChange}
                />
              </div>
              <div>
                <label>Tuition</label>
                <input
                  placeholder="Tuition"
                  type="number"
                  name="tuition"
                  value={formFields.tuition}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Initial deposit</label>
                <input
                  placeholder="Initial deposit"
                  type="number"
                  name="initialDeposit"
                  value={formFields.initialDeposit}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>University commision format</label>
                <SelectBox
                  selectGroupID="uniCommisionDropdown"
                  dropPosition="bottom"
                  displayStyles={selectBoxStyles}
                  defaultSelected={{ title: "Currency" }}
                  dropdownItems={[
                    {
                      containerId: "uniCommisionDropdown",
                      title: "Currency",
                      key: "true",
                      styles: { justifyContent: "flex-start" },
                    },
                    {
                      containerId: "uniCommisionDropdown",
                      title: "Percentage",
                      key: "false",
                      styles: { justifyContent: "flex-start" },
                    },
                  ]}
                  onDropItemSelect={handleDropDownChange}
                />
              </div>
              <div>
                <label>University commission</label>
                <input
                  placeholder="University commission"
                  type="number"
                  name="uniCommission"
                  value={formFields.uniCommission}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Recruiter commision format</label>
                <SelectBox
                  selectGroupID="recruiterCommisionDropdown"
                  dropPosition="bottom"
                  displayStyles={selectBoxStyles}
                  defaultSelected={{ title: "Currency" }}
                  dropdownItems={[
                    {
                      containerId: "recruiterCommisionDropdown",
                      title: "Currency",
                      key: "true",
                      styles: { justifyContent: "flex-start" },
                    },
                    {
                      containerId: "recruiterCommisionDropdown",
                      title: "Percentage",
                      key: "false",
                      styles: { justifyContent: "flex-start" },
                    },
                  ]}
                  onDropItemSelect={handleDropDownChange}
                />
              </div>
              <div>
                <label>Recruiter commission</label>
                <input
                  placeholder="Recruiter commission"
                  type="number"
                  name="recruiterCommission"
                  value={formFields.recruiterCommission}
                  onChange={handleChange}
                />
              </div>
            </div>
            {message ? (
              <div className={styles.message}>
                <WarningIcon />
                {message}
              </div>
            ) : serverError ? (
              <div className={styles.message}>
                <WarningIcon />
                {` ${serverError}`}
              </div>
            ) : (
              ""
            )}
            <button type="submit" className={styles.formButton}>
              Create
            </button>
          </form>
        </>
      ) : (
        ""
      )}
      {tableBodyItems.length > 0 && (
        <AdminDashBoardTable
          tableHeaderTitles={tableHeaderTitles}
          customTableClasses={CustomTableClasses}
          AdminTableBody={
            <SpecialOfferTableBody tableBodyClassName={styles.tableBodyClass} tableBodyItems={tableBodyItems} />
          }
        />
      )}
    </section>
  );
};

export { SpecialOfferUI };

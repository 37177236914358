import {
  Pagination,
  VerifiedAgencyItem,
  VerifiedAgentsDeleteModal,
  VerifiedAgentsUI,
  VerifiedAgentsUpdateModal,
} from "components";
import { TableHeaderItemProps } from "components/table/components";
import { useApiRequest } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { deleteVerifiedAgentService, getAllVerifiedAgentsService } from "services";
import { optionType } from "types";
import config from "config.json";

const VerifiedAgents = () => {
  // Current page number for pagination component and data fetching
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = React.useState("");
  const [sourceFilter, setSourceFilter] = React.useState<optionType>({ label: "All", value: "" });

  // API Request Variables
  const { run: runVerifiedAgencyFetch, data: agentsData, requestStatus } = useApiRequest({});
  const { run: runAgencyDelete, data: agencyDeleteData, requestStatus: deleteRequestStats } = useApiRequest({});

  const fetchAgents = () => {
    runVerifiedAgencyFetch(
      getAllVerifiedAgentsService({
        pageNo: currentPageNo,
        search,
        source: config.whitelabel ? config.source : sourceFilter.value,
      }),
    );
  };
  // Fetch all verified agents
  useEffect(() => {
    fetchAgents();
  }, [currentPageNo, runVerifiedAgencyFetch, search, sourceFilter]);

  // Request Error Data from API fetching hook
  const agentsFetchrequestError = useMemo(
    () => (agentsData?.response ? agentsData?.response?.data : null),
    [agentsData?.response],
  );

  // Agent Delete Request Error
  const agentDeleterequestError = useMemo(
    () => (agencyDeleteData?.response ? agencyDeleteData?.response?.data : null),
    [agencyDeleteData?.response],
  );

  // Rerun fetch after Agent delete
  useEffect(() => {
    if (agencyDeleteData?.data) fetchAgents();
  }, [agencyDeleteData]);

  // Formatted verified agents
  const allFetchedAgentsData = useMemo(() => agentsData?.data?.data, [agentsData?.data?.data]);
  const preformattedAgentsData = useMemo(() => {
    if (agentsData?.status === 200) {
      const fetchedAgencies = allFetchedAgentsData.fetchedRequests;
      setTotalPages(allFetchedAgentsData.availablePages);

      return fetchedAgencies.map((agency) => ({
        agentName: agency.agencyName,
        representativeName: agency.name,
        emailAddress: agency.email,
        agencyAddress: agency.address,
        agencyId: agency._id,
        role: agency.role,
        dateCreated: new Date(agency.createdAt).toLocaleDateString(),
      })) as VerifiedAgencyItem[];
    }

    return [];
  }, [agentsData, allFetchedAgentsData]);

  // Table header titles
  const tableHeaderTitles: TableHeaderItemProps[] = useMemo(
    () => [
      { title: "S/N" },
      { title: "Agency Name" },
      { title: "Representative" },
      { title: "Email" },
      { title: "Address" },
      { title: "Date Created" },
      { title: "Action" },
    ],
    [],
  );

  // Delete Modal Variables
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [agentToBeDeleted, setAgentToBeDeleted] = useState<VerifiedAgencyItem | null>(null);

  // Launch delete modal
  const handleAgentDelete = (agent: VerifiedAgencyItem) => {
    setAgentToBeDeleted(agent);
    setOpenDeleteModal(true);
  };

  // Cancel delete handler
  const handleDeleteCancel = () => {
    setOpenDeleteModal(false);
    setAgentToBeDeleted(null);
  };

  // Delete submit handler
  const handleAgentDeleteSubmit = () => {
    if (agentToBeDeleted?.agencyId) {
      setOpenDeleteModal(false);
      runAgencyDelete(deleteVerifiedAgentService({ agentId: agentToBeDeleted.agencyId }));
      setAgentToBeDeleted(null);
    }
  };

  // Update modal variables
  const [agentToBeUpdated, setAgentToBeUpdated] = useState<VerifiedAgencyItem | null>(null);

  // Launch role update modal
  const handleAgentRoleUpdate = (item: VerifiedAgencyItem) => {
    setAgentToBeUpdated(item);
  };

  // Show Non-Page UI Elements
  const showErrorFallback = agentsFetchrequestError?.success === false || agentDeleterequestError?.success === false;
  const showPreloader = requestStatus.isPending || deleteRequestStats.isPending;

  // Function to rerun a fetch of all Agents
  const rerunfetchAgents = () =>
    runVerifiedAgencyFetch(
      getAllVerifiedAgentsService({
        pageNo: currentPageNo,
      }),
    );

  // Verified Agents UI Props
  const VerifiedAgentsUIProps = {
    preformattedAgentsData,
    showPreloader,
    showErrorFallback,
    agentsFetchrequestError,
    agentDeleterequestError,
    rerunfetchAgents,
    handleAgentDelete,
    tableHeaderTitles,
    totalAgentsCount: allFetchedAgentsData?.count,
    dropdownActions: {
      handleAgentDelete,
      handleAgentRoleUpdate,
    },
  };

  // Delete Modal Component Props
  const deleteModalProps = {
    openDeleteModal,
    agentToBeDeleted,
    handleAgentDeleteSubmit,
    handleDeleteCancel,
  };

  // Agent update component props
  const agentUpdateProps = {
    handleMessageUpdateCancel: (performRefetch: boolean) => {
      setAgentToBeUpdated(null);
      if (performRefetch) rerunfetchAgents();
    },
    agentDetails: agentToBeUpdated,
  };

  const handleSearch = (search) => {
    setCurrentPageNo(1);
    setSearch(search);
  };

  return (
    <React.Fragment>
      <VerifiedAgentsUI
        currentPage={currentPageNo}
        {...VerifiedAgentsUIProps}
        handleSearch={handleSearch}
        sourceFilter={{ value: sourceFilter, control: (x) => setSourceFilter(x) }}
      />
      <VerifiedAgentsDeleteModal {...deleteModalProps} />
      {agentToBeUpdated && <VerifiedAgentsUpdateModal {...agentUpdateProps} />}
      <Pagination pages={totalPages} handlePage={(page) => setCurrentPageNo(page)} />
    </React.Fragment>
  );
};

export { VerifiedAgents };

import * as React from "react";
import styles from "./styles.module.css";

interface ModalBodyProps {
  bodyClassName?: string;
}

const ModalBody: React.FC<ModalBodyProps> = ({ children, bodyClassName }) => {
  return <div className={`${styles.modalBody} ${bodyClassName}`}>{children}</div>;
};

export { ModalBody };

import { Input } from "components/Input";
import * as React from "react";
import styles from "./styles.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { AddIcon, EditIcon, WarningIcon } from "assets";
import { countries } from "country-flags-svg";
import { optionType } from "types";
import { CustomSelect, objectComparision } from "components";
import { institutionTypeOptions } from "Helper/options";

interface UniData {
  logo: string;
  name: string;
  nationalities: optionType[];
  website: string;
  city: string;
  country: optionType;
  commissionPercentage: number;
  isLanguageSchool: boolean;
  institutionType: optionType;
  isEuropean: boolean;
}

const schema = yup
  .object({
    logo: yup.string().required("Required"),
    name: yup.string().required("Required"),
    country: yup
      .object({ label: yup.string().required("Required"), value: yup.string().required("Required") })
      .required("Required"),
    nationalities: yup.array(),
    website: yup.string().url("Enter valid url").required("Required"),
    city: yup.string().notRequired(),
    commissionPercentage: yup.number().notRequired(),
    isLanguageSchool: yup.boolean().required("Required"),
    institutionType: yup
      .object({ label: yup.string().required("Required"), value: yup.string().required("Required") })
      .required("Required"),
    isEuropean: yup.boolean().required("Required"),
  })
  .required();

interface AddInstitutionProps {
  show: boolean;
  close: () => void;
  submit: (data) => void;
  initialValues: UniData;
}

const EditInstitutionUI: React.FC<AddInstitutionProps> = ({ show, close, submit, initialValues }) => {
  const [photo, setPhoto] = React.useState({ nameUrl: "", file: "" });
  const [nationalities, setNationalities] = React.useState<optionType[]>([]);
  const [errorMessage, setErrorMessage] = React.useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch: watch,
    setValue,
    reset,
  } = useForm<UniData>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  React.useEffect(() => {
    const nat = initialValues.nationalities.slice();
    setNationalities(nat);
    setValue("nationalities", nat);
  }, []);

  const nationalityOptions: optionType[] = [];

  countries.map((item) => {
    nationalityOptions.push({ value: item.iso3, label: item.demonym });
  });
  nationalityOptions.unshift({ value: "Global", label: "Global" });

  React.useEffect(() => {
    if (!show) closeForm();
  }, [show]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setPhoto({ file, nameUrl: URL?.createObjectURL(file) });
    setValue("logo", file);
  };

  const removeNationality = (iso3) => {
    const index = nationalities.findIndex((item) => item.value === iso3);
    if (index >= 0) {
      nationalities.splice(index, 1);
      setNationalities([...nationalities]);
      setValue("nationalities", [...nationalities]);
    }
  };

  const arrayComparison = (array1, array2) => {
    const array1Sorted = array1.slice().sort();
    const array2Sorted = array2.slice().sort();

    return (
      array1.length === array2.length &&
      array1Sorted.every(function (value, index) {
        return value === array2Sorted[index];
      })
    );
  };

  const onSubmit: SubmitHandler<UniData> = (data) => {
    const editData: any = {};

    if (!objectComparision(data.country, initialValues.country)) editData.country = data.country.value;
    if (!objectComparision(data.institutionType, initialValues.institutionType)) editData.institutionType = data.institutionType.value;
    if (data.website !== initialValues.website) editData.website = data.website;
    if (data.city !== initialValues.city) editData.city = data.city;
    if (data.name !== initialValues.name) editData.name = data.name;
    if (data.isEuropean !== initialValues.isEuropean) editData.isEuropean = data.isEuropean;
    if (data.isLanguageSchool !== initialValues.isLanguageSchool) editData.isLanguageSchool = data.isLanguageSchool;
    if (data.commissionPercentage !== initialValues.commissionPercentage)
      editData.commissionPercentage = data.commissionPercentage;
    if (photo.file) editData.logo = photo.file;
    if (
      !arrayComparison(
        data.nationalities.map((item) => item.value),
        initialValues.nationalities.map((item) => item.value),
      )
    )
      editData.nationalities = data.nationalities.map((item) => item.value);

    if (Object.keys(editData).length === 0) {
      setErrorMessage("You haven't updated any data");
      return;
    }
    setErrorMessage("");
    submit(editData);

    // console.log(editData)
  };

  const countryOptions: optionType[] = [];

  countries.map((item) => {
    countryOptions.push({ value: item.iso3, label: item.name });
  });

  const closeForm = () => {
    reset();
    close();
    setPhoto({ nameUrl: "", file: "" });
  };
  return (
    <aside className={`${styles.wrapper} ${show ? styles.show : styles.hide}`}>
      <AddIcon
        className={`${styles.closeBtn} ${show ? styles.showIcon : styles.hideIcon}`}
        role="button"
        onClick={closeForm}
      />
      <h1 className={styles.ttl}>Edit Institution</h1>
      <p className={styles.txt}>Complete the form below to edit an institution&apos;s profile</p>

      <form className={styles.form}>
        <div className={styles.logoWrap}>
          <img src={photo.nameUrl === "" ? watch("logo") : photo.nameUrl} alt="logo" />
          <span>Institution logo</span>
          {!watch("logo") && errors.logo?.message ? (
            <span className={styles.message}>
              <WarningIcon /> {errors.logo?.message}
            </span>
          ) : (
            ""
          )}
          <label htmlFor="logo">
            <EditIcon />
            <input
              onChange={handleImageUpload}
              style={{ display: "none" }}
              id="logo"
              type={"file"}
              accept=".png, .jpg, .jpeg"
            />
          </label>
        </div>

        <Input
          label="Institution Name*"
          placeholder="Eg. University of Manitoba"
          type="text"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.name?.message}
          name="name"
          register={register}
        />
        <CustomSelect
          onChange={(x) => setValue("country", x)}
          validatorMessage={
            !(watch("country.label") && watch("country.value"))
              ? errors.country?.message ?? errors.country?.label?.message ?? errors.country?.value?.message ?? ""
              : ""
          }
          inputClass={styles.select}
          name={"country"}
          placeholder={"Select..."}
          label={"Country"}
          options={countryOptions}
          value={watch("country")}
          parentClassName={styles.inputWrap}
        />
        <Input
          label="City "
          placeholder="Eg. Winnipeg"
          type="text"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.city?.message}
          name="city"
          register={register}
        />
        <Input
          label="Website"
          placeholder="Eg.https://www.universityofmanitoba.com"
          type="text"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.website?.message}
          name="website"
          register={register}
        />
        <Input
          label="Commission %"
          placeholder="Eg. 15"
          type="number"
          parentClassName={styles.inputWrap}
          validatorMessage={errors.commissionPercentage?.message}
          name="commissionPercentage"
          register={register}
        />

        <CustomSelect
          onChange={(x) => {
            const index = nationalities.findIndex((item) => item.value === x.value);
            if (index < 0) {
              setValue("nationalities", [x, ...nationalities]);
              setNationalities((prev) => [x, ...prev]);
            }
          }}
          validatorMessage={watch("nationalities").length === 0 ? errors.nationalities?.message ?? "" : ""}
          inputClass={styles.select}
          name={"nationalities"}
          placeholder={"Select..."}
          label={"Nationality"}
          options={nationalityOptions}
          value={{ label: "", value: "" }}
          parentClassName={styles.inputWrap}
        />
        {nationalities.length > 0 && (
          <div className={styles.agentList}>
            {nationalities.map((item, index) => (
              <span key={index} className={styles.agent}>
                {item.label} <AddIcon onClick={() => removeNationality(item.value)} role={"button"} />
              </span>
            ))}
          </div>
        )}

        <CustomSelect
          onChange={(x) => setValue("institutionType", x)}
          validatorMessage={
            !(watch("institutionType.label") && watch("institutionType.value"))
              ? errors.institutionType?.message ??
                errors.institutionType?.label?.message ??
                errors.institutionType?.value?.message ??
                ""
              : ""
          }
          inputClass={styles.select}
          name={"institutionType"}
          placeholder={"Select..."}
          label={"Institution Type*"}
          options={institutionTypeOptions}
          value={watch("institutionType")}
          parentClassName={styles.inputWrap}
        />
        <label className={styles.check}>
          <input
            checked={watch("isLanguageSchool")}
            onClick={() => setValue("isLanguageSchool", !watch("isLanguageSchool"))}
            type={"checkbox"}
          />
          <span>Language School</span>
        </label>
        <label className={styles.check}>
          <input
            checked={watch("isEuropean")}
            onClick={() => setValue("isEuropean", !watch("isEuropean"))}
            type={"checkbox"}
          />
          <span>European</span>
        </label>
        <button className={styles.submitBtn} onClick={handleSubmit(onSubmit)}>
          Submit
        </button>
        {errorMessage ? <p className={styles.errorMessage}>{errorMessage}</p> : ""}
      </form>
    </aside>
  );
};

export { EditInstitutionUI };

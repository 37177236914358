import React from "react";
import { TableRowItem } from "../../components";
import { AdminTableBody } from "../../components/tbody";
import { TableDataItem } from "../../components/td";
import styles from "./styles.module.css";

// Blogs Table Body Item
export interface BlogsTableBodyItem {
  id: string;
  date: string;
  title: string;
  image: string;
  description: string;
  link: string;
}

// University Verification Table Body Props
interface TableBodyProps {
  tableBodyItems: BlogsTableBodyItem[];
  tableBodyClassName: string;
  viewBlog: (id: string) => void;
}

const BlogsTableBody: React.FC<TableBodyProps> = ({ tableBodyItems, tableBodyClassName, viewBlog }) => {
  return (
    <AdminTableBody customClassName={`${styles.tableContainer} `}>
      {tableBodyItems.map((item, idx) => (
        <TableRowItem key={`${item.title} ${idx}`} customClassName={styles.tableRow}>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {item.date.split("T")[0]}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.title}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            <a rel="noreferrer" target={"_blank"} href={item.link}>
              {item.link}
            </a>
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            <button onClick={() => viewBlog(item.id)} className={styles.viewBtn}>
              View
            </button>
          </TableDataItem>
        </TableRowItem>
      ))}
    </AdminTableBody>
  );
};

export { BlogsTableBody };

import { ViewCommentIcon } from "assets";
import { applicationInfo } from "components/ApplicationDetails";
import { TableRowItem, TableDataItem } from "components/table/components";
import { AdminTableBody } from "components/table/components/tbody";
import * as React from "react";
import styles from "./styles.module.css";

// Agency Overview Body Item
export interface ProspectApplicationTableItem {
  publicID: string;
  university: string;
  uniLogo: string;
  course: string;
  degree: string;
  country: string;
  status: string;
  id: string;
  intakeYear: string;
  intakeMonth: string;
  commentCount: number;
  prospectPhoto: string;
  prospectName: string;
}

// Agency Overview Body Props
interface TableBodyProps {
  tableBodyClassName: string;
  tableBodyItems: ProspectApplicationTableItem[];
  viewComment: (x: applicationInfo) => void;
}

const ProspectApplicationTable: React.FC<TableBodyProps> = ({ tableBodyItems, viewComment, tableBodyClassName }) => {
  console.log(tableBodyItems)
  return (
    <>
      <AdminTableBody customClassName={styles.tableBody}>
        {tableBodyItems.map((item, index) => (
          <TableRowItem key={`${item.id} ${index}`} customClassName={styles.tableRow}>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
              <div className={styles.universityWrap}>
                <img src={item.uniLogo} />
                <div className={styles.student}>
                  <span>{item.university}</span>
                  <span>{item.country}</span>
                </div>
              </div>
            </TableDataItem>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
              <div className={styles.student}>
                <span>{item.course}</span>
                <span>{item.degree}</span>
              </div>
            </TableDataItem>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
              <span>
                {item.intakeMonth}, {item.intakeYear}
              </span>
            </TableDataItem>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
              <span style={{textTransform: "capitalize"}} >{item.status}</span>
            </TableDataItem>

            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
              <ViewCommentIcon
                className={styles.viewCommentIcon}
                role="button"
                tabIndex={0}
                onClick={() =>
                  viewComment({
                    name: item.prospectName,
                    passportPhoto: item.prospectPhoto,
                    university: item.university,
                    course: item.course,
                    degree: item.degree,
                    id: item.id,
                  })
                }
              />
              {item.commentCount ? (
                <div className={styles.commentCount}>
                  {item.commentCount}{" "}
                  <div className={styles.commentCountDescrip}>{item.commentCount} new comment(s)</div>
                </div>
              ) : (
                ""
              )}
            </TableDataItem>
          </TableRowItem>
        ))}
      </AdminTableBody>
    </>
  );
};

export { ProspectApplicationTable };

import { SendSVGIcon } from "assets/vectors";
import { SelectBox } from "components";
import { CommentProps, repOptions } from "components/ApplicationDetails";
import CommentCard from "components/ApplicationDetails/Comments";
import { DeleteCommentPrompt } from "components/ApplicationDetails/DeleteCommentPrompt";
import * as React from "react";
import styles from "./styles.module.css";

interface ProspectCommentProps {
  prospectId: string;
  show: boolean;
  closeModal: () => void;
  comments: CommentProps[];
  addNewComment: (id, data) => void;
  editComment: (id, commentID, data) => void;
  deleteComment: (id, commentID) => void;
  commentLoader: boolean;
  clearComment: boolean;
}

const ProspectComments: React.FC<ProspectCommentProps> = ({
  show,
  closeModal,
  comments,
  addNewComment,
  editComment,
  deleteComment,
  commentLoader,
  clearComment,
  prospectId,
}) => {
  const [newComment, setNewComment] = React.useState<string>("");
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [commentID, setCommentID] = React.useState<string>("");
  const [deletePrompt, setDeletePrompt] = React.useState<boolean>(false);
  const [rep, setRep] = React.useState("");

  React.useEffect(() => {
    setNewComment("");
    setIsEdit(false);
    setCommentID("");
  }, [clearComment]);

  const createComment = () => {
    // setLoading3(true);
    const data = {
      name: rep,
      comment: newComment,
    };

    if (isEdit) {
      editComment(prospectId, commentID, data);
    } else {
      addNewComment(prospectId, data);
    }
  };

  const closeProspectComments = () => {
    setNewComment("");
    setIsEdit(false)
    setCommentID("")
    closeModal();
  };

  const selectBoxStyles = {
    background: "#e9eeff",
    color: "inherit",
    fontWeight: "500",
    minWidth: "unset",
    border: "none",
    padding: " 0 2rem 0 16px",
    borderRadius: "20px",
    fontSize: "13px",
    paddingRight: "2rem",
    height: "26px",
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <aside className={`${styles.wrapper} ${show ? styles.show : styles.hide}`}>
        <button className={styles.close} onClick={closeProspectComments}>
          &times;
        </button>
        <div className={styles.formWrap}>
          <h3 className={styles.formTxt}>Prospect Comments</h3>

          <div className={styles.commentsContainer}>
            <div className={styles.commentScrollContanier}>
              {commentLoader ? (
                <div className={styles.spinner}></div>
              ) : (
                <>
                  {comments.length > 0 ? (
                    comments.map((item, index) => (
                      <CommentCard
                        key={index}
                        name={item.name}
                        role={item.role}
                        comment={item.comment}
                        date={item.date}
                        id={item.id}
                        userID={item.userID}
                        editComment={(id, comment) => {
                          setNewComment(comment);
                          setIsEdit(true);
                          setCommentID(id);
                        }}
                        deleteComment={(id) => {
                          setDeletePrompt(true);
                          setCommentID(id);
                        }}
                      />
                    ))
                  ) : (
                    <p className={styles.emptyComment}>No comments</p>
                  )}{" "}
                </>
              )}
            </div>
            <SelectBox
              selectGroupID="Representative profile"
              dropPosition="bottom"
              displayStyles={selectBoxStyles}
              dropdownItems={repOptions}
              onDropItemSelect={(e) => setRep(e.title)}
              defaultSelected={{ title: rep }}
              containerClassName={styles.dropdownWrap}
              label="Choose profile"
            />
            <div className={styles.commentInputWrapper}>
              <div className={styles.avatar}>
                <div>V</div>
              </div>
              <textarea
                className={styles.commentTextarea}
                placeholder="Add comment"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  createComment();
                }}
                className={`appBtnFill ${styles.commentSendBtn}`}
              >
                <SendSVGIcon />
              </button>
            </div>
          </div>
        </div>
      </aside>
      <DeleteCommentPrompt
        show={deletePrompt}
        closeModal={() => setDeletePrompt(false)}
        deleteComment={() => deleteComment(prospectId, commentID)}
      />
    </>
  );
};

export { ProspectComments };

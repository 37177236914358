import { CameraIcon, TickIcon, WarningIcon } from "assets/vectors";
import { AdminDashBoardTable, AllCustomTableClasses, BlogsTableBody, PageHeader } from "components";
import { TableHeaderItemProps } from "components/table/components";
import { BlogsTableBodyItem } from "components/table/tableBodyVariants";
import * as React from "react";
import styles from "./styles.module.css";

interface BlogProps {
  tableBodyItems: BlogsTableBodyItem[];
  count: number;
  handleImageAsyncUpload: (data) => void;
  serverUrl: string;
  serverError: string;
  clearServerUrl: () => void;
  handleCreateBlog: (data) => void;
  viewBlog: (id: string) => void;
}

const BlogsUI: React.FC<BlogProps> = ({
  tableBodyItems,
  count,
  clearServerUrl,
  handleImageAsyncUpload,
  serverError,
  serverUrl,
  handleCreateBlog,
  viewBlog,
}) => {
  const [createBlog, setCreateBlog] = React.useState(false);
  const [formFields, setFormFields] = React.useState({
    title: "",
    description: "",
    link: "",
  });
  const [blogImage, setBlogImage] = React.useState({ nameUrl: "", file: "" });
  const [message, setMessage] = React.useState("");
  const tableHeaderTitles: TableHeaderItemProps[] = [
    { title: "Date" },
    { title: "Title" },
    { title: "Link" },
    { title: "Actions" },
  ];

  const CustomTableClasses: AllCustomTableClasses = {
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItem,
    tableWrapperClass: styles.tableWrapperClass,
    tableContainerClassName: styles.tableContainer
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setBlogImage({ file, nameUrl: URL?.createObjectURL(file) });
    clearServerUrl();
  };

  const handleAsyncImageUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("files", blogImage?.file);
    handleImageAsyncUpload(formData);
    setMessage("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMessage("");

    setFormFields({
      ...formFields,
      [name]: value,
    });
  };

  const createNewBlog = (e) => {
    e.preventDefault();
    const { title, description, link } = formFields;
    console.log(formFields);

    const urlCheck = new RegExp(/(http|https):\/\/(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}/);

    if (!urlCheck.test(link)) {
      setMessage("Please enter a valid url");
      return;
    }

    if (!serverUrl) {
      return setMessage("Upload image first!");
    }

    if (title === "" || description === "" || link === "") {
      return setMessage(
        `${!title ? "Title" : !description ? "Description" : !link ? "Link" : ""} cannot be left empty`,
      );
    }

    handleCreateBlog({ title, description, blog_link: link, image: serverUrl });
  };

  const emptyFields = () => {
    setFormFields({
      title: "",
      description: "",
      link: "",
    });
    setBlogImage({ nameUrl: "", file: "" });
  };
  return (
    <React.Fragment>
      <PageHeader title="Blogs" description="The table below presents a list of blogs." count={count} />
      <button
        onClick={() => {
          setCreateBlog(!createBlog);
          if (createBlog) emptyFields();
        }}
        className={styles.createBlog}
      >
        {createBlog ? "Close" : "Create"}
      </button>
      {createBlog ? (
        <>
          <form>
            <div className={styles["uniLogoContainer"]}>
              <div className={styles["uniLogoContainerImage"]}>
                {blogImage?.nameUrl && !serverUrl ? (
                  <WarningIcon className={`${styles["verificationIcon"]} ${styles["warningIcon"]}`} />
                ) : blogImage?.nameUrl && serverUrl ? (
                  <TickIcon className={`${styles["verificationIcon"]} ${styles["tickIcon"]}`} />
                ) : (
                  ""
                )}
                {blogImage?.nameUrl !== "" ? (
                  <img src={blogImage?.nameUrl} alt={blogImage?.nameUrl} />
                ) : (
                  <CameraIcon className={styles["cameraIcon"]} />
                )}
                <input type="file" accept="image/png, image/jpg, image/jpeg" onChange={handleImageUpload} />
              </div>
              <button
                className={styles.formButton}
                disabled={!blogImage?.nameUrl || serverUrl !== ""}
                onClick={handleAsyncImageUpload}
              >
                Upload Image
              </button>
            </div>
          </form>
          <form>
            <div className={styles.formContainer}>
              <div>
                <label>Title</label>
                <input placeholder="Title" type="text" name="title" value={formFields.title} onChange={handleChange} />
              </div>
              <div>
                <label>Link</label>
                <input placeholder="Link" type="url" name="link" value={formFields.link} onChange={handleChange} />
              </div>
              <div>
                <label>Description</label>
                <textarea
                  placeholder="Enter a short description"
                  name="description"
                  value={formFields.description}
                  onChange={handleChange}
                />
              </div>
            </div>
            {message ? (
              <div className={styles.message}>
                <WarningIcon />
                {message}
              </div>
            ) : serverError ? (
              <div className={styles.message}>
                <WarningIcon />
                {` ${serverError}`}
              </div>
            ) : (
              ""
            )}
            <button onClick={createNewBlog} type="submit" className={styles.formButton}>
              Create
            </button>
          </form>
        </>
      ) : (
        ""
      )}
      {tableBodyItems.length > 0 && (
        <AdminDashBoardTable
          tableHeaderTitles={tableHeaderTitles}
          customTableClasses={CustomTableClasses}
          AdminTableBody={
            <BlogsTableBody
              viewBlog={viewBlog}
              tableBodyClassName={styles.tableBodyClass}
              tableBodyItems={tableBodyItems}
            />
          }
        />
      )}
    </React.Fragment>
  );
};
export { BlogsUI };
export * from "./DeleteBlogPrompt";

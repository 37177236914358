import styles from "./styles.module.css";
import * as React from "react";
import { CaretLeftBlue } from "assets/vectors";
import { TableHeaderItemProps } from "components/table/components";
import { AdminDashBoardTable, AllCustomTableClasses, UniversityTableBody, UniversityTableBodyItem } from "components";

export interface ContactProps {
  agencyName: string;
  address: string;
  email: string;
  phone: string;
}

interface ViewUniProps {
  back: () => void;
  tableBodyItems: UniversityTableBodyItem[];
  contactDetails: any;
  universityCount: number;
  updateUniversity: (university_id: string, request_id: string, status: string) => void;
}

const ViewUniversitiesUI: React.FC<ViewUniProps> = ({
  back,
  tableBodyItems,
  contactDetails,
  universityCount,
  updateUniversity,
}) => {
  const tableHeaderTitles: TableHeaderItemProps[] = [
    { title: "University" },
    { title: "Country" },
    { title: "Commission received" },
    { title: "Commission paid" },
    { title: "Action" },
    { title: "Status" },
  ];

  // Custom table Classes
  const CustomTableClasses: AllCustomTableClasses = {
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItem,
    tableWrapperClass: styles.tableWrapperClass
  };

  return (
    <section>
      <button className={styles.backBtn} onClick={back}>
        <CaretLeftBlue /> Back
      </button>
      <div className={styles.header}>
        <h1 className={styles.title}>{contactDetails.agencyName}</h1>
        <p className={styles.description}>{contactDetails.address}</p>
        <p className={styles.description}>{contactDetails.email}</p>
        <p className={styles.description}>{contactDetails.phone}</p>
      </div>
      {tableBodyItems.length > 0 && <p className={styles.count}>{universityCount}</p>}
      <AdminDashBoardTable
        tableHeaderTitles={tableHeaderTitles}
        customTableClasses={{ ...CustomTableClasses }}
        AdminTableBody={
          <UniversityTableBody
            handleUpdate={(university_id: string, request_id: string, status: string) =>
              updateUniversity(university_id, request_id, status)
            }
            tableBodyClassName={styles.tableBodyClass}
            tableBodyItems={tableBodyItems}
          />
        }
      />{" "}
    </section>
  );
};

export { ViewUniversitiesUI };

import * as React from "react";
import { Modal } from "components";
import styles from "./styles.module.css";
import { ModalBody } from "components/Modal/components";

interface DeleteCommentPromptProps {
  show: boolean;
  closeModal: () => void;
  deleteComment: () => void;
}

const DeleteCommentPrompt: React.FC<DeleteCommentPromptProps> = ({ show, closeModal, deleteComment }) => {
  return (
    <Modal dialogClassName={styles.deleteModal} contentClassName={styles.content} show={show} onHide={closeModal}>
      <ModalBody bodyClassName={styles.body}>
        <p className={styles.promptTxt}>Are you sure you want to delete this comment? This action cannot be undone</p>
        <button onClick={closeModal} className={`appBtnOutline ${styles.promptBtn}`}>
          Cancel
        </button>
        <button onClick={() => {closeModal(); deleteComment()}} className={`appBtnFill ${styles.promptBtn}`}>
          Yes, delete
        </button>
      </ModalBody>
    </Modal>
  );
};

export { DeleteCommentPrompt };

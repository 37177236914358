import { EmptyResultVector } from "assets/vectors";
import React from "react";
import styles from "../styles.module.css";

// Empty Table Body
const SupportEmptyTableBody = ({ currentTab = "" }: { currentTab: string }) => {
  return (
    <div className={styles.emptyTableContainer}>
      <EmptyResultVector className={styles.svg} />
      <p>No {currentTab.toLowerCase()} messages found.</p>
    </div>
  );
};

export { SupportEmptyTableBody };

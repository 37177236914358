import { getInitials } from "Helper/initials";
import * as React from "react";
import { CommentProps } from "..";
import styles from "./styles.module.css";

export interface CommentCardProps extends CommentProps {
  editComment: (id: string, comment: string) => void;
  deleteComment: (id: string) => void;
}

const CommentCard: React.FC<CommentCardProps> = ({
  name,
  role,
  comment,
  date,
  editComment,
  deleteComment,
  id,
  userID,
}) => {
  const currentUser = localStorage.getItem("vobbAdminID");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const commentDate = new Date(date);
  return (
    <div className={styles.wrapper}>
      <div className={styles.avatar}>
        <div>{getInitials(name)}</div>
      </div>
      <div className={styles.details}>
        <h5 className={styles.detailsTtl}>
          {name}{" "}
          {role === "user"
            ? " (Agent)"
            : role === "super_agent"
            ? "(Representative)"
            : role === "student"
            ? " (Student)"
            : " (Account manager)"}
        </h5>
        <p className={styles.detailsTxt}> {comment} </p>
        <div className={styles.actions}>
          <p className={styles.date}>{`${commentDate.getDate()} ${months[commentDate.getMonth()].substring(
            0,
            3,
          )} ${commentDate.getFullYear()} at ${commentDate.getHours()}:${commentDate.getMinutes()}`}</p>
          {currentUser === userID && (
            <button
              onClick={(e) => {
                e.preventDefault();
                editComment(id, comment);
              }}
              className={styles.edit}
            >
              Edit
            </button>
          )}
          {currentUser === userID && (
            <button
              onClick={(e) => {
                e.preventDefault();
                deleteComment(id);
              }}
              className={styles.delete}
            >
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommentCard;

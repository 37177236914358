import { AddIcon, CaretLeftBlue, CopyIcon, EditIcon, SearchIcon, TickDoubleIcon } from "assets";
import {
  AdminDashBoardTable,
  AllCustomTableClasses,
  InstitutionAgentsTableBodyItem,
  InstitutionAgentsTableBody,
  CoursesTableBodyItem,
  CoursesTableBody,
  Toggle,
  Pagination,
  SelectBox,
} from "components";
import { TableHeaderItemProps } from "components/table/components";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

export interface InstitutionData {
  name: string;
  logo: string;
  website: string;
  country: string;
  city: string;
  commissionPercentage: number;
  flag: string;
  nationalities: string[];
  id: string;
  isLanguageSchool: boolean;
  isEuropean: boolean;
  institutionType: string;
}

interface InstitutionProps {
  courseList: CoursesTableBodyItem[];
  agentsList: InstitutionAgentsTableBodyItem[];
  handleViewCourse: (id: string) => void;
  pageInfo: {
    courses: {
      count: number;
      totalPages: number;
      updateCurrentPage: (x) => void;
      currentPage: number;
    };
    agents: {
      count: number;
      totalPages: number;
      updateCurrentPage: (x) => void;
      currentPage: number;
    };
  };
  addCourse: () => void;
  addAgent: () => void;
  handleSearch: (x: string) => void;
  institution: InstitutionData;
  status: {
    value: boolean;
    control: () => void;
  };
  degreeFilter: {
    value: string;
    control: (x) => void;
  };
  attendanceFilter: {
    value: string;
    control: (x) => void;
  };
  editUni: () => void;
  updateAgentStatus: (id: string, stat: boolean) => void;
}

const InstitutionUI: React.FC<InstitutionProps> = ({
  courseList,
  agentsList,
  handleViewCourse,
  addCourse,
  addAgent,
  handleSearch,
  institution,
  status,
  pageInfo,
  degreeFilter,
  attendanceFilter,
  editUni,
  updateAgentStatus,
}) => {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");
  const [view, setView] = React.useState<number>(1);

  // This  is to show how to use the table component
  const tableHeaderTitlesCourse: TableHeaderItemProps[] = [
    { title: "Course" },
    { title: "Degree" },
    { title: "Duration" },
    { title: "" },
  ];

  const tableHeaderTitlesAgents: TableHeaderItemProps[] = [
    { title: "Agent" },
    { title: "Agency" },
    { title: "Address" },
    { title: "Email" },
    { title: "Status" },
  ];

  // Custom table Classes
  const CustomTableClassesCourses: AllCustomTableClasses = {
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItem,
    tableWrapperClass: styles.tableWrapperClass,
  };

  const CustomTableClassesAgents: AllCustomTableClasses = {
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItemAgents,
    tableWrapperClass: styles.tableWrapperClass,
  };

  const handleChange = (e) => {
    if (e.target.value === "") {
      handleSearch("");
    }
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    setSearch("");
  }, []);

  const selectBoxStyles = {
    background: "transparent",
    color: "inherit",
    fontWeight: "500",
    minWidth: "unset",
    border: "none",
    padding: "8px 10px",
    borderRadius: "8px",
    fontSize: "13px",
    paddingRight: "2rem",
    width: "140px",
    justifyContent: "flex-end",
  };

  const [copied, setCopied] = React.useState(false);
  const handleCopyID = (id) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3500);
      })
      .catch((err) => {
        // Copy manually
      });
  };

  return (
    <section>
      <button onClick={() => navigate(-1)} className={styles.backBtn}>
        <CaretLeftBlue /> Back
      </button>
      <div className={styles.headerSec}>
        <div className={styles.ttl}>
          <img src={institution.logo} alt="logo" />
          <span>
            <h1>
              {institution.name}{" "}
              {institution.isLanguageSchool ? <span className={styles.langSch}>(Language School)</span> : ""}
            </h1>
            <a target={"_blank"} rel="noreferrer" className={styles.website} href={institution.website}>
              Visit website
            </a>
          </span>
          <EditIcon onClick={editUni} role="button" className={styles.editBtn} />
        </div>
        {institution.institutionType ? (
          <p className={styles.commission}>
            Institution Type: <b>{institution.institutionType}</b>
          </p>
        ) : (
          ""
        )}
        {institution.commissionPercentage ? (
          <p className={styles.commission}>
            Commission: <b>{institution.commissionPercentage}%</b>{" "}
          </p>
        ) : (
          ""
        )}
        <p className={styles.country}>
          <img src={institution.flag} alt="" />{" "}
          <span>
            {institution.country} {institution.city ? ", " + institution.city : ""}{" "}
            {institution.isEuropean ? " (European)" : ""}
          </span>
        </p>

        <div className={styles.toggle}>
          <Toggle onClick={status.control} checked={status.value} />{" "}
          <span>{status.value ? "Accepting applications" : "Not accepting applications"}</span>
        </div>
        <div className={styles.copySec}>
          <span>{institution.id}</span>
          <button onClick={() => handleCopyID(institution.id)}>
            {!copied ? <CopyIcon className={styles.copyIcon} /> : <TickDoubleIcon className={styles.tickIcon} />}
          </button>
        </div>
        {institution.nationalities.length > 0 && (
          <div className={styles.nationaityWrap}>
            <p>Accepted nationalities</p>
            <div>
              {institution.nationalities.map((item, index) => (
                <span key={index}>{item}</span>
              ))}
            </div>
          </div>
        )}
      </div>
      <nav className={styles.nav}>
        <p className={view === 1 ? styles.activeNav : ""} role={"button"} onClick={() => setView(1)}>
          <span>{pageInfo.courses.count}</span>
          Courses
        </p>
        <p className={view === 2 ? styles.activeNav : ""} role={"button"} onClick={() => setView(2)}>
          <span>{pageInfo.agents.count}</span>
          Agents
        </p>
      </nav>
      <div className={styles.tableExtra}>
        <button onClick={() => (view === 1 ? addCourse() : addAgent())} className={styles.addUniBtn}>
          <AddIcon /> Add {view === 1 ? "Course" : "Agent"}
        </button>
        {view === 1 && (
          <div className={styles.searchFilterWrapper}>
            <div className={styles.searchInputWrapper}>
              <SearchIcon />
              <input
                className={styles.searchInput}
                value={search}
                onChange={handleChange}
                type="search"
                placeholder="Search..."
                onKeyPress={(e) => {
                  if (e.key === "Enter") handleSearch(search);
                }}
              />
            </div>
            <SelectBox
              selectGroupID="courseDegree"
              dropPosition="bottom"
              displayStyles={selectBoxStyles}
              dropdownItems={[
                { title: "All" },
                { title: "Undergraduate" },
                { title: "Masters" },
                { title: "PhD" },
                { title: "Diploma" },
                { title: "Foundational" },
              ]}
              onDropItemSelect={(e) => degreeFilter.control(e)}
              defaultSelected={{ title: degreeFilter.value }}
              containerClassName={styles.dropdownWrap}
              label="Degree"
            />
            <SelectBox
              selectGroupID="courseAttendance"
              dropPosition="bottom"
              displayStyles={selectBoxStyles}
              dropdownItems={[{ title: "All" }, { title: "Onsite" }, { title: "Online" }]}
              onDropItemSelect={(e) => attendanceFilter.control(e)}
              defaultSelected={{ title: attendanceFilter.value }}
              containerClassName={styles.dropdownWrap}
              label="Attendance"
            />
          </div>
        )}
      </div>
      {view === 2 ? (
        <>
          {agentsList.length > 0 && (
            <AdminDashBoardTable
              tableHeaderTitles={tableHeaderTitlesAgents}
              customTableClasses={CustomTableClassesAgents}
              AdminTableBody={
                <InstitutionAgentsTableBody
                  tableBodyClassName={styles.tableBodyClassAgents}
                  tableBodyItems={agentsList}
                  updateStatus={updateAgentStatus}
                />
              }
            />
          )}
          <Pagination
            currentPage={pageInfo.agents.currentPage}
            pages={pageInfo.agents.totalPages}
            handlePage={pageInfo.agents.updateCurrentPage}
          />
        </>
      ) : (
        <>
          {courseList.length > 0 && (
            <AdminDashBoardTable
              tableHeaderTitles={tableHeaderTitlesCourse}
              customTableClasses={CustomTableClassesCourses}
              AdminTableBody={
                <CoursesTableBody
                  handleView={handleViewCourse}
                  tableBodyClassName={styles.tableBodyClass}
                  tableBodyItems={courseList}
                />
              }
            />
          )}

          <Pagination
            currentPage={pageInfo.courses.currentPage}
            pages={pageInfo.courses.totalPages}
            handlePage={pageInfo.courses.updateCurrentPage}
          />
        </>
      )}
    </section>
  );
};

export { InstitutionUI };
export * from "./addCourse";
export * from "./addAgent";
export * from "./editInstitution";
export * from "./editCourse";

import { Chart as ChartJS, ChartData, registerables, ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";
import * as React from "react";

ChartJS.unregister();
ChartJS.register(...registerables);
ChartJS.defaults.font.family = "Barlow";
ChartJS.defaults.font.size = 13;

interface ProspectData {
  data: number[];
}
const ProspectPlanChart: React.FC<ProspectData> = ({ data }) => {
  const chartData: ChartData<"bar"> = {
    labels: ["Free", "Gold ($65)", "Ruby ($95)", "Platinum"],
    datasets: [
      {
        data: data,
        backgroundColor: [
          "rgb(192 192 192 / 20%)",
          "rgb(255 215 0 / 20%)",
          "rgb(124 224 255 / 20%)",
          "rgb(255 124 251 / 20%)",
        ],
        borderColor: ["rgb(192 192 192)", "rgb(255 215 0)", "rgb(124 224 255)", "rgb(255 124 251)"],
        borderWidth: 1,
        borderRadius: 4,
        barThickness: 32,
        maxBarThickness: 32,
        categoryPercentage: 0.5,
        barPercentage: 1.0,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const config = {
    type: "bar",
    data: chartData,
    options: options,
  };

  return <Bar {...config} />;
};

const ProspectStatusChart: React.FC<ProspectData> = ({ data }) => {
  const chartData: ChartData<"bar"> = {
    labels: ["Created", "Ready", "Changes", "Complete"],
    datasets: [
      {
        data: data,
        backgroundColor: [
          "rgb(124 124 255 / 20%)",
          "rgb(255 159 64 / 20%)",
          "rgb(255 124 124 / 20%)",
          "rgb(0 193 0 / 20%)",
        ],
        borderColor: ["rgb(124 124 255)", "rgb(255 159 64)", "rgb(255 124 124 )", "rgb(0 193 0)"],
        borderWidth: 1,
        borderRadius: 4,
        barThickness: 32,
        maxBarThickness: 32,
        categoryPercentage: 0.5,
        barPercentage: 1.0,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {},
    },
  };

  const config = {
    type: "bar",
    data: chartData,
    options: options,
  };

  return <Bar {...config} />;
};

export { ProspectPlanChart, ProspectStatusChart };

import * as React from "react";
import { useParams } from "react-router-dom";
import { educationHistoryOptions, languageQualificationOptions } from "Helper/options";
import {
  GeneralInfo,
  initialGeneralInfo,
  initDocsUpload,
  uploadDocsType,
  EducationHistory,
  Language,
  Toast,
  EditProspectUI,
  ProspectInfo,
} from "components";
import { useApiRequest } from "hooks";
import { EditProspectData, editProspectService, uploadService, viewProspectService } from "services";
import Preloader from "components/Preloader";
import { getErrorMessage } from "Helper/errorMessage";
import { getCountry, getNationality } from "Helper/country";
import { DocType, emptyDoc, initialUploadedDocuments, UploadedDocuments } from "components/EditProspect/types";

const initProspect: ProspectInfo = {
  generalInfo: initialGeneralInfo,
  languageInfo: [],
  eduHistory: [],
  initAvatar: emptyDoc,
  uploadedDocs: initialUploadedDocuments,
};

const initProspectData = {
  firstName: "",
  lastName: "",
  middleName: "",
  dob: "",
  gender: "",
  maritalStatus: "",
  fName: "",
  mName: "",
  nationality: "",
  passport: {
    number: "",
    issueDate: "",
    expiryDate: "",
    issueCountry: "",
  },
  placeOfBirth: "",
  countryOfBirth: "",
  language: [],
  contactInfo: {
    address: "",
    city: "",
    state: "",
    country: "",
    email: "",
    phone: "",
  },
  education: [],
  suppDocs: initDocsUpload,
};

const EditProspect = () => {
  const { id: prospectID } = useParams();
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });
  const [prospectData, setProspectData] = React.useState<EditProspectData>(initProspectData);

  const [uploadDocs, setUploadDocs] = React.useState<uploadDocsType>(initDocsUpload);
  const [uploadedDocs, setUploadedDocs] = React.useState<UploadedDocuments>(initialUploadedDocuments);
  const [initAvatar, setInitAvatar] = React.useState<DocType>(emptyDoc);

  const { run: runEdit, data: editResponse, requestStatus: editStatus, error: editError } = useApiRequest({});
  const {
    run: runFetchProspect,
    data: fetchProspectResponse,
    requestStatus: fetchProspectStatus,
    error: fetchProspectError,
  } = useApiRequest({});
  const { run: runUpload, data: uploadResponse, requestStatus: uploadStatus, error: uploadError } = useApiRequest({});

  const getDate = (date) => (date ? date.split("T")[0] : "");

  const fetchProspect = () => runFetchProspect(viewProspectService({ id: prospectID }));

  React.useEffect(() => {
    fetchProspect();
  }, []);

  const prospect = React.useMemo<ProspectInfo>(() => {
    if (fetchProspectResponse) {
      if (fetchProspectResponse.status === 200) {
        const prospect = fetchProspectResponse.data.data;
        const docItems = prospect.suppDocs;

        const general: GeneralInfo = {
          passportPhoto: docItems.passId.url,
          firstName: prospect.firstName,
          middleName: prospect.middleName,
          lastName: prospect.lastName,
          dateofBirth: getDate(prospect.dob),
          gender: prospect.gender,
          maritalStatus: prospect.maritalStatus,
          fathersName: prospect.fName,
          mothersName: prospect.mName,
          nationality: {
            label: getNationality(prospect.nationality),
            value: prospect.nationality,
          },
          passportNo: prospect.passport.number,
          issueDate: getDate(prospect.passport.issueDate),
          expiryDate: getDate(prospect.passport.expiryDate),
          country: {
            label: getCountry(prospect.passport.issueCountry),
            value: prospect.passport.issueCountry,
          },
          birthPlace: prospect.placeOfBirth,
          address: prospect.contactInfo.address,
          city: prospect.contactInfo.city,
          state: prospect.contactInfo.state,
          phone: prospect.contactInfo.phone,
          email: prospect.contactInfo.email,
          countryOfResidence: {
            label: getCountry(prospect.contactInfo.country),
            value: prospect.contactInfo.country,
          },
        };

        const eduHistory: EducationHistory[] = prospect.education.map((edu) => ({
          level: educationHistoryOptions.find((item) => item.value === edu.level),
          institute: edu.insititute,
          program: edu.level === "ssce" ? "nil" : edu.program,
          startDate: getDate(edu.startDate),
          endDate: getDate(edu.endDate),
          studyLanguage: edu.language,
          grade: edu.level === "ssce" ? "nil" : edu.aveGrade,
          maximumGrade: edu.level === "ssce" ? "nil" : edu.maxGrade,
          country: {
            label: getCountry(edu.country),
            value: edu.country,
          },
        }));

        const languages: Language[] = prospect.language.map((lang) => ({
          qualification: languageQualificationOptions.find((item) => item.value === lang.qualification),
          band: lang.band,
          examDate: getDate(lang.examDate),
          readingScore: lang.readingScore,
          listeningScore: lang.listeningScore,
          writingScore: lang.writingScore,
          speakingScore: lang.speakingScore,
        }));

        const passportPhoto = docItems.passId.url
          ? {
              url: docItems.passId.url,
              key: docItems.passId.key,
              filename:
                docItems.passId.filename !== ""
                  ? docItems.passId.filename
                  : "Passport photo" + getExtension(docItems.passId.key),
            }
          : emptyDoc;

        const intlPassport = docItems.passCopy.url
          ? {
              url: docItems.passCopy.url,
              key: docItems.passCopy.key,
              filename:
                docItems.passCopy.filename !== ""
                  ? docItems.passCopy.filename
                  : "Intl. Passport." + getExtension(docItems.passCopy.key),
            }
          : emptyDoc;

        const highSchoolTranscript = docItems.hst.url
          ? {
              url: docItems.hst.url,
              key: docItems.hst.key,
              filename:
                docItems.hst.filename !== ""
                  ? docItems.hst.filename
                  : "High School Transcript." + getExtension(docItems.hst.key),
            }
          : emptyDoc;

        const languageCert = docItems.languageProficiency.url
          ? {
              url: docItems.languageProficiency.url,
              key: docItems.languageProficiency.key,
              filename:
                docItems.languageProficiency.filename !== ""
                  ? docItems.languageProficiency.filename
                  : "Language Certificate." + getExtension(docItems.languageProficiency.key),
            }
          : emptyDoc;

        const workExperience = docItems.cv.url
          ? {
              url: docItems.cv.url,
              key: docItems.cv.key,
              filename:
                docItems.cv.filename !== "" ? docItems.cv.filename : "Work Experience." + getExtension(docItems.cv.key),
            }
          : emptyDoc;

        const previousVisa = docItems.visa.url
          ? {
              url: docItems.visa.url,
              key: docItems.visa.key,
              filename:
                docItems.visa.filename !== ""
                  ? docItems.visa.filename
                  : "Previous Visa." + getExtension(docItems.visa.key),
            }
          : emptyDoc;

        const highSchoolCert = docItems.hsc.url
          ? {
              url: docItems.hsc.url,
              key: docItems.hsc.key,
              filename:
                docItems.hsc.filename !== ""
                  ? docItems.hsc.filename
                  : "High School Certificate." + getExtension(docItems.hsc.key),
            }
          : emptyDoc;

        const statementOfPurpose = docItems.sop.url
          ? {
              url: docItems.sop.url,
              key: docItems.sop.key,
              filename:
                docItems.sop.filename !== ""
                  ? docItems.sop.filename
                  : "Statement Of Purpose." + getExtension(docItems.sop.key),
            }
          : emptyDoc;

        const referenceLetters = docItems.reference.map((item, index) => ({
          url: item.url,
          key: item.key,
          filename: item.filename !== "" ? item.filename : `Reference_${index + 1}.${getExtension(item.key)}`,
        }));

        const others = docItems.others.map((item, index) => ({
          url: item.url,
          key: item.key,
          filename: item.filename !== "" ? item.filename : `Other_Doc_${index + 1}.${getExtension(item.key)}`,
        }));

        const undergradCert = docItems.undergraduateCertificate.url
          ? {
              url: docItems.undergraduateCertificate.url,
              key: docItems.undergraduateCertificate.key,
              filename:
                docItems.undergraduateCertificate.filename !== ""
                  ? docItems.undergraduateCertificate.filename
                  : "Undergrad. Certificate." + getExtension(docItems.undergraduateCertificate.key),
            }
          : emptyDoc;

        const undergradTranscript = docItems.undergraduateTranscript.url
          ? {
              url: docItems.undergraduateTranscript.url,
              key: docItems.undergraduateTranscript.key,
              filename:
                docItems.undergraduateTranscript.filename !== ""
                  ? docItems.undergraduateTranscript.filename
                  : "Undergrad. Transcript." + getExtension(docItems.undergraduateTranscript.key),
            }
          : emptyDoc;

        setInitAvatar(passportPhoto);
        const docs: UploadedDocuments = {
          intlPassport: intlPassport,
          highSchoolTranscript: highSchoolTranscript,
          languageCert: languageCert,
          workExperience: workExperience,
          previousVisa: previousVisa,
          highSchoolCert: highSchoolCert,
          statementOfPurpose: statementOfPurpose,
          referenceLetters: referenceLetters,
          undergradTranscript: undergradTranscript,
          undergradCert: undergradCert,
          others: others,
        };

        setUploadedDocs(docs);

        return {
          generalInfo: general,
          eduHistory,
          languageInfo: languages,
          uploadedDocs: docs,
          initAvatar,
        };
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(uploadResponse, "Unable to fetch prospect information"),
          type: false,
        });
      }
    }
    if (fetchProspectError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(uploadError, "Unable to fetch prospect information"),
        type: false,
      });
    }
    return initProspect;
  }, [fetchProspectResponse, fetchProspectError]);

  const getExtension = (fileName) => {
    return fileName.split(".").at(-1);
  };

  const tooLarge = () => {
    setToast({
      show: true,
      heading: "File size error",
      text: "Failed to attach file greater than 5MB. Please reduce size and try again.",
      type: false,
    });
  };

  const isValidArrayOfObjects = (arr) => {
    return arr.every((item) => {
      return Object.keys(item).every((key) => item[key] !== "");
    });
  };

  const isValidEduArray = (arr) => {
    return arr.every((item) => {
      return Object.keys(item).every((key) =>
        item.level === "ssce"
          ? key === "aveGrade" || key === "program" || key === "maxGrade"
            ? item[key] === ""
            : item[key] !== ""
          : item[key] !== "",
      );
    });
  };

  const submit = ({
    docs,
    general,
    eduHistory,
    languages,
  }: {
    docs: FormData | boolean;
    general: GeneralInfo;
    eduHistory: EducationHistory[];
    languages: Language[];
  }) => {
    const lang = languages.map((item) => ({
      qualification: item.qualification.value,
      band: item.band,
      examDate: item.examDate,
      readingScore: item.readingScore,
      writingScore: item.writingScore,
      speakingScore: item.speakingScore,
      listeningScore: item.listeningScore,
    }));

    const edu = eduHistory.map((item) => ({
      level: item.level.value,
      insititute: item.institute,
      program: item.program === "nil" ? "" : item.program,
      startDate: item.startDate,
      endDate: item.endDate,
      country: item.country.value,
      language: item.studyLanguage,
      aveGrade: item.grade === "nil" ? "" : item.grade,
      maxGrade: item.maximumGrade === "nil" ? "" : item.maximumGrade,
    }));

    const currentDocs: uploadDocsType = {
      passId: initAvatar,
      passCopy: uploadedDocs.intlPassport,
      hst: uploadedDocs.highSchoolTranscript,
      languageProficiency: uploadedDocs.languageCert,
      cv: uploadedDocs.workExperience,
      visa: uploadedDocs.previousVisa,
      hsc: uploadedDocs.highSchoolCert,
      sop: uploadedDocs.statementOfPurpose,
      reference: uploadedDocs.referenceLetters,
      undergraduateTranscript: uploadedDocs.undergradTranscript,
      undergraduateCertificate: uploadedDocs.undergradCert,
      others: uploadDocs.others,
    };

    const data: EditProspectData = {
      firstName: general.firstName,
      lastName: general.lastName,
      middleName: general.middleName,
      dob: general.dateofBirth,
      gender: general.gender,
      maritalStatus: general.maritalStatus,
      fName: general.fathersName,
      mName: general.mothersName,
      nationality: general.nationality.value,
      passport: {
        number: general.passportNo,
        issueDate: general.issueDate,
        expiryDate: general.expiryDate,
        issueCountry: general.country.value,
      },
      placeOfBirth: general.birthPlace,
      countryOfBirth: general.country.value,
      language: isValidArrayOfObjects(lang) ? lang : [],
      contactInfo: {
        address: general.address,
        city: general.city,
        state: general.state,
        country: general.countryOfResidence.value,
        email: general.email,
        phone: general.phone,
      },
      education: isValidEduArray(edu) ? edu : [],
      suppDocs: currentDocs,
    };

    console.log(data, docs);

    if (!docs) {
      editProspect(data);
    } else {
      setProspectData(data);
      runUpload(uploadService(docs));
    }
  };

  const editProspect = (data: EditProspectData) => {
    runEdit(editProspectService({ id: prospectID ?? "", data }));
  };

  React.useMemo(() => {
    if (uploadResponse) {
      if (uploadResponse?.status === 200) {
        const currentDocs: uploadDocsType = {
          passId: initAvatar,
          passCopy: uploadedDocs.intlPassport,
          hst: uploadedDocs.highSchoolTranscript,
          languageProficiency: uploadedDocs.languageCert,
          cv: uploadedDocs.workExperience,
          visa: uploadedDocs.previousVisa,
          hsc: uploadedDocs.highSchoolCert,
          sop: uploadedDocs.statementOfPurpose,
          reference: uploadedDocs.referenceLetters,
          undergraduateTranscript: uploadedDocs.undergradTranscript,
          undergraduateCertificate: uploadedDocs.undergradCert,
          others: uploadDocs.others,
        };

        const data: any[] = uploadResponse.data.data;

        const intlPassport = data.find((item) => /Intl. Passport/.test(item.originalname));
        const previousVisa = data.find((item) => /Previous Visa/.test(item.originalname));
        const sop = data.find((item) => /Statement of Purpose/.test(item.originalname));
        const hsCert = data.find((item) => /High School Certificate/.test(item.originalname));
        const hsTranscript = data.find((item) => /High School Transcript/.test(item.originalname));
        const undegradCert = data.find((item) => /Undergraduate Certificate/.test(item.originalname));
        const undegradTranscript = data.find((item) => /Undergraduate Transcript/.test(item.originalname));
        const languageCert = data.find((item) => /Language Certificate/.test(item.originalname));
        const workExperience = data.find((item) => /Work Experience/.test(item.originalname));
        const reference = data.filter((item) => /VobbRef/.test(item.originalname));
        const others = data.filter((item) => /VobbOther/.test(item.originalname));
        const passportPhoto = data.find((item) => /Passport photo/.test(item.originalname));

        const hasLocation = (doc) => doc?.location && doc?.location !== "";

        const docs: uploadDocsType = {
          passId: hasLocation(passportPhoto)
            ? {
                url: passportPhoto.location,
                key: passportPhoto.key,
                filename: passportPhoto.originalname,
              }
            : currentDocs.passId,
          passCopy: hasLocation(intlPassport)
            ? {
                url: intlPassport.location,
                key: intlPassport.key,
                filename: intlPassport.originalname,
              }
            : currentDocs.passCopy,
          hst: hasLocation(hsTranscript)
            ? {
                url: hsTranscript.location,
                key: hsTranscript.key,
                filename: hsTranscript.originalname,
              }
            : currentDocs.hst,
          languageProficiency: hasLocation(languageCert)
            ? {
                url: languageCert.location,
                key: languageCert.key,
                filename: languageCert.originalname,
              }
            : currentDocs.languageProficiency,
          cv: hasLocation(workExperience)
            ? {
                url: workExperience.location,
                key: workExperience.key,
                filename: workExperience.originalname,
              }
            : currentDocs.cv,
          visa: hasLocation(previousVisa)
            ? {
                url: previousVisa.location,
                key: previousVisa.key,
                filename: previousVisa.originalname,
              }
            : currentDocs.visa,
          hsc: hasLocation(hsCert)
            ? {
                url: hsCert.location,
                key: hsCert.key,
                filename: hsCert.originalname,
              }
            : currentDocs.hsc,
          sop: hasLocation(sop)
            ? {
                url: sop.location,
                key: sop.key,
                filename: sop.originalname,
              }
            : currentDocs.sop,
          reference: [
            ...currentDocs.reference,
            ...reference.map((item) => ({
              url: item.location,
              key: item.key,
              filename: item.originalname,
            })),
          ],
          others: [
            ...currentDocs.others,
            ...others.map((item) => ({
              url: item.location,
              key: item.key,
              filename: item.originalname,
            })),
          ],
          undergraduateTranscript: hasLocation(undegradTranscript)
            ? {
                url: undegradTranscript.location,
                key: undegradTranscript.key,
                filename: undegradTranscript.originalname,
              }
            : currentDocs.undergraduateTranscript,
          undergraduateCertificate: hasLocation(undegradCert)
            ? {
                url: undegradCert.location,
                key: undegradCert.key,
                filename: undegradCert.originalname,
              }
            : currentDocs.undergraduateCertificate,
        };
        setUploadDocs(docs);
        editProspect({ ...prospectData, suppDocs: docs });
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(uploadResponse, "Unable to upload documents"),
          type: false,
        });
      }
    }
    if (uploadError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(uploadError, "Unable to upload documents"),
        type: false,
      });
    }
  }, [uploadResponse, uploadError]);

  React.useMemo(() => {
    if (editResponse) {
      if (editResponse?.status === 200) {
        setToast({
          show: true,
          heading: "Great!",
          text: `${prospect.generalInfo.firstName}'s profile has been updated successfully`,
          type: true,
        });
        fetchProspect();
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(editResponse, "Unable to update student information"),
          type: false,
        });
      }
    }
    if (editError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(editError, "Unable to update student information"),
        type: false,
      });
    }
  }, [editResponse, editError]);

  const deleteDoc = (docs: uploadDocsType) => {
    editProspect({ suppDocs: docs });
  };

  const showLoader = editStatus.isPending || uploadStatus.isPending || fetchProspectStatus.isPending;

  return (
    <>
      <Toast {...toast} closeModal={() => setToast({ ...toast, show: false })} />
      {showLoader ? <Preloader /> : ""}
      {!fetchProspectStatus.isPending && (
        <EditProspectUI prospect={prospect} submit={submit} tooLarge={tooLarge} deleteDoc={deleteDoc} />
      )}
    </>
  );
};

export { EditProspect };

import { CheckIconGreen } from "assets/vectors";
import { Modal } from "components";
import { ModalBody } from "components/Modal/components";
import * as React from "react";
import styles from "./styles.module.css";

interface ToastProps {
  heading: string;
  text: string;
  closeModal: () => void;
  show: boolean;
  type: boolean;
}

const Toast: React.FC<ToastProps> = ({ heading, text, closeModal, show, type }) => {
  return (
    <Modal
      contentClassName={type ? styles["content-green"] : styles["content-red"]}
      show={show}
      onHide={closeModal}
    >
      <ModalBody bodyClassName={styles.body}>
        {type ? (
          <CheckIconGreen className={styles["v-toast-img"]} title="check" />
        ) : (
          <div className={styles["v-toast-red-icon"]}>&times;</div>
        )}
        <div>
          <h4 className={styles["v-toast-ttl"]}>{heading}</h4>
          <p className={styles["v-toast-txt"]}>{text}</p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export { Toast };

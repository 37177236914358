import {
  AdminDashBoardTable,
  AllCustomTableClasses,
  FilterBox,
  PageHeader,
  SignupRequestItem,
  SignupRequestTableBody,
} from "components";
import { TableHeaderItemProps } from "components/table/components";
import React from "react";
import { EmptyTableBody } from "./components";
import styles from "./styles.module.css";
import { SearchIcon } from "assets";
import { sourceOptions } from "Helper/options";
import { optionType } from "types";
import config from "config.json";

// UI Props
interface IProps {
  tableBodyItems: SignupRequestItem[];
  count: number;
  viewSignup: (id: string) => void;
  sourceFilter: {
    value: optionType;
    control: (x) => void;
  };
  handleSearch: (x: string) => void;
  currentPage: number;
}

// UI meant for the signup requests agents page
const SignupRequestUI: React.FC<IProps> = ({
  tableBodyItems,
  count,
  viewSignup,
  sourceFilter,
  handleSearch,
  currentPage,
}) => {
  const [search, setSearch] = React.useState("");

  // Table header titles
  const tableHeaderTitles: TableHeaderItemProps[] = [
    { title: "S/N" },
    { title: "Agency Name" },
    { title: "Representative" },
    { title: "Email" },
    { title: "Phone" },
    { title: "Date Created" },
    { title: "Action" },
  ];

  // All custom Table Classes
  const customTableClasses: AllCustomTableClasses = {
    tableWrapperClass: styles.tableWrapperClass,
    tableContainerClassName: styles.tableContainerClass,
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItem,
  };

  const handleChange = (e) => {
    if (e.target.value === "") {
      handleSearch("");
    }
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    setSearch("");
  }, []);

  return (
    <React.Fragment>
      <PageHeader
        title="Signup Requests"
        description="The table below represents a list of signups awaiting admin approval."
        count={count}
      />
      <div className={styles.searchFilterWrapper}>
        <div className={styles.searchInputWrapper}>
          <SearchIcon />
          <input
            className={styles.searchInput}
            value={search}
            onChange={handleChange}
            type="search"
            placeholder="Search by email, agency or representative name"
            onKeyPress={(e) => {
              if (e.key === "Enter") handleSearch(search);
            }}
          />
        </div>
        <div className={styles.filterContainer}>
          {!config.whitelabel ? (
            <FilterBox
              onChange={(x) => sourceFilter.control(x)}
              validatorMessage={""}
              inputClass={styles.select}
              name={"source"}
              placeholder={"Select..."}
              label={"Source"}
              options={[{ label: "All", value: "" }, ...sourceOptions]}
              value={sourceFilter.value}
              parentClassName={`${styles.dropdownWrap}`}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <AdminDashBoardTable
        tableHeaderTitles={tableHeaderTitles}
        customTableClasses={{ ...customTableClasses }} // custom class object
        EmptyTableElement={<EmptyTableBody />} // UI for empty table body
        showEmptyTableElement={tableBodyItems?.length < 1} //Check to show an empty agents body
        AdminTableBody={
          <SignupRequestTableBody
            tableBodyClassName={styles.tableBodyClass}
            tableBodyItems={tableBodyItems}
            handleView={viewSignup}
            currentPage={currentPage}
          />
        }
      />
    </React.Fragment>
  );
};

export { SignupRequestUI };

import { CaretLeftBlue, EditIcon, SearchIcon, avatarImg } from "assets";
import {
  AdminDashBoardTable,
  AllCustomTableClasses,
  Pagination,
  AgentProspectsTableBody,
  AgentProspectsTableBodyItem,
  AgentApplicationsTableBody,
  AgentApplicationsTableBodyItem,
} from "components";
import { TableHeaderItemProps } from "components/table/components";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

export interface AgentData {
  agencyName: string;
  repName: string;
  avatar: string;
  email: string;
  phone: string;
  website: string;
  address: string;
  teamSize: string;
  studentsPerMonth: string;
  stats: {
    prospects: number;
    applications: number;
    admissions: number;
  };
  bankInfo: {
    holderName: string;
    bankName: string;
    iban: string;
    holderAddress: string;
    country: string;
  };
}

interface InstitutionProps {
  agent: AgentData;
  prospectList: AgentProspectsTableBodyItem[];
  applicationList: AgentApplicationsTableBodyItem[];
  handleViewProspect: (id: string) => void;
  pageInfo: {
    prospects: {
      count: number;
      totalPages: number;
      updateCurrentPage: (x) => void;
      currentPage: number;
    };
    applications: {
      count: number;
      totalPages: number;
      updateCurrentPage: (x) => void;
      currentPage: number;
    };
  };
  handleSearchProspect: (x: string) => void;
  handleSearchApplication: (x: string) => void;
  editAgent: () => void;
  handleEditProspect: (id: string) => void;
  handleViewComments: (id: string) => void;
}

const AgentUI: React.FC<InstitutionProps> = ({
  prospectList,
  applicationList,
  agent,
  handleViewProspect,
  pageInfo,
  editAgent,
  handleEditProspect,
  handleViewComments,
  handleSearchApplication,
  handleSearchProspect,
}) => {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");
  const [view, setView] = React.useState<number>(1);

  // This  is to show how to use the table component
  const tableHeaderTitlesProspects: TableHeaderItemProps[] = [
    { title: "S/N" },
    { title: "Prospect" },
    { title: "Plan" },
    { title: "Status" },
    { title: "Date Created" },
    { title: "" },
  ];

  const tableHeaderTitlesApplication: TableHeaderItemProps[] = [
    { title: "S/N" },
    { title: "Prospect" },
    { title: "University" },
    { title: "Program" },
    { title: "Status" },
    { title: "Date Created" },
  ];

  // Custom table Classes
  const CustomTableClassesProspects: AllCustomTableClasses = {
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItemProspect,
    tableWrapperClass: styles.tableWrapperClass,
  };

  const CustomTableClassesApplications: AllCustomTableClasses = {
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItemApplication,
    tableWrapperClass: styles.tableWrapperClass,
  };

  const handleChange = (e) => {
    if (e.target.value === "") {
      view === 1 ? handleSearchProspect("") : handleSearchApplication("");
    }
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    setSearch("");
  }, [view]);

  const selectBoxStyles = {
    background: "transparent",
    color: "inherit",
    fontWeight: "500",
    minWidth: "unset",
    border: "none",
    padding: "8px 10px",
    borderRadius: "8px",
    fontSize: "13px",
    paddingRight: "2rem",
    width: "140px",
    justifyContent: "flex-end",
  };

  const [copied, setCopied] = React.useState(false);
  const handleCopyID = (id) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3500);
      })
      .catch((err) => {
        // Copy manually
      });
  };

  return (
    <section>
      <button onClick={() => navigate(-1)} className={styles.backBtn}>
        <CaretLeftBlue /> Back
      </button>

      <div className={styles.headerSec}>
        <div className={styles.ttl}>
          <img src={agent.avatar !== "" ? agent.avatar : avatarImg} alt="logo" />
          <span>
            <h1>{agent.agencyName}</h1>
            <p> {agent.repName}</p>
          </span>
          <EditIcon onClick={editAgent} role="button" className={styles.editBtn} />
        </div>

        <div className={styles.stats}>
          <div className={styles.statCard}>
            <p>Admissions</p>
            <p>{agent.stats.admissions}</p>
          </div>
          {/* <div className={styles.statCard}>
            <p>Enrollments</p>
            <p>101</p>
          </div> */}
        </div>

        <div className={styles.accountInfo}>
          <p className={styles.acctInfoTtl}>Account Information</p>
          <div className={styles.accountInfoWrap}>
            <div>
              <a target={"_blank"} rel="noreferrer" className={styles.website} href={`mailto:${agent.email}`}>
                {agent.email}
              </a>
              <p className={styles.label}>Email</p>
            </div>
            <div>
              <p className={styles.value}>{agent.phone}</p>
              <p className={styles.label}>Phone Number</p>
            </div>
            <div>
              <a target={"_blank"} rel="noreferrer" className={styles.website} href={agent.website}>
                {agent.website}
              </a>
              <p className={styles.label}>Website</p>
            </div>
            <div>
              <p className={styles.value}> {agent.address}</p>
              <p className={styles.label}>Address</p>
            </div>
            <div>
              <p className={styles.value}> {agent.teamSize}</p>
              <p className={styles.label}>Team Size</p>
            </div>
            <div>
              <p className={styles.value}> {agent.studentsPerMonth}</p>
              <p className={styles.label}>Students per month</p>
            </div>
          </div>
        </div>

        <div className={styles.accountInfo}>
          <p className={styles.acctInfoTtl}>Bank Information</p>
          <div className={styles.accountInfoWrap}>
            <div>
              <p className={styles.value}>{agent.bankInfo.bankName}</p>
              <p className={styles.label}>Bank Name</p>
            </div>
            <div>
              <p className={styles.value}> {agent.bankInfo.iban}</p>
              <p className={styles.label}>IBAN</p>
            </div>
            <div>
              <p className={styles.value}>{agent.bankInfo.holderName ?? "---"}</p>
              <p className={styles.label}>Holder Name</p>
            </div>
            <div>
              <p className={styles.value}> {agent.bankInfo.holderAddress}</p>
              <p className={styles.label}>Holder Address</p>
            </div>
            <div>
              <p className={styles.value}> {agent.bankInfo.country}</p>
              <p className={styles.label}>Holder Country</p>
            </div>
          </div>
        </div>
      </div>
      <nav className={styles.nav}>
        <p className={view === 1 ? styles.activeNav : ""} role={"button"} onClick={() => setView(1)}>
          <span>{pageInfo.prospects.count}</span>
          Prospects
        </p>
        <p className={view === 2 ? styles.activeNav : ""} role={"button"} onClick={() => setView(2)}>
          <span>{pageInfo.applications.count}</span>
          Applications
        </p>
      </nav>
      <div className={styles.tableExtra}>
        <div className={styles.searchFilterWrapper}>
          <div className={styles.searchInputWrapper}>
            <SearchIcon />
            <input
              className={styles.searchInput}
              value={search}
              onChange={handleChange}
              type="search"
              placeholder="Search..."
              onKeyPress={(e) => {
                if (e.key === "Enter") view === 1 ? handleSearchProspect(search) : handleSearchApplication(search);
              }}
            />
          </div>
        </div>
      </div>
      {view === 2 ? (
        <>
          {applicationList.length > 0 && (
            <AdminDashBoardTable
              tableHeaderTitles={tableHeaderTitlesApplication}
              customTableClasses={CustomTableClassesApplications}
              AdminTableBody={
                <AgentApplicationsTableBody
                  tableBodyClassName={styles.tableBodyClassApplication}
                  tableBodyItems={applicationList}
                  currentPage={pageInfo.applications.currentPage}
                />
              }
            />
          )}
          <Pagination
            currentPage={pageInfo.applications.currentPage}
            pages={pageInfo.applications.totalPages}
            handlePage={pageInfo.applications.updateCurrentPage}
          />
        </>
      ) : (
        <>
          {prospectList.length > 0 && (
            <AdminDashBoardTable
              tableHeaderTitles={tableHeaderTitlesProspects}
              customTableClasses={CustomTableClassesProspects}
              AdminTableBody={
                <AgentProspectsTableBody
                  currentPage={pageInfo.prospects.currentPage}
                  handleView={handleViewProspect}
                  tableBodyClassName={styles.tableBodyClassProspect}
                  tableBodyItems={prospectList}
                  handleEdit={handleEditProspect}
                  handleViewComments={handleViewComments}
                />
              }
            />
          )}
          <Pagination
            currentPage={pageInfo.prospects.currentPage}
            pages={pageInfo.prospects.totalPages}
            handlePage={pageInfo.prospects.updateCurrentPage}
          />
        </>
      )}
    </section>
  );
};

export { AgentUI };
export * from "./editAgent";

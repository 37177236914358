import { Modal, UnVerifiedAgencyItem } from "components";
import React from "react";
import styles from "./styles.module.css";

// Modal Props
interface IProps {
  agentToBeVerified: UnVerifiedAgencyItem | null;
  handleSubmit: () => void;
  handleCancel: () => void;
}

const VerifyAgentsModal: React.FC<IProps> = ({ agentToBeVerified, handleSubmit, handleCancel }) => {
  return (
    <Modal show={true} onHide={() => {}}>
      <section className={styles.modalContainer}>
        <h2>
          Are you sure you want to send verification email to <span>{agentToBeVerified?.agentName}</span>? This action
          cannot be undone.
        </h2>
        <aside className={styles.modalBtnContainer}>
          <button className={styles.deleteBtn} onClick={handleSubmit}>
            Confirm
          </button>
          <button className={styles.cancelBtn} onClick={handleCancel}>
            Cancel
          </button>
        </aside>
      </section>
    </Modal>
  );
};

// Modal Props
interface IDeleteProps {
  agentToBeDeleted: UnVerifiedAgencyItem | null;
  handleSubmit: () => void;
  handleCancel: () => void;
}

const DeleteUnverifiedAgentsModal: React.FC<IDeleteProps> = ({ agentToBeDeleted, handleSubmit, handleCancel }) => {
  return (
    <Modal show={true} onHide={() => {}}>
      <section className={styles.modalContainer}>
        <h2>
          Are you sure you want to delete <span>{agentToBeDeleted?.agentName}</span>? This action cannot be undone.
        </h2>
        <aside className={styles.modalBtnContainer}>
          <button className={styles.deleteBtn} onClick={handleSubmit}>
            Confirm
          </button>
          <button className={styles.cancelBtn} onClick={handleCancel}>
            Cancel
          </button>
        </aside>
      </section>
    </Modal>
  );
};

export { VerifyAgentsModal, DeleteUnverifiedAgentsModal };

import React from "react";
import { MainRouter } from "routes";

const App = () => {
  return (
    <div className="App">
      <MainRouter />
    </div>
  );
};

export default App;

import { EyeIcon, EyeOpenIcon } from "assets/vectors";
import React from "react";
import { TableRowItem } from "../../components";
import { AdminTableBody } from "../../components/tbody";
import { TableDataItem } from "../../components/td";
import styles from "./styles.module.css";

// University Verification Table Body Item
export interface ApprovedSignupRequestItem {
  agencyName: string;
  representativeName: string;
  emailAddress: string;
  phone: string;
  agencyId: string;
  dateCreated: string;
}

// University Verification Table Body Props
interface TableBodyProps {
  tableBodyItems: ApprovedSignupRequestItem[];
  handleView: (id: string) => void;
  tableBodyClassName: string;
  currentPage: number;
}

const ApprovedSignupRequestTableBody: React.FC<TableBodyProps> = ({
  tableBodyItems,
  handleView,
  tableBodyClassName,
  currentPage,
}) => {
  return (
    <AdminTableBody customClassName={styles.tableContainer}>
      {tableBodyItems.map((item, idx) => (
        <TableRowItem key={`${item.agencyName} ${idx}`} customClassName={styles.tableRow}>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {(currentPage - 1) * 10 + idx + 1}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.agencyName}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {item.representativeName}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName} ${styles.email}`}>{item.emailAddress}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.phone}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.dateCreated}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem}`}>
            <button className={styles.viewBtn} onClick={() => handleView(item.agencyId)}>
              <EyeOpenIcon className={styles.fillEye} /> <EyeIcon className={styles.outlineEye} /> View
            </button>
          </TableDataItem>
        </TableRowItem>
      ))}
    </AdminTableBody>
  );
};

export { ApprovedSignupRequestTableBody };

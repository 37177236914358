import { appendParams } from "Helper/appendParams";

// Environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL;

/*
=================================
STUDENT APPLICATION URLS
=================================
*/

/**
 * Application url
 *
 * @returns url string
 *
 */

export const fetchProspectsURL = (pageNo, agent, search, plan, status, source, actionRequired) =>
  `${API_URL}/admin/view-all-prospects?${appendParams({
    search: search,
    pageNo: pageNo,
    agent: agent,
    paymentPlan: plan,
    noOfRequests: 10,
    status: status,
    source,
    actionRequired,
  })}`;

export const viewProspectURL = (id) => `${API_URL}/admin/view-an-prospect/${id}`;

export const updateProspectStatusURL = (id) => `${API_URL}/update-prospect-profile-status/${id}`;

export const fetchProspectApplicationsURL = (id) => `${API_URL}/admin/list-applications-for-prospect/${id}`;

export const fetchProspectCommentURL = (id) => `${API_URL}/fetch-prospect-comments/${id}`;

export const createProspectCommentURL = (id) => `${API_URL}/create-comment-on-prospect/${id}`;

export const editProspectCommentURL = (id, commentID) =>
  `${API_URL}/edit-prospect-comments/${id}?commentID=${commentID}`;

export const deleteProspectCommentURL = (id, commentID) =>
  `${API_URL}/delete-prospect-comments/${id}?commentID=${commentID}`;

export const updateProspectDocsURL = (id) => `${API_URL}/edit-prospect-profile-docs/${id}`;

export const fetchAgentsUnpaginatedURL = () => `${API_URL}/admin/list-agent-unpaginated`;

export const updateProspectPlanURL = (id) => `${API_URL}/admin/update-enterprise-plan/${id}`;

export const editProspectURL = (id) => `${API_URL}/admin/edit-a-prospect/${id}`;

export const createProspectURL = (id) => `${API_URL}/admin/create-agent-prospect/${id}`;

import * as React from "react";
import styles from "./styles.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { AddIcon, WarningIcon } from "assets";
import { optionType } from "types";
import { CustomSelect } from "components";

interface AgentData {
  agent: optionType[];
}

const initialValues: AgentData = {
  agent: [],
};

const schema = yup
  .object({
    agent: yup.array().min(1),
  })
  .required();

interface AddInstitutionProps {
  show: boolean;
  close: () => void;
  submit: (data) => void;
  agentList: optionType[];
}

const AddAgentUI: React.FC<AddInstitutionProps> = ({ show, close, submit, agentList }) => {
  const [agents, setAgents] = React.useState<optionType[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch: watch,
    setValue,
    reset,
  } = useForm<AgentData>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  React.useEffect(() => {
    if (!show) closeForm();
  }, [show]);

  const removeAgent = (id) => {
    const index = agents.findIndex((item) => item.value === id);
    if (index >= 0) {
      agents.splice(index, 1);
      setAgents([...agents]);
      setValue("agent", [...agents]);
    }
  };

  const onSubmit: SubmitHandler<AgentData> = (data) => {
    const submitData = {
      agents: agents.map((item) => item.value),
    };
    submit(submitData);
  };

  const closeForm = () => {
    reset();
    close();
    setAgents([]);
  };
  return (
    <aside className={`${styles.wrapper} ${show ? styles.show : styles.hide}`}>
      <AddIcon
        className={`${styles.closeBtn} ${show ? styles.showIcon : styles.hideIcon}`}
        role="button"
        onClick={closeForm}
      />
      <h1 className={styles.ttl}>Add a new Agent</h1>
      <p className={styles.txt}>Complete the form below to add a new agent</p>

      <form className={styles.form}>
        <CustomSelect
          onChange={(x) => {
            const index = agents.findIndex((item) => item.value === x.value);
            if (index < 0) {
              setValue("agent", [x, ...agents]);
              setAgents((prev) => [x, ...prev]);
            }
          }}
          validatorMessage={watch("agent").length === 0 ? errors.agent?.message ?? "" : ""}
          inputClass={styles.select}
          name={"agent"}
          placeholder={"Select..."}
          label={"Agent"}
          options={agentList}
          value={{ label: "", value: "" }}
          parentClassName={styles.inputWrap}
        />
        <div className={styles.agentList}>
          {agents.length > 0 &&
            agents.map((item, index) => (
              <span key={index} className={styles.agent}>
                {item.label} <AddIcon onClick={() => removeAgent(item.value)} role={"button"} />
              </span>
            ))}
        </div>
        <button className={styles.submitBtn} onClick={handleSubmit(onSubmit)}>
          Submit
        </button>
      </form>
    </aside>
  );
};

export { AddAgentUI };

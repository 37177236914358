// Environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL;

/*
=================================
SUPPORT PAGE URLs
=================================
*/

export type SupportMessageType = "resolved" | "pending" | "unattended";

export interface SupportMessageParams {
  pageNo: string | number;
  messageCount?: number | string;
  messageType: SupportMessageType;
}

export const getAllSupportMessageURL = ({ pageNo, messageCount = "", messageType }: SupportMessageParams) =>
  `${API_URL}/admin/support/messages?pageNo=${pageNo}&noOfmessages=${messageCount}&messageType=${messageType}`;

export const getUpdateSupportMsgTypeURL = ({ messageId, messageType }) =>
  `${API_URL}/admin/support/message/${messageId}/update?messageType=${messageType}`;

export const getDelSupportMsgURL = ({ messageId }: { messageId: string }) =>
  `${API_URL}/admin/support/message/${messageId}/delete`;

import { Modal } from "components";
import { BlogsTableBodyItem } from "components/table/tableBodyVariants";
import verifiedAgentsStyles from "../verifiedAgents/styles.module.css";
import styles from "./styles.module.css";
import * as React from "react";
import { CameraIcon, TickIcon, WarningIcon } from "assets/vectors";

// Shared Modal Props
interface ModalProps {
  Blog: BlogsTableBodyItem;
  closeModal: () => void;
  editBlog: (id: string, data) => void;
  deleteBlog: (id: string) => void;
  show: boolean;
  handleImageAsyncUpload: (data) => void;
  serverUrl: string;
  serverError: string;
  clearServerUrl: () => void;
  success: boolean;
}

// Support Message Modal
const BlogModal: React.FC<ModalProps> = ({
  Blog,
  closeModal,
  show,
  editBlog,
  deleteBlog,
  handleImageAsyncUpload,
  serverUrl,
  serverError,
  clearServerUrl,
  success,
}) => {
  const [edit, setEdit] = React.useState(false);
  const [formFields, setFormFields] = React.useState({
    title: Blog.title,
    description: Blog.description,
    link: Blog.link,
  });
  const [blogImage, setBlogImage] = React.useState({ nameUrl: "", file: "" });
  const [message, setMessage] = React.useState("");

  React.useEffect(() => {
    setFormFields({
      title: Blog.title,
      description: Blog.description,
      link: Blog.link,
    });
    setMessage("");
  }, [edit === true]);

  React.useEffect(() => {
    setEdit(false);
    setFormFields({
      title: Blog.title,
      description: Blog.description,
      link: Blog.link,
    });
  }, [success]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setBlogImage({ file, nameUrl: URL?.createObjectURL(file) });
    clearServerUrl();
  };

  const handleAsyncImageUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("files", blogImage?.file);
    handleImageAsyncUpload(formData);
    setMessage("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMessage("");

    setFormFields({
      ...formFields,
      [name]: value,
    });
  };

  const updateBlog = () => {
    const { title, description, link } = formFields;
    let count = 0;
    console.log(formFields);
    for (const [key, value] of Object.entries(formFields)) {
      if (formFields[key] === Blog[key]) {
        count++;
      }
    }

    const urlCheck = new RegExp(/(http|https):\/\/(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}/);

    if (!urlCheck.test(link)) {
      setMessage("Please enter a valid url");
      return;
    }

    if (count === 3 && serverUrl === "") {
      setMessage("No change to blog details detected");
    } else if (!formFields.description || !formFields.link || !formFields.title) {
      setMessage("Can't update blog with empty fields");
    } else
      editBlog(Blog.id, {
        title,
        description,
        blog_link: link,
        image: serverUrl === "" ? Blog.image : serverUrl,
      });
  };

  const cancel = () => {
    closeModal();
    setEdit(false);
    setBlogImage({ nameUrl: "", file: "" });
    setMessage("");
    setFormFields({
      title: "",
      description: "",
      link: "",
    });
  };

  return (
    <Modal show={show}>
      <section className={`${verifiedAgentsStyles.modalContainer} ${styles.modalContainer} ${edit ? styles.edit : ""}`}>
        <p className={styles.date}>{Blog?.date.split("T")[0]}</p>
        <div className={styles.image}>
          {edit ? (
            <>
              {blogImage?.nameUrl && !serverUrl ? (
                <WarningIcon className={`${styles["verificationIcon"]} ${styles["warningIcon"]}`} />
              ) : blogImage?.nameUrl && serverUrl ? (
                <TickIcon className={`${styles["verificationIcon"]} ${styles["tickIcon"]}`} />
              ) : (
                ""
              )}
              <label role="button" htmlFor="image">
                <CameraIcon className={styles.cameraIcon} title="camera" />
              </label>
            </>
          ) : (
            ""
          )}
          <img src={!(edit && blogImage?.nameUrl) ? Blog?.image : blogImage.nameUrl} alt={Blog?.title} />
          <input
            id="image"
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            onChange={handleImageUpload}
            style={{ display: "none" }}
          />
        </div>
        {edit && blogImage.nameUrl && (
          <button
            className={verifiedAgentsStyles.cancelBtn}
            disabled={!blogImage?.nameUrl || serverUrl !== ""}
            onClick={handleAsyncImageUpload}
            style={{ marginLeft: "0", marginBottom: "1.2rem" }}
          >
            Upload Image
          </button>
        )}
        <div className={styles.messageTitle}>
          <h3>Title: </h3>
          <input
            value={edit ? formFields.title : Blog.title}
            readOnly={!edit}
            placeholder="Title"
            type="text"
            name="title"
            onChange={handleChange}
          />
        </div>
        <div className={styles.messageBody}>
          <h3>Description: </h3>
          <textarea
            value={edit ? formFields.description : Blog.description}
            placeholder="Short description"
            name="description"
            readOnly={!edit}
            onChange={handleChange}
          />
        </div>
        <div className={styles.link}>
          <h3>Link: </h3>
          {!edit ? (
            <a>{Blog?.link}</a>
          ) : (
            <input
              placeholder="Link"
              type="url"
              name="link"
              value={edit ? formFields.link : Blog.link}
              onChange={handleChange}
            />
          )}
        </div>
        <div className={verifiedAgentsStyles.modalBtnContainer} style={{ marginTop: "1.5rem", width: "100%" }}>
          <button
            className={`${!edit ? verifiedAgentsStyles.cancelBtn : verifiedAgentsStyles.deleteBtn}`}
            onClick={() => setEdit(!edit)}
            style={{ marginRight: "1rem", marginLeft: "0" }}
          >
            {edit ? "Cancel" : "Edit"}
          </button>
          <button
            className={`${edit ? verifiedAgentsStyles.cancelBtn : verifiedAgentsStyles.deleteBtn}`}
            onClick={() => {
              if (!edit) deleteBlog(Blog.id);
              else updateBlog();
            }}
          >
            {edit ? "Update" : "Delete"}
          </button>
          <button
            className={verifiedAgentsStyles.cancelBtn}
            onClick={cancel}
            style={{ marginLeft: "auto", background: "#000" }}
          >
            Close
          </button>
        </div>
        <p className={styles.message}>{message}</p>
        <p className={styles.error}>{serverError}</p>
      </section>
    </Modal>
  );
};

export { BlogModal };

import { EditIcon, EyeIcon, EyeOpenIcon } from "assets";
import React from "react";
import { TableRowItem } from "../../components";
import { AdminTableBody } from "../../components/tbody";
import { TableDataItem } from "../../components/td";
import styles from "./styles.module.css";

// University Prospects Table Body Item
export interface AgentProspectsTableBodyItem {
  id: string;
  avatar: string;
  name: string;
  plan: string;
  status: string;
  nationality: string;
  dateCreated: string;
  commentCount: number;
}

// University Prospects Table Body Props
interface TableBodyProps {
  tableBodyItems: AgentProspectsTableBodyItem[];
  tableBodyClassName: string;
  handleView: (id: string) => void;
  handleViewComments: (id: string) => void;
  handleEdit: (id: string) => void;
  currentPage: number;
}

const AgentProspectsTableBody: React.FC<TableBodyProps> = ({
  tableBodyItems,
  tableBodyClassName,
  handleView,
  currentPage,
  handleEdit,
  handleViewComments,
}) => {
  return (
    <AdminTableBody customClassName={`${styles.tableContainer} `}>
      {tableBodyItems.map((item, idx) => (
        <TableRowItem key={`${item.name} ${idx}`} customClassName={styles.tableRow}>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {(currentPage - 1) * 10 + idx + 1}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName} ${styles.uniName}`}>
            <img className={styles.logo} src={item.avatar} />{" "}
            <div>
              <p onClick={() => handleView(item.id)} role="button">
                {item.name}
              </p>
              <p className={styles.subText}>{item.nationality}</p>
            </div>
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.plan}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName} ${styles.status} `}>
            {item.status}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName} ${styles.status} `}>
            {item.dateCreated}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName} ${styles.CTAWrap}`}>
            <EditIcon onClick={() => handleEdit(item.id)} className={styles.editBtn} role="button" />
            <button className={styles.viewBtn} onClick={() => handleView(item.id)}>
              <EyeOpenIcon className={styles.fillEye} /> <EyeIcon className={styles.outlineEye} />
            </button>

            {item.commentCount ? (
              <div onClick={() => handleViewComments(item.id)} role="button" className={styles.commentCount}>
                {item.commentCount} <div className={styles.commentCountDescrip}>{item.commentCount} new comment(s)</div>
              </div>
            ) : (
              ""
            )}
          </TableDataItem>
        </TableRowItem>
      ))}
    </AdminTableBody>
  );
};

export { AgentProspectsTableBody };

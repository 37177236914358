import { AdminDashboardLayout, SideBarProps } from "components";
import {
  AdminLogin,
  AdminSupportPage,
  Agent,
  ApprovedSignupRequests,
  Blogs,
  Commissions,
  CreateProspect,
  EditProspect,
  Institution,
  Institutions,
  Overview,
  Prospects,
  SignupRequests,
  UniversityVerification,
  UnverifiedAgents,
  VerifiedAgents,
  ViewProspect,
  ViewUniversities,
} from "pages";
import { Applications } from "pages/Applications";
import { SpecialOffers } from "pages/SpecialOffers";
import { RouteProps } from "react-router-dom";
import { Routes } from "./routes";
import { ActionRequired } from "pages/ActionRequired";

// Route Builder Item Props
export interface RouteBuilderItem extends RouteProps {
  Layout?: React.FC<any>; // If you wish to add a layout to the page
  Element: React.FC<unknown>;
  props?: SideBarProps;
}

/**
 * ADMIN ROUTE BUILDER
 *
 * ===============================================
 *
 * This is a list of all our application routes.
 *
 * A single item on this list contains the necessary Route props needed by React Router to do it's job.
 *
 * If you wish to add a new route to the application,
 * just fulfill all the necessary props needed by the RouteBuilder item. Ciao!
 *
 */

export const RouteBuilder: RouteBuilderItem[] = [
  {
    path: Routes.home,
    Element: AdminLogin,
    caseSensitive: true,
  },
  {
    path: Routes.uniVerification,
    Element: UniversityVerification,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "University Verification",
    },
  },
  {
    path: Routes.singleUniVerification,
    Element: ViewUniversities,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "University Verification",
    },
  },
  {
    path: Routes.verifiedAgents,
    Element: VerifiedAgents,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Verified Agents",
    },
  },
  {
    path: Routes.unverifiedAgents,
    Element: UnverifiedAgents,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Unverified Agents",
    },
  },
  {
    path: Routes.support,
    Element: AdminSupportPage,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Support",
    },
  },
  {
    path: Routes.applications,
    Element: Applications,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Applications",
    },
  },
  {
    path: Routes.specialOffers,
    Element: SpecialOffers,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Special Offers",
    },
  },
  {
    path: Routes.blogs,
    Element: Blogs,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Blogs",
    },
  },
  {
    path: Routes.institutions,
    Element: Institutions,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Institutions",
    },
  },
  {
    path: Routes.institution,
    Element: Institution,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Institutions",
    },
  },
  {
    path: Routes.signupRequests,
    Element: SignupRequests,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Signup Requests",
    },
  },
  {
    path: Routes.approvedSignups,
    Element: ApprovedSignupRequests,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Approved Signups",
    },
  },
  {
    path: Routes.prospects,
    Element: Prospects,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Prospects",
    },
  },
  {
    path: Routes.prospect,
    Element: ViewProspect,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Prospects",
    },
  },
  {
    path: Routes.overview,
    Element: Overview,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Overview",
    },
  },
  {
    path: Routes.actionRequired,
    Element: ActionRequired,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Action Required",
    },
  },
  {
    path: Routes.createProspect,
    Element: CreateProspect,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Prospects",
    },
  },
  {
    path: Routes.editProspect,
    Element: EditProspect,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Prospects",
    },
  },
  {
    path: Routes.agent,
    Element: Agent,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Approved Signups",
    },
  },
  {
    path: Routes.commissions,
    Element: Commissions,
    caseSensitive: true,
    Layout: AdminDashboardLayout,
    props: {
      active: "Commissions",
    },
  },
];

import { TableDataItem, TableRowItem } from "components/table/components";
import { AdminTableBody } from "components/table/components/tbody";
import { getCountry } from "Helper/country";
import React from "react";
import styles from "./styles.module.css";

// University Verification Table Body Item
export interface UniversityTableBodyItem {
  universityName: string;
  universityID: string;
  requestID: string;
  country: string;
  commissionReceived: string;
  commissionPaid: string;
  status: string;
}

// University Verification Table Body Props
interface TableBodyProps {
  tableBodyItems: UniversityTableBodyItem[];
  tableBodyClassName: string;
  handleUpdate: (university_id: string, request_id: string, status: string) => void;
}

const UniversityTableBody: React.FC<TableBodyProps> = ({ tableBodyItems, tableBodyClassName, handleUpdate }) => {
  return (
    <AdminTableBody customClassName={`${styles.tableContainer} `}>
      {tableBodyItems.map((item, idx) => (
        <TableRowItem key={`${item.universityName} ${idx}`} customClassName={styles.tableRow}>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {item.universityName}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{getCountry(item.country)}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {item.commissionReceived}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {item.commissionPaid}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {item.status === "pending" ? (
              <>
                <button
                  onClick={() => handleUpdate(item.universityID, item.requestID, "accepted")}
                  className={styles.verifyBtn}
                >
                  Accept
                </button>{" "}
                <button
                  onClick={() => handleUpdate(item.universityID, item.requestID, "denied")}
                  className={styles.verifyBtn}
                >
                  Reject
                </button>
              </>
            ) : (
              ""
            )}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            <p className={`${styles[item.status]} ${styles.status}`}>{item.status}</p>
          </TableDataItem>
        </TableRowItem>
      ))}
    </AdminTableBody>
  );
};

export { UniversityTableBody };

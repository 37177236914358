// Environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL;

/*
=================================
AUTH URLS
=================================
*/

/**
 * Create blog url
 *
 * @returns url string
 *
 */

export const createBlogURL = () => `${API_URL}/admin/blogs/new`;

export const getAllBlogsURL = ({pageNo}) => `${API_URL}/admin/blogs/all?pageNo=${pageNo}&noOfRequests=10`;

export const getSingleBlogURL = ({blogID}) => `${API_URL}/admin/blog/${blogID}`;

export const deleteBlogURL = ({blogID}) => `${API_URL}/admin/blog/${blogID}/delete`;

export const editBlogURL = ({blogID}) => `${API_URL}/admin/blog/${blogID}/edit`;

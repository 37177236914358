import { DropDownItem } from "components";
import { optionType } from "types";
import { countries } from "country-flags-svg";

export const degreeOptions: optionType[] = [
  { value: "Undergraduate", label: "Undergraduate" },
  { value: "Masters", label: "Masters" },
  { value: "PhD", label: "PhD" },
  { value: "Diploma", label: "Diploma" },
  { value: "Foundational", label: "Foundational" },
];

export const currencyOptions: optionType[] = [
  {
    label: "AUD",
    value: "AUD",
  },
  {
    label: "CAD",
    value: "CAD",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "GBP",
    value: "GBP",
  },
  {
    label: "USD",
    value: "USD",
  },
];

export const attendanceOptions: optionType[] = [
  {
    label: "Onsite",
    value: "on-campus",
  },
  {
    label: "Online",
    value: "off-campus",
  },
];

export const initialOptionType: optionType = {
  label: "",
  value: "",
};

const year = new Date().getFullYear();
const years = Array.from(new Array(20), (val, index) => index + year);
export const yearOptions: optionType[] = years.map((item) => ({
  value: String(item),
  label: String(item),
}));

export const months: optionType[] = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

export const getMonthOptions = (year): optionType[] => {
  let monthOptions: optionType[] = [];

  if (`${new Date().getFullYear()}` === year) {
    monthOptions = months.filter((item, index) => index >= new Date().getMonth());
  } else {
    monthOptions = months;
  }
  return monthOptions;
};

export const sourceOptions: optionType[] = [
  {
    label: "Vobb",
    value: "vobb",
  },
  {
    label: "Studyinuk",
    value: "studyinuk",
  },
];

export const institutionTypeOptions: optionType[] = [
  {
    label: "Public",
    value: "public",
  },
  {
    label: "Private",
    value: "private",
  },
];

export const sourceOptionsFilter: DropDownItem[] = [
  {
    title: "All",
    key: "",
  },
  {
    title: "Vobb",
    key: "vobb",
  },
  {
    title: "Studyinuk",
    key: "studyinuk",
  },
];

export const languageOptions: optionType[] = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Language School",
    value: true,
  },
  {
    label: "Non-Language School",
    value: false,
  },
];


export const nationalityOptions: optionType[] = countries.map((item) => ({
  value: item.iso3,
  label: item.demonym,
}));

export const countryOptions: optionType[] = countries.map((item) => ({
  value: item.iso3,
  label: item.name,
}));

export const genderOptions: optionType[] = [
  {
    value: "male",
    label: "male",
  },
  {
    value: "female",
    label: "female",
  },
];

export const maritalOptions: optionType[] = [
  { value: "Single", label: "Single" },
  { value: "Married", label: "Married" },
  { value: "Divorced", label: "Divorced" },
];

export const educationHistoryOptions: optionType[] = [
  {
    label: "Grade 12 certificate",
    value: "ssce",
  },
  {
    label: "Bachelors degree",
    value: "bachelors",
  },
  {
    label: "Diploma",
    value: "post-grad",
  },
  {
    label: "Masters",
    value: "masters",
  },
  {
    label: "PhD",
    value: "phd",
  },
];

export const languageQualificationOptions: optionType[] = [
  {
    label: "IELTS",
    value: "ielts",
  },
  {
    label: "GRE",
    value: "gre",
  },
  {
    label: "TOEFL",
    value: "toefl",
  },
  {
    label: "OIETC",
    value: "oietc",
  },
  {
    label: "MOI",
    value: "moi",
  },
  {
    label: "Duolingo",
    value: "duolingo",
  },
];

import { Input, Textarea } from "components/Input";
import * as React from "react";
import styles from "./styles.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { AddIcon, EditIcon, WarningIcon } from "assets";
import { optionType } from "types";
import { CustomSelect } from "components";
import { createCourseData } from "services";
import { degreeOptions, attendanceOptions, currencyOptions } from "Helper/options";

interface money {
  currency: optionType;
  amount: number;
}

const initialMoney: money = {
  currency: {
    label: "",
    value: "",
  },
  amount: 0,
};

interface CourseData {
  name: string;
  degree: optionType;
  duration: string;
  yearlyTuition: money;
  initialDeposit: money;
  commission: money;
  applicationFee: money;
  attendance: optionType;
  description: string;
}

const initialValues: CourseData = {
  name: "",
  degree: {
    label: "",
    value: "",
  },
  duration: "",
  yearlyTuition: initialMoney,
  initialDeposit: initialMoney,
  commission: initialMoney,
  applicationFee: initialMoney,
  attendance: {
    label: "",
    value: "",
  },
  description: "",
};

const optionTypeSchema = yup
  .object({
    label: yup.string().required("Required"),
    value: yup.string().required("Required"),
  })
  .required("Required");

const moneySchemaRequired = yup
  .object({
    amount: yup.number().required("Required").min(1, "Amount cannot be zero"),
    currency: optionTypeSchema,
  })
  .required("Required");

const moneySchema = yup
  .object({
    amount: yup.number().min(0).nullable(),
    currency: yup.object({
      label: yup.string().nullable(),
      value: yup.string().nullable(),
    }),
  })
  .nullable()
  .notRequired();

const schema = yup
  .object({
    name: yup.string().required("Required"),
    degree: optionTypeSchema,
    duration: yup.string().required("Required"),
    yearlyTuition: moneySchemaRequired,
    initialDeposit: moneySchemaRequired,
    commission: moneySchema,
    applicationFee: moneySchema,
    attendance: optionTypeSchema,
    description: yup.string().required("Required"),
  })
  .required();

interface AddCourseProps {
  show: boolean;
  close: () => void;
  submit: (data: createCourseData) => void;
}

const AddCourseUI: React.FC<AddCourseProps> = ({ show, close, submit }) => {
  const [addApplicationFee, setAddApplicationFee] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch: watch,
    setValue,
    reset,
  } = useForm<CourseData>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  React.useEffect(() => {
    if (!show) closeForm();
  }, [show]);

  const onSubmit: SubmitHandler<CourseData> = (data) => {
    const submitData: createCourseData = {
      name: data.name,
      degree: data.degree.value,
      duration: `${data.duration} years`,
      yearlyTuition: {
        currency: data.yearlyTuition.currency.value,
        amount: data.yearlyTuition.amount,
      },
      initialDeposit: {
        currency: data.initialDeposit.currency.value,
        amount: data.initialDeposit.amount,
      },
      description: data.description,
      applicationFee: {
        currency: addApplicationFee ? data.applicationFee.currency.value : "USD",
        amount: addApplicationFee ? data.applicationFee.amount : 0,
      },
      commission: {
        currency: data.commission.currency.value === "" ? "USD" : data.commission.currency.value,
        amount: data.commission.amount,
      },
      attendance: data.attendance.value,
    };

    submit(submitData);
  };

  const closeForm = () => {
    reset();
    close();
  };
  return (
    <aside className={`${styles.wrapper} ${show ? styles.show : styles.hide}`}>
      <AddIcon
        className={`${styles.closeBtn} ${show ? styles.showIcon : styles.hideIcon}`}
        role="button"
        onClick={closeForm}
      />
      <h1 className={styles.ttl}>Add a new Course</h1>
      <p className={styles.txt}>Complete the form below to add a new course</p>

      <form className={styles.form}>
        <Input
          label="Course Title*"
          placeholder="Eg. Computer Science"
          type="text"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.name?.message}
          name="name"
          register={register}
        />
        <CustomSelect
          onChange={(x) => setValue("degree", x)}
          validatorMessage={
            !(watch("degree.label") && watch("degree.value"))
              ? errors.degree?.message ?? errors.degree?.label?.message ?? errors.degree?.value?.message ?? ""
              : ""
          }
          inputClass={styles.select}
          name={"degree"}
          placeholder={"Select..."}
          label={"Degree*"}
          options={degreeOptions}
          value={watch("degree")}
          parentClassName={styles.inputWrap}
        />
        <Input
          label="Duration*"
          placeholder=""
          type="number"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.duration?.message}
          name="duration"
          register={register}
          min={1}
        />
        <CustomSelect
          onChange={(x) => setValue("attendance", x)}
          validatorMessage={
            !(watch("attendance.label") && watch("attendance.value"))
              ? errors.attendance?.message ??
                errors.attendance?.label?.message ??
                errors.attendance?.value?.message ??
                ""
              : ""
          }
          inputClass={styles.select}
          name={"attendance"}
          placeholder={"Select..."}
          label={"Attendance*"}
          options={attendanceOptions}
          value={watch("attendance")}
          parentClassName={styles.inputWrap}
        />
        <Textarea
          label="Description*"
          placeholder="Enter a short description"
          parentClassName={styles.inputWrap}
          required
          validatorMessage={errors.description?.message}
          name="description"
          register={register}
        />
        <div className={styles.moneyGroup}>
          <p className={styles.title}>Yearly Tuition*</p>
          <CustomSelect
            onChange={(x) => setValue("yearlyTuition.currency", x)}
            validatorMessage={
              !(watch("yearlyTuition.currency.label") && watch("yearlyTuition.currency.value"))
                ? errors.yearlyTuition?.currency?.message ??
                  errors.yearlyTuition?.currency?.label?.message ??
                  errors.yearlyTuition?.currency?.value?.message ??
                  ""
                : ""
            }
            inputClass={styles.select}
            name={"yearlyTuition.currency"}
            placeholder={"Select..."}
            label={"Currency*"}
            options={currencyOptions}
            value={watch("yearlyTuition.currency")}
            parentClassName={styles.inputWrap}
          />
          <Input
            label="Amount*"
            placeholder=""
            type="number"
            parentClassName={styles.inputWrap}
            required
            validatorMessage={errors.yearlyTuition?.amount?.message}
            name="yearlyTuition.amount"
            register={register}
            min={1}
          />
          {!watch("yearlyTuition") && errors.yearlyTuition?.message ? (
            <p className={styles.message}>
              <WarningIcon /> {errors.yearlyTuition?.message}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className={styles.moneyGroup}>
          <p className={styles.title}>Initial Deposit*</p>
          <CustomSelect
            onChange={(x) => setValue("initialDeposit.currency", x)}
            validatorMessage={
              !(watch("initialDeposit.currency.label") && watch("initialDeposit.currency.value"))
                ? errors.initialDeposit?.currency?.message ??
                  errors.initialDeposit?.currency?.label?.message ??
                  errors.initialDeposit?.currency?.value?.message ??
                  ""
                : ""
            }
            inputClass={styles.select}
            name={"initialDeposit.currency"}
            placeholder={"Select..."}
            label={"Currency*"}
            options={currencyOptions}
            value={watch("initialDeposit.currency")}
            parentClassName={styles.inputWrap}
          />
          <Input
            label="Amount*"
            placeholder=""
            type="number"
            parentClassName={styles.inputWrap}
            required
            validatorMessage={errors.initialDeposit?.amount?.message}
            name="initialDeposit.amount"
            register={register}
            min={1}
          />
          {!watch("initialDeposit") && errors.initialDeposit?.message ? (
            <p className={styles.message}>
              <WarningIcon /> {errors.initialDeposit?.message}
            </p>
          ) : (
            ""
          )}
        </div>
        <label className={styles.check}>
          <span>Add application fee</span>
          <input
            checked={addApplicationFee}
            onClick={() => setAddApplicationFee(!addApplicationFee)}
            type={"checkbox"}
          />
        </label>
        {addApplicationFee && (
          <div className={styles.moneyGroup}>
            <p className={styles.title}>Application Fee</p>
            <CustomSelect
              onChange={(x) => setValue("applicationFee.currency", x)}
              validatorMessage={
                !(watch("applicationFee.currency.label") && watch("applicationFee.currency.value"))
                  ? errors.applicationFee?.currency?.message ??
                    errors.applicationFee?.currency?.label?.message ??
                    errors.applicationFee?.currency?.value?.message ??
                    ""
                  : ""
              }
              inputClass={styles.select}
              name={"applicationFee.currency"}
              placeholder={"Select..."}
              label={"Currency*"}
              options={currencyOptions}
              value={watch("applicationFee.currency")}
              parentClassName={styles.inputWrap}
            />
            <Input
              label="Amount*"
              placeholder=""
              type="number"
              parentClassName={styles.inputWrap}
              required
              validatorMessage={errors.applicationFee?.amount?.message}
              name="applicationFee.amount"
              register={register}
              min={1}
            />
            {!watch("applicationFee") && errors.applicationFee?.message ? (
              <p className={styles.message}>
                <WarningIcon /> {errors.applicationFee?.message}
              </p>
            ) : (
              ""
            )}
          </div>
        )}
        <div className={styles.moneyGroup}>
          <p className={styles.title}>Commission</p>
          <CustomSelect
            onChange={(x) => setValue("commission.currency", x)}
            validatorMessage={
              !(watch("commission.currency.label") && watch("commission.currency.value"))
                ? errors.commission?.currency?.message ??
                  errors.commission?.currency?.label?.message ??
                  errors.commission?.currency?.value?.message ??
                  ""
                : ""
            }
            inputClass={styles.select}
            name={"commission.currency"}
            placeholder={"Select..."}
            label={"Currency"}
            options={currencyOptions}
            value={watch("commission.currency")}
            parentClassName={styles.inputWrap}
          />
          <Input
            label="Amount"
            placeholder=""
            type="number"
            parentClassName={styles.inputWrap}
            required
            validatorMessage={errors.commission?.amount?.message}
            name="commission.amount"
            register={register}
            min={1}
          />
          {!watch("commission") && errors.commission?.message ? (
            <p className={styles.message}>
              <WarningIcon /> {errors.commission?.message}
            </p>
          ) : (
            ""
          )}
        </div>

        <button className={styles.submitBtn} onClick={handleSubmit(onSubmit)}>
          Submit
        </button>
      </form>
    </aside>
  );
};

export { AddCourseUI };

import {
  DeleteUnverifiedAgentsModal,
  Pagination,
  Toast,
  UnVerifiedAgencyItem,
  UnVerifiedAgentsUI,
  VerifyAgentsModal,
} from "components";
import { TableHeaderItemProps } from "components/table/components";
import { useApiRequest } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { deleteAgentService, getAllUnVerifiedAgentsService, verifyAgentService } from "services";
import { optionType } from "types";
import config from "config.json";

const UnverifiedAgents = () => {
  // Current page number for pagination component and data fetching
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = React.useState("");
  const [sourceFilter, setSourceFilter] = React.useState<optionType>({ label: "All", value: "" });

  // API Request Variables
  const { run: runUnVerifiedAgencyFetch, data: agentsData, requestStatus } = useApiRequest({});
  const { run: runAgencyVerify, data: agencyVerifyData, requestStatus: verifyRequestStats } = useApiRequest({});

  const fetchAgents = () => {
    runUnVerifiedAgencyFetch(
      getAllUnVerifiedAgentsService({
        pageNo: currentPageNo,
        search,
        source: config.whitelabel ? config.source : sourceFilter.value,
      }),
    );
  };

  // Fetch all unverified agents
  useEffect(() => {
    fetchAgents();
  }, [currentPageNo, runUnVerifiedAgencyFetch, search, sourceFilter]);

  // Request Error Data from API fetching hook
  const agentsFetchrequestError = useMemo(
    () => (agentsData?.response ? agentsData?.response?.data : null),
    [agentsData?.response],
  );

  // Agent Verify Request Error
  const agentVerifyError = useMemo(
    () => (agencyVerifyData?.response ? agencyVerifyData?.response?.data : null),
    [agencyVerifyData?.response],
  );

  // Rerun fetch after Agent delete
  useMemo(() => {
    if (agencyVerifyData?.data) fetchAgents();
  }, [agencyVerifyData]);

  // Formatted verified agents
  const allFetchedAgentsData = useMemo(() => agentsData?.data?.data, [agentsData?.data?.data]);
  const preformattedAgentsData = useMemo(() => {
    if (agentsData?.status === 200) {
      setTotalPages(allFetchedAgentsData.availablePages);
      const fetchedAgencies = allFetchedAgentsData.fetchedRequests;

      return fetchedAgencies.map((agency) => ({
        agentName: agency.agencyName,
        representativeName: agency.name,
        emailAddress: agency.email,
        agencyAddress: agency.address,
        agencyId: agency._id,
        dateCreated: new Date(agency.createdAt).toLocaleDateString(),
      })) as UnVerifiedAgencyItem[];
    }

    return [];
  }, [agentsData, allFetchedAgentsData]);

  // Table header titles
  // Table header titles
  const tableHeaderTitles: TableHeaderItemProps[] = useMemo(
    () => [
      { title: "S/N" },
      { title: "Agency Name" },
      { title: "Representative" },
      { title: "Email" },
      { title: "Address" },
      { title: "Date Created" },
      { title: "Action" },
    ],
    [],
  );
  // Modal Variables
  const [agentToBeVerified, setAgentToBeVerified] = useState<UnVerifiedAgencyItem | null>(null);
  const [agentToBeDeleted, setAgentToBeDeleted] = useState<UnVerifiedAgencyItem | null>(null);
  const [agentDeleteStatus, setAgentDeleteStatus] = useState<"idle" | "loading">("idle");

  // Launch modal
  const handleAgentVerification = (agent: UnVerifiedAgencyItem) => setAgentToBeVerified(agent);

  // Launch Delete modal
  const handleAgentDelete = (agent: UnVerifiedAgencyItem) => setAgentToBeDeleted(agent);

  // Cancel handler
  const handleCancel = () => {
    setAgentToBeVerified(null);
    setAgentToBeDeleted(null);
  };

  // Verify handler
  const handleSubmit = () => {
    if (agentToBeVerified?.agencyId) {
      runAgencyVerify(verifyAgentService({ email: agentToBeVerified.emailAddress }));
      openToast("Success", "Email sent!!", true);
      setAgentToBeVerified(null);
    }
  };

  // Delete confirmer
  const handleDeleteConfirm = () => {
    if (agentToBeDeleted) {
      setAgentDeleteStatus("loading");
      deleteAgentService({ agentId: agentToBeDeleted?.agencyId })
        .then((data) => {
          if (data?.data) {
            openToast("Success", "Agent Deleted Successfully!", true);
            setAgentToBeDeleted(null);
            rerunfetchAgents();
          }
        })
        .catch((error) => {
          if (error) {
            setAgentToBeDeleted(null);
            openToast("Error", "Something failed. Try Again.", true);
          }
        })
        .finally(() => setAgentDeleteStatus("idle"));
    }
  };

  //Toast Variables
  const [toast, setToast] = useState({
    show: false,
    heading: "",
    text: "",
    type: true,
  });

  //Toast handler
  const openToast = (hd, txt, type) => {
    setToast({
      show: true,
      heading: hd,
      text: txt,
      type: type,
    });
    setTimeout(() => {
      setToast({
        ...toast,
        show: false,
      });
    }, 3000);
  };

  // Show Non-Page UI Elements
  const showErrorFallback = agentsFetchrequestError?.success === false || agentVerifyError?.success === false;
  const showPreloader = requestStatus.isPending || verifyRequestStats.isPending || agentDeleteStatus === "loading";

  // Function to rerun a fetch of all Agents
  const rerunfetchAgents = () => runUnVerifiedAgencyFetch(getAllUnVerifiedAgentsService({ pageNo: currentPageNo }));

  // UnVerified Agents UI Props
  const UnverifiedAgentsUIProps = {
    preformattedAgentsData,
    showPreloader,
    showErrorFallback,
    agentsFetchrequestError,
    agentVerifyError,
    rerunfetchAgents,
    tableHeaderTitles,
    totalAgentsCount: allFetchedAgentsData?.count,
    dropdownActions: {
      handleAgentDelete,
      handleAgentVerification,
    },
  };

  // Modal Component Props
  const modalProps = {
    agentToBeVerified,
    handleSubmit,
    handleCancel,
  };

  // Modal Component Props
  const deleteModalProps = {
    agentToBeDeleted,
    handleSubmit: handleDeleteConfirm,
    handleCancel,
  };

  const handleSearch = (search) => {
    setCurrentPageNo(1);
    setSearch(search);
  };

  return (
    <React.Fragment>
      <Toast
        show={toast.show}
        closeModal={() => setToast({ ...toast, show: false })}
        type={toast.type}
        heading={toast.heading}
        text={toast.text}
      />
      <UnVerifiedAgentsUI
        currentPage={currentPageNo}
        {...UnverifiedAgentsUIProps}
        handleSearch={handleSearch}
        sourceFilter={{ value: sourceFilter, control: (x) => setSourceFilter(x) }}
      />

      {agentToBeVerified && <VerifyAgentsModal {...modalProps} />}
      {agentToBeDeleted && <DeleteUnverifiedAgentsModal {...deleteModalProps} />}

      <Pagination pages={totalPages} handlePage={(page) => setCurrentPageNo(page)} />
    </React.Fragment>
  );
};

export { UnverifiedAgents };

import { VobbLogoFull, EyeCloseIcon, EyeOpenIcon } from "assets/vectors";
import React, { useState } from "react";
import styles from "./styles.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import config from "config.json";

interface LoginProps {
  onSubmit: (email: string, password: string, captcha: string) => void;
}

const AdminLoginUI: React.FC<LoginProps> = ({ onSubmit }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captcha, setCaptcha] = React.useState("");
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
  };

  const login = (e) => {
    e.preventDefault();
    onSubmit(email, password, captcha);
    recaptchaRef.current?.reset();
  };
  return (
    <>
      <div className={styles.loginWrapper}>
        {config.whitelabel ? <img className={styles.logo} src={config.logo} /> : <VobbLogoFull />}
        <div className={styles.formWrapper}>
          <h1>Welcome Back Admin!</h1>
          <p>Please enter your credentials to access the admin dashboard</p>
          <form className={styles.loginForm}>
            <div className={styles.inputWrap}>
              <input
                onChange={handleEmailChange}
                value={email}
                name="email"
                type="email"
                placeholder="Email address"
                required
              />
            </div>

            <div className={styles.inputWrap}>
              <input
                onChange={handlePasswordChange}
                value={password}
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                required
              />
              {!showPassword ? (
                <EyeOpenIcon onClick={() => setShowPassword(true)} />
              ) : (
                <EyeCloseIcon onClick={() => setShowPassword(false)} />
              )}
            </div>
            <ReCAPTCHA
              class="recaptcha"
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={(token) => {
                // console.log(token, "captcha");
                setCaptcha(token);
              }}
              ref={recaptchaRef}
            />
            <button onClick={login} disabled={!email || !password || !captcha}>
              Login
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export { AdminLoginUI };

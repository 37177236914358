import React from "react";
import styles from "../styles.module.css";

// Empty Table Body
const EmptyTableBody: React.FC = () => {
  return (
    <div className={styles.emptyTableContainer}>
      <p>No Item found</p>
    </div>
  );
};

export { EmptyTableBody };

import { editBlogURL, getSingleBlogURL } from "api";
import { BlogModal, BlogsTableBodyItem, BlogsUI, DeleteBlogPrompt, Pagination, Toast } from "components";
import Preloader from "components/Preloader";
import { useApiRequest } from "hooks";
import * as React from "react";
import { useCallback } from "react";
import {
  createBlogService,
  deleteBlogService,
  editBlogService,
  getBlogsService,
  getSingleBlogService,
  uploadService,
} from "services";

const Blogs = () => {
  const [count, setCount] = React.useState<number>(0);
  const [serverUrl, setServerUrl] = React.useState<string>("");
  const [serverError, setServerError] = React.useState<string>("");
  const [showBlog, setShowBlog] = React.useState<boolean>(false);
  const [pageNo, setPageNo] = React.useState<number>(1);
  const [totalPages, setTotalPages] = React.useState<number>(1);
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });
  const [deletePrompt, setDeletePrompt] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [activeBlogID, setActiveBlogID] = React.useState<string>("");

  const { run: runUploadImage, data: imageUploadResponse, requestStatus: imageUploadStatus } = useApiRequest({});
  const { run: runCreateBlog, data: createBlogResponse, requestStatus: createBlogStatus } = useApiRequest({});
  const { run: runFetchBlogs, data: fetchBlogResponse, requestStatus: fetchBlogStatus } = useApiRequest({});
  const { run: runFetchSingleBlog, data: singleBlogResponse, requestStatus: singleBlogStatus } = useApiRequest({});
  const {
    run: runDeleteBlog,
    data: deleteBlogResponse,
    requestStatus: deleteBlogStatus,
    error: deleteBlogError,
  } = useApiRequest({});
  const {
    run: runEditBlog,
    data: editBlogResponse,
    requestStatus: editBlogStatus,
    error: editBlogError,
  } = useApiRequest({});

  // Fetch blogs
  React.useEffect(() => {
    runFetchBlogs(getBlogsService({ pageNo }));
  }, [pageNo]);

  React.useEffect(() => {
    if (imageUploadResponse?.status === 200) {
      const path = imageUploadResponse?.data?.data?.[0]?.path;
      setServerUrl(path);
    }
  }, [imageUploadResponse]);

  React.useEffect(() => {
    if (createBlogResponse?.status === 200) {
      window.location.reload();
    } else if (createBlogResponse?.response.status >= 400) {
      setServerError(createBlogResponse?.response?.data?.message);
    }
  }, [createBlogResponse]);

  // Delete blog response
  React.useMemo(() => {
    if (deleteBlogResponse?.status === 200) {
      setShowBlog(false);
      setToast({
        show: true,
        heading: "",
        text: deleteBlogResponse?.response?.data?.message ?? "Deleted blog successfully",
        type: true,
      });
      setTimeout(() => {
        setToast({
          ...toast,
          show: false,
        });
        runFetchBlogs(getBlogsService({ pageNo }));
      }, 2000);
    } else if (deleteBlogStatus.isRejected || deleteBlogStatus.isResolved) {
      setToast({
        show: true,
        heading: "Sorry",
        text:
          deleteBlogError?.response?.data?.message ??
          deleteBlogResponse?.response?.data?.message ??
          "Failed to delete blog. Please try again later.",
        type: false,
      });
      setTimeout(() => {
        setToast({
          ...toast,
          show: false,
        });
      }, 5000);
    }
  }, [deleteBlogResponse, deleteBlogStatus]);

  // Edit blog response
  React.useMemo(() => {
    if (editBlogResponse?.status === 200) {
      setShowBlog(false);
      setSuccess(!success);
      setToast({
        show: true,
        heading: "",
        text: deleteBlogResponse?.response?.data?.message ?? "Updated blog successfully",
        type: true,
      });
      setTimeout(() => {
        setToast({
          ...toast,
          show: false,
        });
        runFetchBlogs(getBlogsService({ pageNo }));
      }, 2000);
    } else if (editBlogStatus.isRejected || editBlogStatus.isResolved) {
      setToast({
        show: true,
        heading: "Sorry",
        text:
          editBlogError?.response?.data?.message ??
          editBlogResponse?.response?.data?.message ??
          "Failed to update blog. Please try again later.",
        type: false,
      });
      setTimeout(() => {
        setToast({
          ...toast,
          show: false,
        });
      }, 5000);
    }
  }, [editBlogResponse, editBlogStatus]);

  // Fetch all blogs response
  const preformattedBlogData = React.useMemo<BlogsTableBodyItem[]>(() => {
    if (fetchBlogResponse?.status === 200) {
      const fetchedBlogs = fetchBlogResponse?.data.data.fetchedRequests;
      setCount(fetchBlogResponse.data.data.count);
      setTotalPages(fetchBlogResponse.data.data.availablePages);
      return fetchedBlogs.map((item) => ({
        id: item._id,
        title: item.title,
        date: item.createdAt,
        link: item.blog_link,
        image: item.image,
        description: item.description,
      }));
    }

    return [];
  }, [fetchBlogResponse]);

  // Fetch single blog response
  const blogData = React.useMemo<BlogsTableBodyItem>(() => {
    let blog: BlogsTableBodyItem = {
      id: "",
      title: "",
      date: "",
      link: "",
      image: "",
      description: "",
    };

    if (singleBlogResponse?.status === 200) {
      // console.log(singleBlogResponse);
      setShowBlog(true);
      const fetchedBlog = singleBlogResponse?.data?.data;
      blog = {
        id: fetchedBlog._id,
        title: fetchedBlog.title,
        date: fetchedBlog.createdAt,
        link: fetchedBlog.blog_link,
        image: fetchedBlog.image,
        description: fetchedBlog.description,
      };
    }
    return blog;
  }, [singleBlogStatus, singleBlogResponse]);

  const handleImageAsyncUpload = useCallback(
    async (data) => {
      runUploadImage(uploadService(data));
      await imageUploadResponse;
    },
    [runUploadImage, imageUploadResponse],
  );

  const handleCreateBlog = useCallback(
    async (data) => {
      console.log(data);
      runCreateBlog(createBlogService(data));
    },
    [runCreateBlog],
  );

  const clearServerUrl = useCallback(() => {
    setServerUrl("");
  }, []);

  const viewBlog = (id: string) => {
    setActiveBlogID(id);
    runFetchSingleBlog(getSingleBlogService({ blogID: id }));
  };

  const deleteBlog = () => {
    runDeleteBlog(deleteBlogService({ blogID: activeBlogID }));
  };

  const updateBlog = (id, data) => {
    console.log(id, data);
    runEditBlog(editBlogService({ blogID: id, data }));
  };
  const showPreloader =
    createBlogStatus.isPending ||
    fetchBlogStatus.isPending ||
    singleBlogStatus.isPending ||
    deleteBlogStatus.isPending ||
    editBlogStatus.isPending;
  return (
    <>
      {showPreloader && <Preloader />}
      <Toast
        show={toast.show}
        closeModal={() => setToast({ ...toast, show: false })}
        heading={toast.heading}
        text={toast.text}
        type={toast.type}
      />
      <BlogModal
        show={showBlog && !showPreloader}
        closeModal={() => setShowBlog(false)}
        Blog={blogData}
        editBlog={(id, data) => updateBlog(id, data)}
        deleteBlog={() => {
          setDeletePrompt(true);
        }}
        handleImageAsyncUpload={handleImageAsyncUpload}
        serverUrl={serverUrl}
        serverError={serverError}
        clearServerUrl={clearServerUrl}
        success={success}
      />
      <DeleteBlogPrompt show={deletePrompt} closeModal={() => setDeletePrompt(false)} deleteBlog={() => deleteBlog()} />

      <BlogsUI
        handleImageAsyncUpload={handleImageAsyncUpload}
        serverUrl={serverUrl}
        serverError={serverError}
        clearServerUrl={clearServerUrl}
        count={count}
        handleCreateBlog={handleCreateBlog}
        viewBlog={(id) => viewBlog(id)}
        tableBodyItems={preformattedBlogData}
      />
      <Pagination pages={totalPages} handlePage={(x) => setPageNo(x)} />
    </>
  );
};
export { Blogs };

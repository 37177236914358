import { VerticalEllipseIcon } from "assets/vectors";
import { DropdownList } from "components/Selectbox/dropdownList";
import { useDropdownList } from "hooks";
import React from "react";
import { DropDownItem } from "../../../Selectbox/index";
import { TableRowItem } from "../../components";
import { AdminTableBody } from "../../components/tbody";
import { TableDataItem } from "../../components/td";
import styles from "./styles.module.css";

// University UnVerification Table Body Item
export interface UnVerifiedAgencyItem {
  agentName: string;
  representativeName: string;
  emailAddress: string;
  agencyAddress: string;
  agencyId: string;
  dateCreated: string;
}

// University UnVerification Table Body Props
interface TableBodyProps {
  tableBodyItems: UnVerifiedAgencyItem[];
  dropdownActions: UnVerifiedAgentsDropdownActions;
  tableBodyClassName: string;
  currentPage: number;
}

const UnVerifiedAgentsTableBody: React.FC<TableBodyProps> = ({
  tableBodyItems,
  dropdownActions,
  tableBodyClassName,
  currentPage,
}) => {
  return (
    <AdminTableBody customClassName={styles.tableContainer}>
      {tableBodyItems.map((item, idx) => (
        <TableRowItem key={`${item.agentName} ${idx}`} customClassName={styles.tableRow}>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {(currentPage - 1) * 10 + idx + 1}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.agentName}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {item.representativeName}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName} ${styles.email}`}>{item.emailAddress}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.agencyAddress}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.dateCreated}</TableDataItem>
          <ActionsTableRow actions={dropdownActions} item={item} />
        </TableRowItem>
      ))}
    </AdminTableBody>
  );
};

// Action Tab Props
export interface UnVerifiedAgentsDropdownActions {
  handleAgentDelete: (agent: UnVerifiedAgencyItem) => void;
  handleAgentVerification: (agent: UnVerifiedAgencyItem) => void;
}

// Action tab props
interface ActionTabsProps {
  item: UnVerifiedAgencyItem;
  actions: UnVerifiedAgentsDropdownActions;
}

const ActionsTableRow: React.FC<ActionTabsProps> = ({ item, actions }) => {
  // Default Dropdown list
  const dropDownList: DropDownItem[] = [
    { title: "Verify Agent", styles: { color: "#5b7fff", fontWeight: 600 }, action: actions.handleAgentVerification },
    { title: "Delete Agent", styles: { color: "#ff5630", fontWeight: 600 }, action: actions.handleAgentDelete },
  ];

  // Hook to use dropdownList
  const { activeDropDownState, dropdownRef, dropdownLinkRef, setActiveDropdownState } = useDropdownList({
    initialDropdownState: false,
  });

  return (
    <TableDataItem className={styles.tableRowItem}>
      <button
        className={styles.deleteCTA}
        ref={dropdownLinkRef}
        id={item.agencyId}
        onClick={() => setActiveDropdownState((state) => !state)}
        key={item.agencyId}
      >
        <VerticalEllipseIcon className={styles.svg} />
      </button>

      {activeDropDownState && (
        <DropdownList
          onDropItemSelect={(dropItem) => dropItem.action && dropItem.action(item)}
          activeDropDownState={activeDropDownState}
          dropdownItems={dropDownList}
          dropdownRef={dropdownRef}
          dropPosition={"top"}
          key={item.agencyId}
        />
      )}
    </TableDataItem>
  );
};

export { UnVerifiedAgentsTableBody };

import { AddIcon, SearchIcon } from "assets";
import {
  AdminDashBoardTable,
  AllCustomTableClasses,
  FilterBox,
  InstitutionsTableBody,
  InstitutionsTableBodyItem,
  PageHeader,
} from "components";
import { TableHeaderItemProps } from "components/table/components";
import React from "react";
import styles from "./styles.module.css";
import { languageOptions } from "Helper/options";
import { optionType } from "types";

interface InstitutionsProps {
  tableBodyItems: InstitutionsTableBodyItem[];
  handleViewInstitution: (id: string) => void;
  count: number;
  addUni: () => void;
  handleSearch: (x: string) => void;
  updateStatus: (id: string, stat: boolean) => void;
  filter: {
    languageSchool: {
      value: optionType;
      control: (x) => void;
    };
  };
}

const InstitutionsUI: React.FC<InstitutionsProps> = ({
  tableBodyItems,
  handleViewInstitution,
  count,
  addUni,
  handleSearch,
  updateStatus,
  filter,
}) => {
  const [search, setSearch] = React.useState("");

  // This  is to show how to use the table component
  const tableHeaderTitles: TableHeaderItemProps[] = [
    { title: "Institution" },
    { title: "Country" },
    { title: "Website" },
    { title: "Status" },
    { title: "" },
  ];

  // Custom table Classes
  const CustomTableClasses: AllCustomTableClasses = {
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItem,
    tableWrapperClass: styles.tableWrapperClass,
  };

  const handleChange = (e) => {
    if (e.target.value === "") {
      handleSearch("");
    }
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    setSearch("");
  }, []);

  return (
    <section>
      <PageHeader title="Institutions" description="Institutions added on Vobb" count={count} />
      <button onClick={addUni} className={styles.addUniBtn}>
        <AddIcon /> Add Institution
      </button>
      <div className={styles.searchFilterWrapper}>
        <div className={styles.searchInputWrapper}>
          <SearchIcon />
          <input
            className={styles.searchInput}
            value={search}
            onChange={handleChange}
            type="search"
            placeholder="Search..."
            onKeyPress={(e) => {
              if (e.key === "Enter") handleSearch(search);
            }}
          />
        </div>
        <div className={styles.filterWrapper}>
          <FilterBox
            onChange={(x) => filter.languageSchool.control(x)}
            validatorMessage={""}
            inputClass={styles.select}
            name={"languageSchool"}
            placeholder={"Select..."}
            label={"Language School"}
            options={languageOptions}
            value={filter.languageSchool.value}
            parentClassName={`${styles.dropdownWrap}`}
          />
        </div>
      </div>
      {tableBodyItems.length > 0 && (
        <AdminDashBoardTable
          tableHeaderTitles={tableHeaderTitles}
          customTableClasses={CustomTableClasses}
          AdminTableBody={
            <InstitutionsTableBody
              handleView={handleViewInstitution}
              tableBodyClassName={styles.tableBodyClass}
              tableBodyItems={tableBodyItems}
              updateStatus={updateStatus}
            />
          }
        />
      )}
    </section>
  );
};

export { InstitutionsUI };
export * from "./addInstitution";

import React from "react";
import styles from "../styles.module.css";

interface TableRowItemProps {
  customClassName?: string;
}

/**
 * This component is a representation of the HTML's tr element.
 *
 */

const TableRowItem: React.FC<TableRowItemProps> = ({ children, customClassName = "" }) => {
  return <tr className={`${styles.generalTableRow} ${customClassName}`}>{children}</tr>;
};

export { TableRowItem };

import styles from "./styles.module.css";
import * as React from "react";

interface PageHeaderProps {
  title: string;
  description: string;
  count?: number;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, description, count }) => {
  return (
    <section>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.description}>{description}</p>
      {count ? <p className={styles.count}>{count}</p> : ""}
    </section>
  );
};

export { PageHeader };

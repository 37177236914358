/* eslint-disable @typescript-eslint/no-explicit-any */
import { SupportMessageType } from "api";
import { AdminDashBoardTable, AllCustomTableClasses, HorizontalTab, PageHeader } from "components";
import Preloader from "components/Preloader";
import { TableHeaderItemProps } from "components/table/components";
import { HeroSubTabKeys } from "pages";
import React, { useMemo } from "react";
import { HorizontalTabItemProps } from "types";
import { SupportTableBody, SupportTableItem } from "../table/tableBodyVariants";
import { SupportEmptyTableBody } from "./components";
import styles from "./styles.module.css";

// Support UI Props
interface SupportUIProps {
  allFetchedMsgsData: any;
  Items: HorizontalTabItemProps<any>[];
  updateActiveTab: (currentTab: HorizontalTabItemProps<object>) => void;
  isActive: (tab: HorizontalTabItemProps<unknown>) => boolean;
  setCurrentMessageInView: React.Dispatch<React.SetStateAction<SupportTableItem | null>>;
  setMessageToDelete: React.Dispatch<React.SetStateAction<SupportTableItem | null>>;
  showPreloader: boolean;
  tableHeaderTitles: TableHeaderItemProps[];
  CurrentTabData: SupportTableItem[];
  currentTab: HorizontalTabItemProps<typeof HeroSubTabKeys>;
}

const AdminSupportPageUI: React.FC<SupportUIProps> = (props) => {
  // All props data
  const {
    currentTab,
    tableHeaderTitles,
    showPreloader,
    setCurrentMessageInView,
    setMessageToDelete,
    allFetchedMsgsData,
    Items,
    updateActiveTab,
    isActive,
    CurrentTabData,
  } = props;

  // All custom Table Classes
  const customTableClasses: AllCustomTableClasses = useMemo(
    () => ({
      tableWrapperClass: styles.tableWrapperClass,
      tableContainerClassName: styles.tableContainerClass,
      tableHeaderClassName: styles.tableHeaderClass,
      tableHeaderItemClassName: styles.tableHeaderItem,
    }),
    [],
  );

  return (
    <React.Fragment>
      <PageHeader
        title="Support"
        description="View all support messages, manage, and delete."
        count={allFetchedMsgsData?.count || 0}
      />
      <HorizontalTab
        tabItems={Items}
        updateCurrentTab={updateActiveTab}
        isActive={isActive}
        tabVariant="primary"
        containerStyles={{ justifyContent: "flex-start" }}
      />

      {showPreloader ? (
        <Preloader />
      ) : (
        <AdminDashBoardTable
          tableHeaderTitles={tableHeaderTitles}
          customTableClasses={{ ...customTableClasses }} // custom class object
          EmptyTableElement={<SupportEmptyTableBody currentTab={currentTab.title} />}
          showEmptyTableElement={CurrentTabData.length < 1}
          hideHeaders={CurrentTabData.length < 1}
          AdminTableBody={
            <SupportTableBody
              tableBodyItems={CurrentTabData}
              handleViewMessage={(message) => setCurrentMessageInView(message)}
              handleMessageDelete={(message) => setMessageToDelete(message)}
              currentTabTitle={currentTab.title as SupportMessageType}
            />
          }
        />
      )}
    </React.Fragment>
  );
};

export { AdminSupportPageUI };

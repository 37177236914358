import { ContactProps } from "components/ViewUniversities";
import React from "react";
import { TableRowItem } from "../../components";
import { AdminTableBody } from "../../components/tbody";
import { TableDataItem } from "../../components/td";
import styles from "./styles.module.css";

// University Verification Table Body Item
export interface VerificationTableBodyItem extends ContactProps {
  agencyName: string;
  representativeName: string;
  universityCount: JSX.Element;
  id: string;
}

// University Verification Table Body Props
interface TableBodyProps {
  tableBodyItems: VerificationTableBodyItem[];
  tableBodyClassName: string;
  handleView: (id: string, contact: ContactProps) => void;
}

const VerificationTableBody: React.FC<TableBodyProps> = ({ tableBodyItems, tableBodyClassName, handleView }) => {
  return (
    <AdminTableBody customClassName={`${styles.tableContainer} `}>
      {tableBodyItems.map((item, idx) => (
        <TableRowItem key={`${item.agencyName} ${idx}`} customClassName={styles.tableRow}>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.agencyName}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {item.representativeName}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            {item.universityCount}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            <button
              className={styles.viewBtn}
              onClick={() =>
                handleView(item.id, {
                  agencyName: item.agencyName,
                  email: item.email,
                  address: item.address,
                  phone: item.phone,
                })
              }
            >
              View
            </button>
          </TableDataItem>
        </TableRowItem>
      ))}
    </AdminTableBody>
  );
};

export { VerificationTableBody };

// Environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL;

/*
=================================
AUTH URLS
=================================
*/

/**
 * Comment url
 *
 * @returns url string
 *
 */

export const getCommentURL = (id) => `${API_URL}/agent/fetch-applicaitons/${id}`;

export const createCommentURL = (id) => `${API_URL}/agent/application/comment/${id}`;

export const editCommentURL = (id, commentID) =>
  `${API_URL}/agent/application_comment/edit/${id}?commentID=${commentID}`;

export const deleteCommentURL = (id, commentID) =>
  `${API_URL}/agent/applicaiton_coment/delete/${id}?commentID=${commentID}`;

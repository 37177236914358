import { Chart as ChartJS, ChartData, registerables, ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";
import * as React from "react";

ChartJS.unregister();
ChartJS.register(...registerables);
ChartJS.defaults.font.family = "Barlow";
ChartJS.defaults.font.size = 13;

interface AgentData {
  data: number[];
}
const EmailVerificationAgentChart: React.FC<AgentData> = ({ data }) => {
  const chartData: ChartData<"bar"> = {
    labels: ["Unverified", "Verified"],
    datasets: [
      {
        data: data,
        backgroundColor: ["rgb(255 159 64/ 20%)", "rgb(0 193 0 / 20%)"],
        borderColor: ["rgb(255 159 64)", "rgb(0 193 0)"],
        borderWidth: 1,
        borderRadius: 4,
        barThickness: 32,
        maxBarThickness: 32,
        categoryPercentage: 0.5,
        barPercentage: 1.0,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const config = {
    type: "bar",
    data: chartData,
    options: options,
  };

  return <Bar {...config} />;
};

const AccountApprovalAgentChart: React.FC<AgentData> = ({ data }) => {
  const chartData: ChartData<"bar"> = {
    labels: ["No Requests", "Requests", "Approved"],
    datasets: [
      {
        data: data,
        backgroundColor: ["rgb(124 124 255 / 20%)", "rgb(255 159 64 / 20%)", "rgb(0 193 0 / 20%)"],
        borderColor: ["rgb(124 124 255)", "rgb(255 159 64)", "rgb(0 193 0)"],
        borderWidth: 1,
        borderRadius: 4,
        barThickness: 32,
        maxBarThickness: 32,
        categoryPercentage: 0.5,
        barPercentage: 1.0,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const config = {
    type: "bar",
    data: chartData,
    options: options,
  };

  return <Bar {...config} />;
};

export { EmailVerificationAgentChart, AccountApprovalAgentChart };

import { Chart as ChartJS, ChartData, registerables, ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";
import * as React from "react";

ChartJS.unregister();
ChartJS.register(...registerables);
ChartJS.defaults.font.family = "Barlow";
ChartJS.defaults.font.size = 13;

interface CommissionData {
  data: number[];
}
const CommissionChart: React.FC<CommissionData> = ({ data }) => {
  const chartData: ChartData<"bar"> = {
    labels: ["Payable", "Paid"],
    datasets: [
      {
        label: "Commissions",
        data: data,
        backgroundColor: ["rgb(255 159 64/ 20%)", "rgb(0 193 0 / 20%)"],
        borderRadius: 4,
        barThickness: 32,
        maxBarThickness: 32,
        categoryPercentage: 0.5,
        barPercentage: 1.0,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    indexAxis: "y" as const,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
        labels: {
          color: "rgb(255, 99, 132)",
        },
      },
    },
  };

  const config = {
    type: "bar",
    data: chartData,
    options: options,
  };

  return <Bar {...config} />;
};

export { CommissionChart };

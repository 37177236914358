import { SupportMessageType } from "api";
import { Modal, SelectBox, SupportTableItem } from "components";
import Preloader from "components/Preloader";
import { useApiRequest } from "hooks";
import React, { useEffect, useState } from "react";
import { updateASupportMsgeStatServ } from "services";
import verifiedAgentsStyles from "../verifiedAgents/styles.module.css";
import styles from "./styles.module.css";

// Shared Modal Props
interface IProps {
  handleMessageUpdateCancel: () => void;
  supportMessage: SupportTableItem | null;
  confirmDeleteHandler?: (item: SupportTableItem | null) => void;
}

// Support Message Modal
const SupportMessageModal: React.FC<IProps> = ({ handleMessageUpdateCancel, supportMessage }) => {
  const [defaultStatus, setDefaultStatus] = useState(supportMessage?.status.toLowerCase());

  // API Status Update Request Hook
  const { run, data: statusUpdateData, requestStatus } = useApiRequest({});

  // Status update function
  const handleStatusUpdate = (updatedStatus) => {
    // Status to be changed to
    const statusToBeChanged = updatedStatus?.title?.toLowerCase() as SupportMessageType;
    run(
      updateASupportMsgeStatServ({
        messageId: supportMessage?.messageId,
        messageType: statusToBeChanged,
      }),
    );
  };

  // Update status if update is successful
  useEffect(() => {
    if (statusUpdateData?.data) {
      setDefaultStatus(statusUpdateData?.data?.data?.status);
    }
  }, [statusUpdateData?.data]);

  return (
    <Modal show={true} onHide={() => {}}>
      <section className={`${verifiedAgentsStyles.modalContainer} ${styles.modalContainer}`}>
        {requestStatus.isPending && <Preloader />}
        <aside className={styles.messageTitle}>
          <h3>Title: </h3>
          <p>{supportMessage?.messageTitle}</p>
        </aside>
        <aside className={styles.messageBody}>
          <h3>Message Body: </h3>
          <p>{supportMessage?.message}</p>
        </aside>

        <aside className={`${styles.messageStatus} ${defaultStatus ? styles[defaultStatus] : ""}`}>
          <h3>Current Status</h3>
          <p>{defaultStatus?.toUpperCase()}</p>
        </aside>

        <aside className={`${styles.messageStatusUpdate}`}>
          <h3>Update Message Status:</h3>
          <SelectBox
            selectGroupID="status display"
            dropPosition="bottom"
            containerStyles={{ maxWidth: "200px" }}
            defaultSelected={{ title: defaultStatus || "resolved" }}
            onDropItemSelect={handleStatusUpdate}
          />
        </aside>

        <aside className={verifiedAgentsStyles.modalBtnContainer} style={{ marginTop: "10px" }}>
          <button
            className={verifiedAgentsStyles.cancelBtn}
            onClick={handleMessageUpdateCancel}
            style={{ marginLeft: "0px" }}
          >
            Close Modal
          </button>
        </aside>
      </section>
    </Modal>
  );
};

// Delete Modal Component
const SupportMsgDeleteModal: React.FC<IProps> = ({
  handleMessageUpdateCancel,
  confirmDeleteHandler = () => {},
  supportMessage,
}) => {
  return (
    <Modal show={true} onHide={() => {}}>
      <section className={verifiedAgentsStyles.modalContainer}>
        <h2>Are you sure you want to delete this message? This action cannot be undone.</h2>
        <aside className={verifiedAgentsStyles.modalBtnContainer}>
          <button className={verifiedAgentsStyles.deleteBtn} onClick={() => confirmDeleteHandler(supportMessage)}>
            Confirm
          </button>
          <button className={verifiedAgentsStyles.cancelBtn} onClick={handleMessageUpdateCancel}>
            Cancel
          </button>
        </aside>
      </section>
    </Modal>
  );
};

export { SupportMessageModal, SupportMsgDeleteModal };

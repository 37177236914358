import { OverviewUI } from "components";
import * as React from "react";

const Overview = () => {
  return (
    <>
      <OverviewUI />
    </>
  );
};

export { Overview };

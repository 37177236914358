import React, { ReactNode } from "react";
import { HorizontalTabProps } from "types";
import styles from "./styles.module.css";

// Horizontal Tab Component
const HorizontalTab = <T extends object>({
  tabItems,
  tabVariant,
  containerStyles,
  isActive,
  updateCurrentTab,
}: HorizontalTabProps<T> & { children?: ReactNode }) => {
  return (
    <ul className={`${styles.links_container} ${styles[tabVariant]}`} style={containerStyles}>
      {tabItems.map((item) => {
        // Active item check
        const isTabActive = isActive(item);
        // Active Item Style
        const activeItemStyles = isTabActive ? styles.active : "";

        return (
          <li
            key={item.title}
            className={`${styles.link_Item} ${activeItemStyles} ${styles[tabVariant]}`}
            onClick={() => updateCurrentTab(item)}
          >
            <span>{item.title}</span>
          </li>
        );
      })}
    </ul>
  );
};

export { HorizontalTab };

import React from "react";
import styles from "../styles.module.css";
import { TableHeaderItem } from "./th";
import { TableHead } from "./thead";
import { TableRowItem } from "./tr";

// Table header item
interface TableHeaderItemProps {
  title: string;
}

// Table header props
interface TableHeaderProps {
  tableHeaderTitles: TableHeaderItemProps[];
  className?: string;
  tableHeadItemClassName?: string;
}

/**
 * This is a representation of the admin table header
 * ---------------------------------------------------
 * @param tableHeaderTitles - @interface TableHeaderProps
 *
 */

const AdminTableHeader: React.FC<TableHeaderProps> = ({
  tableHeaderTitles,
  className = "",
  tableHeadItemClassName = "",
}) => {
  return (
    <TableHead className={`${styles.generalHeaderContainer} ${className}`}>
      <TableRowItem>
        {tableHeaderTitles.map((header, idx) => {
          return (
            <TableHeaderItem className={tableHeadItemClassName} key={`${header}${idx + 1}`}>
              {header["title"]}
            </TableHeaderItem>
          );
        })}
      </TableRowItem>
    </TableHead>
  );
};

export { AdminTableHeader };
export type { TableHeaderItemProps, TableHeaderProps };

// Environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL;

/*
=================================
AUTH URLS
=================================
*/

/**
 * Login url
 *
 * @returns url string
 *
 */

export const loginURL = () => `${API_URL}/auth/login`;

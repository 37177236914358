import { EditIcon } from "assets";
import { Modal } from "components/Modal";
import { ModalBody } from "components/Modal/components";
import { Toggle } from "components/Toggle";
import * as React from "react";
import styles from "./styles.module.css";

export interface CourseData {
  title: string;
  description: string;
  degree: string;
  duration: string;
  yearlyTuition: string;
  initialDeposit: string;
  applicationFee: string;
  commission: string;
  attendance: string;
}

interface ViewCourseProps {
  show: boolean;
  closeModal: () => void;
  status: {
    value: boolean;
    control: () => void;
  };
  course: CourseData;
  edit: () => void;
}

const ViewCourseModal: React.FC<ViewCourseProps> = ({ show, closeModal, status, course, edit }) => {
  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <ModalBody>
          <EditIcon onClick={edit} role={"button"} className={styles.editIcon} />
          <h1 className={styles.ttl}>{course.title}</h1>
          <div className={styles.toggle}>
            <Toggle onClick={() => status.control()} checked={status.value} />{" "}
            <span>{status.value ? "Accepting applications" : "Not accepting applications"}</span>
          </div>
          <p className={styles.txt2}>{course.description}</p>
          <p className={styles.txt1}>
            <span>Degree:</span> {course.degree}
          </p>
          <p className={styles.txt1}>
            <span>Duration:</span> {course.duration}
          </p>
          <p className={styles.txt1}>
            <span>Yearly Tuition:</span> {course.yearlyTuition}
          </p>
          <p className={styles.txt1}>
            <span>Initial Deposit:</span> {course.initialDeposit}
          </p>
          <p className={styles.txt1}>
            <span>Application Fee:</span> {course.applicationFee}
          </p>
          <p className={styles.txt1}>
            <span>Commission:</span> {course.commission}
          </p>
          <p className={styles.txt1}>
            <span>Attendance:</span> {course.attendance}
          </p>
          <button onClick={closeModal} className={styles.closeBtn}>
            Close
          </button>
        </ModalBody>
      </Modal>
    </>
  );
};

export { ViewCourseModal };

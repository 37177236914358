import React from "react";
import { DropDownItem, DropListPosition } from "./index";
import styles from "./styles.module.css";

// Droplist Props
interface IProps {
  onDropItemSelect: (item: DropDownItem) => void;
  activeDropDownState: boolean;
  dropdownItems: DropDownItem[];
  dropdownRef: React.RefObject<HTMLUListElement>;
  closeDropDown?: () => void;
  setSelectedItem?: React.Dispatch<React.SetStateAction<DropDownItem | undefined>>;
  selectedItem?: DropDownItem | undefined;
  dropPosition: DropListPosition;
  customStyles?: React.CSSProperties;
  customClassName?: string;
}

const DropdownList = ({
  activeDropDownState,
  dropdownItems,
  selectedItem,
  dropdownRef,
  closeDropDown,
  setSelectedItem,
  onDropItemSelect,
  dropPosition,
  customStyles,
  customClassName,
}: IProps) => {
  return (
    <React.Fragment>
      {activeDropDownState && dropdownItems?.length && (
        <ul
          ref={dropdownRef}
          className={`${styles.dropdown_list}
          ${dropPosition ? styles[dropPosition] : ""} ${customClassName}
          `}
          style={customStyles}
        >
          {dropdownItems.map((dropItem) => {
            return (
              <li
                onMouseDown={() => {
                  onDropItemSelect(dropItem);
                  setSelectedItem?.(dropItem);
                  if (closeDropDown) {
                    closeDropDown();
                  }
                }}
                key={dropItem.title}
                className={`${styles.dropdown_list_item} ${
                  selectedItem?.title === dropItem.title ? styles.active : ""
                } ${dropPosition ? styles[dropPosition] : ""}`}
                style={dropItem.styles}
              >
                {dropItem.title}
              </li>
            );
          })}
        </ul>
      )}
    </React.Fragment>
  );
};

export { DropdownList };

import React from "react";
import styles from "./styles.module.css";

// Error UI for Verified Agents Page
const VerifiedAgentsErrorFallBack = ({
  agentsFetchrequestError,
  rerunfetchAgents,
  retryDelete,
  deleteRequestError,
  showDeleteButtonGroup,
  handlePageReload = () => {},
}) => {
  return (
    <div className={styles.errorContainer}>
      <h2>{agentsFetchrequestError?.message || deleteRequestError?.message || "Something Failed"}</h2>
      {showDeleteButtonGroup ? (
        <div className={styles.modalBtnContainer} style={{ marginTop: "0px" }}>
          <button className={styles.deleteBtn} onClick={retryDelete}>
            Retry Delete
          </button>
          <button className={styles.cancelBtn} onClick={handlePageReload}>
            Reload Page
          </button>
        </div>
      ) : (
        <button className={styles.cancelBtn} onClick={rerunfetchAgents}>
          Rerun Fetch
        </button>
      )}
    </div>
  );
};

export { VerifiedAgentsErrorFallBack };

import { EditIcon, ViewCommentIcon } from "assets";
import { ApplicationData, applicationInfo, SelectBox } from "components";
import { DropDownItem } from "components/Selectbox";
import React from "react";
import { TableRowItem } from "../../components";
import { AdminTableBody } from "../../components/tbody";
import { TableDataItem } from "../../components/td";
import styles from "./styles.module.css";
import { degreeOptions, initialOptionType, months, sourceOptions, yearOptions } from "Helper/options";

// Application Table Body Item
export interface ApplicationsTableBodyItem {
  unreadCommentCount: {
    student: number;
    subAgent: number;
    superAgent: number;
  };
  agent: { name: string; id: string };
  source: string;
  university: {
    name: string;
    id: string;
    logo: string;
    country: { name: string; code: string };
  };
  course: {
    name: string;
    id: string;
    degree: string;
  };
  prospect: {
    name: string;
    id: string;
    photo: string;
    nationality: string;
  };
  status: string;
  id: string;
  intakeYear: string;
  intakeMonth: string;
  dateCreated: string;
}
export interface unreadComments {
  student: number;
  subAgent: number;
  superAgent: number;
}

// University Verification Table Body Props
interface TableBodyProps {
  tableBodyItems: ApplicationsTableBodyItem[];
  tableBodyClassName: string;
  updateStatus: (id, status: string) => void;
  currentPage: number;
  viewComment: (x: applicationInfo) => void;
  editApplication: (x: ApplicationData, id: string) => void;
  viewProspect: (x: string) => void;
}

const ApplicationsTableBody: React.FC<TableBodyProps> = ({
  tableBodyItems,
  tableBodyClassName,
  updateStatus,
  currentPage,
  viewComment,
  editApplication,
  viewProspect,
}) => {
  const statusList: DropDownItem[] = [
    {
      title: "Submitted",
      key: "submitted",
      customClassName: styles.submitted,
    },
    {
      title: "Admission",
      key: "admission",
      customClassName: styles.admission,
    },
    {
      title: "Withdrawn",
      key: "withdrawn",
      customClassName: styles.admission,
    },
    {
      title: "Rejected",
      key: "rejected",
      customClassName: styles.rejected,
    },
    {
      title: "Accepted",
      key: "accepted",
      customClassName: styles.accepted,
    },
    {
      title: "Enrolled",
      key: "enrolled",
      customClassName: styles.accepted,
    },
    {
      title: "Visa Denied",
      key: "visaDenied",
      customClassName: styles.accepted,
    },
    {
      title: "Payable",
      key: "payable",
      customClassName: styles.payable,
    },
    {
      title: "Cancelled",
      key: "cancelled",
      customClassName: styles.submitted,
    },
  ];

  const selectBoxStyles = {
    background: "#fff",
    color: "inherit",
    fontWeight: "600",
    minWidth: "unset",
    border: "1px solid #b0b7c3",
    padding: "8px 10px",
    borderRadius: "8px",
    fontSize: "13px",
    paddingRight: "18px",
    width: "94px",
  };

  return (
    <AdminTableBody customClassName={`${styles.tableContainer} `}>
      {tableBodyItems.map((item, idx) => {
        const commentCount =
          item.unreadCommentCount.student + item.unreadCommentCount.subAgent + item.unreadCommentCount.superAgent;

        return (
          <TableRowItem key={`${item.id} ${idx}`} customClassName={styles.tableRow}>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
              {(currentPage - 1) * 10 + idx + 1}
            </TableDataItem>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
              <div className={styles.student}>
                <span role="button" onClick={() => viewProspect(item.prospect.id)}>
                  {item.prospect.name}
                </span>
                <span>{item.agent.name} (Agency) </span>
              </div>
            </TableDataItem>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
              <div className={styles.universityWrap}>
                <img src={item.university.logo} />
                <div className={styles.student}>
                  <span>{item.university.name}</span>
                  <span>{item.university.country.name}</span>
                </div>
              </div>
            </TableDataItem>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
              <div className={styles.student}>
                <span>{item.course.name}</span>
                <span>{item.course.degree}</span>
                <span>
                  {item.intakeMonth}, {item.intakeYear} intake
                </span>
              </div>
            </TableDataItem>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName} ${styles.statWrap}`}>
              <SelectBox
                selectGroupID="appStatus"
                dropPosition="bottom"
                displayStyles={selectBoxStyles}
                dropdownItems={statusList}
                onDropItemSelect={(e) => updateStatus(item.id, e.key ?? "")}
                defaultSelected={
                  item.status === "sent"
                    ? { title: item.status }
                    : statusList.find((status) => status.key === item.status)
                }
                containerClassName={styles[item.status]}
              />
            </TableDataItem>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.dateCreated}</TableDataItem>
            <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
              <div className={styles.actionWrap}>
                <div>
                  <EditIcon
                    role="button"
                    tabIndex={0}
                    className={styles.editIcon}
                    onClick={() =>
                      editApplication(
                        {
                          university: {
                            label: item.university.name,
                            value: item.university.id,
                          },
                          uniCountry: {
                            label: item.university.country.name,
                            value: item.university.country.code,
                          },
                          degree:
                            degreeOptions.find((deg) => deg.value.toLowerCase() === item.course.degree) ||
                            initialOptionType,
                          preferredCourse: {
                            label: item.course.name,
                            value: item.course.id,
                          },
                          intakeYear: yearOptions.find((year) => year.value === item.intakeYear) || initialOptionType,
                          intakeMonth:
                            months.find((month) => month.value.toLowerCase() === item.intakeMonth) || initialOptionType,
                          agent: {
                            label: item.agent.name,
                            value: item.agent.id,
                          },
                          source: sourceOptions.find((source) => source.value === item.source) || initialOptionType,
                          prospect: {
                            label: item.prospect.name,
                            value: item.prospect.nationality,
                          },
                        },
                        item.id,
                      )
                    }
                  />
                </div>
                <div className={styles.commentWrap}>
                  <ViewCommentIcon
                    className={styles.viewCommentIcon}
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      viewComment({
                        name: item.prospect.name,
                        passportPhoto: item.prospect.photo,
                        university: item.university.name,
                        course: item.course.name,
                        degree: item.course.degree,
                        id: item.id,
                      })
                    }
                  />
                  {commentCount ? (
                    <div className={styles.commentCount}>
                      {commentCount} <div className={styles.commentCountDescrip}>{commentCount} new comment(s)</div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </TableDataItem>
          </TableRowItem>
        );
      })}
    </AdminTableBody>
  );
};

export { ApplicationsTableBody };

import * as React from "react";
import styles from "./styles.module.css";

const Preloader = () => {
  return (
    <>
      <div className={styles.preloader}>
        <div className={styles.spinner}></div>
      </div>
    </>
  );
};

export default Preloader;


import { DropDownIcon } from "assets/vectors";
import { useDropdownList } from "hooks/useDropdownList";
import React, { useCallback, useState } from "react";
import { DropdownList } from "./dropdownList";
import styles from "./styles.module.css";

// Dropdown Item
export interface DropDownItem {
  title: string;
  styles?: React.CSSProperties;
  key?: any;
  containerId?: string;
  action?: (item: any) => void;
  customClassName?: string;
}

// Default Dropdown list
const dropDownList: DropDownItem[] = [
  { title: "Unattended", styles: { justifyContent: "flex-start" } },
  { title: "Resolved", styles: { justifyContent: "flex-start" } },
  { title: "Pending", styles: { justifyContent: "flex-start" } },
];

// Dropdown position types
export type DropListPosition = "top" | "bottom";

// Select box props
interface SelectBoxProps {
  selectGroupID: string;
  dropdownItems?: DropDownItem[];
  onDropItemSelect: (item: DropDownItem) => void;
  containerStyles?: React.CSSProperties;
  displayStyles?: React.CSSProperties;
  dropPosition?: DropListPosition;
  defaultSelected?: DropDownItem;
  disabled?: boolean;
  containerClassName?: string;
  label?: string;
  labelClassName?: string;
}

// Main Component
const SelectBox = ({
  selectGroupID = "",
  dropdownItems = dropDownList,
  onDropItemSelect = () => {},
  containerStyles,
  displayStyles,
  dropPosition = "top",
  defaultSelected = dropDownList[0],
  disabled = false,
  containerClassName = "",
  label = "",
  labelClassName = "",
}: SelectBoxProps) => {
  // Hook to use dropdownList
  const { activeDropDownState, dropdownLinkRef, setActiveDropdownState } = useDropdownList({
    initialDropdownState: false,
  });

  // Selected default item
  // const [selectedItem, setSelectedItem] = useState<DropDownItem | undefined>(() =>
  //   defaultSelected
  //     ? dropdownItems.find(
  //         (item) =>
  //           item.title.toLowerCase() === defaultSelected.title?.toLowerCase() ||
  //           item.key?.toLowerCase() === defaultSelected.title?.toLowerCase(),
  //       )
  //     : dropdownItems[0],
  // );

  const [selectedItem, setSelectedItem] = useState<DropDownItem | undefined>(() => defaultSelected ?? dropdownItems[0]);

  // Active dropdown style
  const dropdownActive = activeDropDownState ? `${styles.selectbox_container_active}` : "";

  // Disabled dropdown style
  const dropdownDisabledStyles = disabled ? `${styles.disabled}` : "";

  return (
    <div className={`${styles.selectbox_wrapper} ${containerClassName}`} style={containerStyles}>
      {label ? <span className={`${styles.selectLabel} ${labelClassName}`}> {label}</span> : ""}
      <div
        ref={dropdownLinkRef}
        key={selectGroupID}
        id={selectGroupID}
        className={`${styles.selectbox_container} ${dropdownActive} ${dropdownDisabledStyles}`}
        style={displayStyles}
        onClick={() => (disabled ? () => {} : setActiveDropdownState((activeState) => !activeState))}
      >
        <span className={styles.itemTitle} >{selectedItem?.title}</span>
        <span className={styles.caret_down_icon}>
          <DropDownIcon />
        </span>
      </div>
      {!disabled && (
        <DropdownList
          onDropItemSelect={onDropItemSelect}
          activeDropDownState={activeDropDownState}
          dropdownItems={dropdownItems}
          dropdownRef={dropdownLinkRef}
          closeDropDown={useCallback(() => {
            setActiveDropdownState(false);
          }, [setActiveDropdownState])}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          dropPosition={dropPosition}
        />
      )}
    </div>
  );
};

export { SelectBox };

// Environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL;

/*
=================================
AUTH URLS
=================================
*/

/**
 * Fetch Agent Profile
 *
 * @returns url string
 *
 */

export const fetchAgentProfileURL = (id) => `${API_URL}/admin-views-agent/${id}`;

export const editAgentProfileURL = (id) => `${API_URL}/admin-update-agent-profile/${id}`;

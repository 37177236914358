import { EyeIcon, EyeOpenIcon } from "assets";
import { Toggle } from "components";
import { ContactProps } from "components/ViewUniversities";
import React from "react";
import { TableRowItem } from "../../components";
import { AdminTableBody } from "../../components/tbody";
import { TableDataItem } from "../../components/td";
import styles from "./styles.module.css";

// University Institutions Table Body Item
export interface InstitutionsTableBodyItem {
  logo: string;
  name: string;
  country: string;
  websiteUrl: string;
  id: string;
  status: boolean;
}

// University Institutions Table Body Props
interface TableBodyProps {
  tableBodyItems: InstitutionsTableBodyItem[];
  tableBodyClassName: string;
  handleView: (id: string) => void;
  updateStatus: (id: string, stat: boolean) => void;
}

const InstitutionsTableBody: React.FC<TableBodyProps> = ({
  tableBodyItems,
  tableBodyClassName,
  handleView,
  updateStatus,
}) => {
  return (
    <AdminTableBody customClassName={`${styles.tableContainer} `}>
      {tableBodyItems.map((item, idx) => (
        <TableRowItem key={`${item.name} ${idx}`} customClassName={styles.tableRow}>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName} ${styles.uniName}`}>
            <img className={styles.logo} src={item.logo} /> {item.name}
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>{item.country}</TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            <a target={"_blank"} rel="noreferrer" className={styles.website} href={item.websiteUrl}>
              {item.websiteUrl}
            </a>
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            <Toggle onClick={() => updateStatus(item.id, item.status)} checked={item.status} />
          </TableDataItem>
          <TableDataItem className={`${styles.tableRowItem} ${tableBodyClassName}`}>
            <button className={styles.viewBtn} onClick={() => handleView(item.id)}>
              <EyeOpenIcon className={styles.fillEye} /> <EyeIcon className={styles.outlineEye} /> View
            </button>
          </TableDataItem>
        </TableRowItem>
      ))}
    </AdminTableBody>
  );
};

export { InstitutionsTableBody };

import * as React from "react";
import { SideBar, SideBarProps } from "./sidebar";
import styles from "./styles.module.css";

export interface LayoutProps extends SideBarProps {
  children: any;
}

const AdminDashboardLayout: React.FC<LayoutProps> = ({ children, active }) => {
  return (
    <>
      <div className={styles.layoutWrapper}>
        <SideBar active={active} />
        <div className={styles.layoutChildren}>{children}</div>
      </div>
    </>
  );
};

export { AdminDashboardLayout };
export * from "./sidebar"
import { appendParams } from "Helper/appendParams";

// Environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL;

/*
=================================
ENDPOINT URLS
=================================
*/

/**
 * Fetch signup requests url
 *
 * @returns url string
 *
 */

export const listSignupsURL = ({ pageNo, noOfRequests, status, source, search }) =>
  `${API_URL}/admin/fetch-agent-access-requests?${appendParams({
    pageNo,
    noOfRequests,
    status,
    source,
    search,
  })}`;

/**
 * Fetch a single signup request url
 *
 * @returns url string
 *
 */

export const getOneSignupURL = ({ id }) => `${API_URL}/admin/view-an-access-request/${id}`;

/**
 * Verify signup request url
 *
 * @returns url string
 *
 */

export const verifySignupURL = ({ id }) => `${API_URL}/admin/approve-agent-access-requests/${id}`;

import { appendParams } from "Helper/appendParams";

// Environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL;

/*
=================================
VERIFIED PAGE URLs
=================================
*/

/**
 * get all verified agents
 *
 * @returns url string
 *
 */

export const getVerifiedAgentsURL = ({ pageNo, source, search }) =>
  `${API_URL}/admin/agents/verified?${appendParams({
    pageNo,
    noOfRequests: 10,
    source,
    search,
  })}`;

/**
 * Delete a verified agent
 *
 * @returns url string
 *
 */

export const deleteVerifiedAgentURL = (agentId: string) => `${API_URL}/admin/delete/agent/${agentId}`;

/**
 * Update a verified agent
 *
 * @returns url string
 *
 */

export const updateVerifiedAgentURL = (agentId: string) => `${API_URL}/admin/update-role/${agentId}`;
